import gql from "graphql-tag";

export const DashboardWorkspaceFragment = gql`
  fragment DashboardWorkspaceFragment on OrganizationWorkspace {
    id
    status
    subscription {
      id
      plan {
        id
        name
      }
      usage {
        current {
          count
        }
      }
    }
    usage {
      current {
        periodEnd
      }
    }
    company {
      name
      logoUrl
    }
  }
`;

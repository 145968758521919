import gql from "graphql-tag";

export const GET_PAYMENT_METHODS = gql`
  query getPaymentMethods {
    organizationContext {
      paymentMethods {
        ... on PaymentMethod {
          default
        }
        ... on Node {
          id
        }
        ... on CardPaymentMethod {
          brand
          last4
          expYear
          expMonth
        }
        ... on SepaDebitPaymentMethod {
          email
          name
          last4
        }
      }
    }
  }
`;

import { CardPaymentBrand } from "./generated/graphql";

export type Dictionary<V = string> = { [key: string]: V };

export const toOptions = <V>(obj: Dictionary<V>) => {
  return Object.entries(obj).map(([label, value]) => ({
    value,
    text: label
  }));
};

export const assetDictionary = {
  [CardPaymentBrand.Mastercard]: require("./assets/img/stripe/visa.svg"),
  [CardPaymentBrand.Visa]: require("./assets/img/stripe/mastercard.svg"),
  ["SEPA"]: require("./assets/img/stripe/sepa.png")
};

export enum SetupIntentStatus {
  requires_payment_method = "requires_payment_method",
  requires_confirmation = "requires_confirmation",
  requires_action = "requires_action",
  processing = "processing",
  succeeded = "succeeded",
  canceled = "canceled"
}

export enum KeyEventEnum {
  ArrowUp = "ArrowUp",
  ArrowRight = "ArrowRight",
  ArrowLeft = "ArrowLeft",
  ArrowDown = "ArrowDown",
  Enter = "Enter"
}

export enum UploadcareUploadType {
  avatar = "avatar",
  workspace = "workspace"
}

export const timezones = [
  {
    value: "Etc/GMT+12",
    text: "(GMT -12:00) Etc/GMT+12"
  },
  {
    value: "Etc/GMT+11",
    text: "(GMT -11:00) Etc/GMT+11"
  },
  {
    value: "Pacific/Midway",
    text: "(GMT -11:00) Pacific/Midway"
  },
  {
    value: "Pacific/Niue",
    text: "(GMT -11:00) Pacific/Niue"
  },
  {
    value: "Pacific/Pago_Pago",
    text: "(GMT -11:00) Pacific/Pago_Pago"
  },
  {
    value: "Pacific/Samoa",
    text: "(GMT -11:00) Pacific/Samoa"
  },
  {
    value: "US/Samoa",
    text: "(GMT -11:00) US/Samoa"
  },
  {
    value: "Etc/GMT+10",
    text: "(GMT -10:00) Etc/GMT+10"
  },
  {
    value: "HST",
    text: "(GMT -10:00) HST"
  },
  {
    value: "Pacific/Honolulu",
    text: "(GMT -10:00) Pacific/Honolulu"
  },
  {
    value: "Pacific/Johnston",
    text: "(GMT -10:00) Pacific/Johnston"
  },
  {
    value: "Pacific/Rarotonga",
    text: "(GMT -10:00) Pacific/Rarotonga"
  },
  {
    value: "Pacific/Tahiti",
    text: "(GMT -10:00) Pacific/Tahiti"
  },
  {
    value: "US/Hawaii",
    text: "(GMT -10:00) US/Hawaii"
  },
  {
    value: "Pacific/Marquesas",
    text: "(GMT -09:30) Pacific/Marquesas"
  },
  {
    value: "America/Adak",
    text: "(GMT -09:00) America/Adak"
  },
  {
    value: "America/Atka",
    text: "(GMT -09:00) America/Atka"
  },
  {
    value: "Etc/GMT+9",
    text: "(GMT -09:00) Etc/GMT+9"
  },
  {
    value: "Pacific/Gambier",
    text: "(GMT -09:00) Pacific/Gambier"
  },
  {
    value: "US/Aleutian",
    text: "(GMT -09:00) US/Aleutian"
  },
  {
    value: "America/Anchorage",
    text: "(GMT -08:00) America/Anchorage"
  },
  {
    value: "America/Juneau",
    text: "(GMT -08:00) America/Juneau"
  },
  {
    value: "America/Metlakatla",
    text: "(GMT -08:00) America/Metlakatla"
  },
  {
    value: "America/Nome",
    text: "(GMT -08:00) America/Nome"
  },
  {
    value: "America/Sitka",
    text: "(GMT -08:00) America/Sitka"
  },
  {
    value: "America/Yakutat",
    text: "(GMT -08:00) America/Yakutat"
  },
  {
    value: "Etc/GMT+8",
    text: "(GMT -08:00) Etc/GMT+8"
  },
  {
    value: "Pacific/Pitcairn",
    text: "(GMT -08:00) Pacific/Pitcairn"
  },
  {
    value: "US/Alaska",
    text: "(GMT -08:00) US/Alaska"
  },
  {
    value: "America/Creston",
    text: "(GMT -07:00) America/Creston"
  },
  {
    value: "America/Dawson",
    text: "(GMT -07:00) America/Dawson"
  },
  {
    value: "America/Dawson_Creek",
    text: "(GMT -07:00) America/Dawson_Creek"
  },
  {
    value: "America/Ensenada",
    text: "(GMT -07:00) America/Ensenada"
  },
  {
    value: "America/Hermosillo",
    text: "(GMT -07:00) America/Hermosillo"
  },
  {
    value: "America/Los_Angeles",
    text: "(GMT -07:00) America/Los_Angeles"
  },
  {
    value: "America/Phoenix",
    text: "(GMT -07:00) America/Phoenix"
  },
  {
    value: "America/Santa_Isabel",
    text: "(GMT -07:00) America/Santa_Isabel"
  },
  {
    value: "America/Tijuana",
    text: "(GMT -07:00) America/Tijuana"
  },
  {
    value: "America/Vancouver",
    text: "(GMT -07:00) America/Vancouver"
  },
  {
    value: "America/Whitehorse",
    text: "(GMT -07:00) America/Whitehorse"
  },
  {
    value: "Canada/Pacific",
    text: "(GMT -07:00) Canada/Pacific"
  },
  {
    value: "Canada/Yukon",
    text: "(GMT -07:00) Canada/Yukon"
  },
  {
    value: "Etc/GMT+7",
    text: "(GMT -07:00) Etc/GMT+7"
  },
  {
    value: "MST",
    text: "(GMT -07:00) MST"
  },
  {
    value: "Mexico/BajaNorte",
    text: "(GMT -07:00) Mexico/BajaNorte"
  },
  {
    value: "PST8PDT",
    text: "(GMT -07:00) PST8PDT"
  },
  {
    value: "US/Arizona",
    text: "(GMT -07:00) US/Arizona"
  },
  {
    value: "US/Pacific",
    text: "(GMT -07:00) US/Pacific"
  },
  {
    value: "US/Pacific-New",
    text: "(GMT -07:00) US/Pacific-New"
  },
  {
    value: "America/Belize",
    text: "(GMT -06:00) America/Belize"
  },
  {
    value: "America/Boise",
    text: "(GMT -06:00) America/Boise"
  },
  {
    value: "America/Cambridge_Bay",
    text: "(GMT -06:00) America/Cambridge_Bay"
  },
  {
    value: "America/Chihuahua",
    text: "(GMT -06:00) America/Chihuahua"
  },
  {
    value: "America/Costa_Rica",
    text: "(GMT -06:00) America/Costa_Rica"
  },
  {
    value: "America/Denver",
    text: "(GMT -06:00) America/Denver"
  },
  {
    value: "America/Edmonton",
    text: "(GMT -06:00) America/Edmonton"
  },
  {
    value: "America/El_Salvador",
    text: "(GMT -06:00) America/El_Salvador"
  },
  {
    value: "America/Guatemala",
    text: "(GMT -06:00) America/Guatemala"
  },
  {
    value: "America/Inuvik",
    text: "(GMT -06:00) America/Inuvik"
  },
  {
    value: "America/Managua",
    text: "(GMT -06:00) America/Managua"
  },
  {
    value: "America/Mazatlan",
    text: "(GMT -06:00) America/Mazatlan"
  },
  {
    value: "America/Ojinaga",
    text: "(GMT -06:00) America/Ojinaga"
  },
  {
    value: "America/Regina",
    text: "(GMT -06:00) America/Regina"
  },
  {
    value: "America/Shiprock",
    text: "(GMT -06:00) America/Shiprock"
  },
  {
    value: "America/Swift_Current",
    text: "(GMT -06:00) America/Swift_Current"
  },
  {
    value: "America/Tegucigalpa",
    text: "(GMT -06:00) America/Tegucigalpa"
  },
  {
    value: "America/Yellowknife",
    text: "(GMT -06:00) America/Yellowknife"
  },
  {
    value: "Canada/East-Saskatchewan",
    text: "(GMT -06:00) Canada/East-Saskatchewan"
  },
  {
    value: "Canada/Mountain",
    text: "(GMT -06:00) Canada/Mountain"
  },
  {
    value: "Canada/Saskatchewan",
    text: "(GMT -06:00) Canada/Saskatchewan"
  },
  {
    value: "Chile/EasterIsland",
    text: "(GMT -06:00) Chile/EasterIsland"
  },
  {
    value: "Etc/GMT+6",
    text: "(GMT -06:00) Etc/GMT+6"
  },
  {
    value: "MST7MDT",
    text: "(GMT -06:00) MST7MDT"
  },
  {
    value: "Mexico/BajaSur",
    text: "(GMT -06:00) Mexico/BajaSur"
  },
  {
    value: "Navajo",
    text: "(GMT -06:00) Navajo"
  },
  {
    value: "Pacific/Easter",
    text: "(GMT -06:00) Pacific/Easter"
  },
  {
    value: "Pacific/Galapagos",
    text: "(GMT -06:00) Pacific/Galapagos"
  },
  {
    value: "US/Mountain",
    text: "(GMT -06:00) US/Mountain"
  },
  {
    value: "America/Atikokan",
    text: "(GMT -05:00) America/Atikokan"
  },
  {
    value: "America/Bahia_Banderas",
    text: "(GMT -05:00) America/Bahia_Banderas"
  },
  {
    value: "America/Bogota",
    text: "(GMT -05:00) America/Bogota"
  },
  {
    value: "America/Cancun",
    text: "(GMT -05:00) America/Cancun"
  },
  {
    value: "America/Cayman",
    text: "(GMT -05:00) America/Cayman"
  },
  {
    value: "America/Chicago",
    text: "(GMT -05:00) America/Chicago"
  },
  {
    value: "America/Coral_Harbour",
    text: "(GMT -05:00) America/Coral_Harbour"
  },
  {
    value: "America/Eirunepe",
    text: "(GMT -05:00) America/Eirunepe"
  },
  {
    value: "America/Guayaquil",
    text: "(GMT -05:00) America/Guayaquil"
  },
  {
    value: "America/Indiana/Knox",
    text: "(GMT -05:00) America/Indiana/Knox"
  },
  {
    value: "America/Indiana/Tell_City",
    text: "(GMT -05:00) America/Indiana/Tell_City"
  },
  {
    value: "America/Jamaica",
    text: "(GMT -05:00) America/Jamaica"
  },
  {
    value: "America/Knox_IN",
    text: "(GMT -05:00) America/Knox_IN"
  },
  {
    value: "America/Lima",
    text: "(GMT -05:00) America/Lima"
  },
  {
    value: "America/Matamoros",
    text: "(GMT -05:00) America/Matamoros"
  },
  {
    value: "America/Menominee",
    text: "(GMT -05:00) America/Menominee"
  },
  {
    value: "America/Merida",
    text: "(GMT -05:00) America/Merida"
  },
  {
    value: "America/Mexico_City",
    text: "(GMT -05:00) America/Mexico_City"
  },
  {
    value: "America/Monterrey",
    text: "(GMT -05:00) America/Monterrey"
  },
  {
    value: "America/North_Dakota/Beulah",
    text: "(GMT -05:00) America/North_Dakota/Beulah"
  },
  {
    value: "America/North_Dakota/Center",
    text: "(GMT -05:00) America/North_Dakota/Center"
  },
  {
    value: "America/North_Dakota/New_Salem",
    text: "(GMT -05:00) America/North_Dakota/New_Salem"
  },
  {
    value: "America/Panama",
    text: "(GMT -05:00) America/Panama"
  },
  {
    value: "America/Porto_Acre",
    text: "(GMT -05:00) America/Porto_Acre"
  },
  {
    value: "America/Rainy_River",
    text: "(GMT -05:00) America/Rainy_River"
  },
  {
    value: "America/Rankin_Inlet",
    text: "(GMT -05:00) America/Rankin_Inlet"
  },
  {
    value: "America/Resolute",
    text: "(GMT -05:00) America/Resolute"
  },
  {
    value: "America/Rio_Branco",
    text: "(GMT -05:00) America/Rio_Branco"
  },
  {
    value: "America/Winnipeg",
    text: "(GMT -05:00) America/Winnipeg"
  },
  {
    value: "Brazil/Acre",
    text: "(GMT -05:00) Brazil/Acre"
  },
  {
    value: "CST6CDT",
    text: "(GMT -05:00) CST6CDT"
  },
  {
    value: "Canada/Central",
    text: "(GMT -05:00) Canada/Central"
  },
  {
    value: "EST",
    text: "(GMT -05:00) EST"
  },
  {
    value: "Etc/GMT+5",
    text: "(GMT -05:00) Etc/GMT+5"
  },
  {
    value: "Jamaica",
    text: "(GMT -05:00) Jamaica"
  },
  {
    value: "Mexico/General",
    text: "(GMT -05:00) Mexico/General"
  },
  {
    value: "US/Central",
    text: "(GMT -05:00) US/Central"
  },
  {
    value: "US/Indiana-Starke",
    text: "(GMT -05:00) US/Indiana-Starke"
  },
  {
    value: "America/Caracas",
    text: "(GMT -04:30) America/Caracas"
  },
  {
    value: "America/Anguilla",
    text: "(GMT -04:00) America/Anguilla"
  },
  {
    value: "America/Antigua",
    text: "(GMT -04:00) America/Antigua"
  },
  {
    value: "America/Aruba",
    text: "(GMT -04:00) America/Aruba"
  },
  {
    value: "America/Asuncion",
    text: "(GMT -04:00) America/Asuncion"
  },
  {
    value: "America/Barbados",
    text: "(GMT -04:00) America/Barbados"
  },
  {
    value: "America/Blanc-Sablon",
    text: "(GMT -04:00) America/Blanc-Sablon"
  },
  {
    value: "America/Boa_Vista",
    text: "(GMT -04:00) America/Boa_Vista"
  },
  {
    value: "America/Campo_Grande",
    text: "(GMT -04:00) America/Campo_Grande"
  },
  {
    value: "America/Cuiaba",
    text: "(GMT -04:00) America/Cuiaba"
  },
  {
    value: "America/Curacao",
    text: "(GMT -04:00) America/Curacao"
  },
  {
    value: "America/Detroit",
    text: "(GMT -04:00) America/Detroit"
  },
  {
    value: "America/Dominica",
    text: "(GMT -04:00) America/Dominica"
  },
  {
    value: "America/Fort_Wayne",
    text: "(GMT -04:00) America/Fort_Wayne"
  },
  {
    value: "America/Grand_Turk",
    text: "(GMT -04:00) America/Grand_Turk"
  },
  {
    value: "America/Grenada",
    text: "(GMT -04:00) America/Grenada"
  },
  {
    value: "America/Guadeloupe",
    text: "(GMT -04:00) America/Guadeloupe"
  },
  {
    value: "America/Guyana",
    text: "(GMT -04:00) America/Guyana"
  },
  {
    value: "America/Havana",
    text: "(GMT -04:00) America/Havana"
  },
  {
    value: "America/Indiana/Indianapolis",
    text: "(GMT -04:00) America/Indiana/Indianapolis"
  },
  {
    value: "America/Indiana/Marengo",
    text: "(GMT -04:00) America/Indiana/Marengo"
  },
  {
    value: "America/Indiana/Petersburg",
    text: "(GMT -04:00) America/Indiana/Petersburg"
  },
  {
    value: "America/Indiana/Vevay",
    text: "(GMT -04:00) America/Indiana/Vevay"
  },
  {
    value: "America/Indiana/Vincennes",
    text: "(GMT -04:00) America/Indiana/Vincennes"
  },
  {
    value: "America/Indiana/Winamac",
    text: "(GMT -04:00) America/Indiana/Winamac"
  },
  {
    value: "America/Indianapolis",
    text: "(GMT -04:00) America/Indianapolis"
  },
  {
    value: "America/Iqaluit",
    text: "(GMT -04:00) America/Iqaluit"
  },
  {
    value: "America/Kentucky/Louisville",
    text: "(GMT -04:00) America/Kentucky/Louisville"
  },
  {
    value: "America/Kentucky/Monticello",
    text: "(GMT -04:00) America/Kentucky/Monticello"
  },
  {
    value: "America/Kralendijk",
    text: "(GMT -04:00) America/Kralendijk"
  },
  {
    value: "America/La_Paz",
    text: "(GMT -04:00) America/La_Paz"
  },
  {
    value: "America/Louisville",
    text: "(GMT -04:00) America/Louisville"
  },
  {
    value: "America/Lower_Princes",
    text: "(GMT -04:00) America/Lower_Princes"
  },
  {
    value: "America/Manaus",
    text: "(GMT -04:00) America/Manaus"
  },
  {
    value: "America/Marigot",
    text: "(GMT -04:00) America/Marigot"
  },
  {
    value: "America/Martinique",
    text: "(GMT -04:00) America/Martinique"
  },
  {
    value: "America/Montreal",
    text: "(GMT -04:00) America/Montreal"
  },
  {
    value: "America/Montserrat",
    text: "(GMT -04:00) America/Montserrat"
  },
  {
    value: "America/Nassau",
    text: "(GMT -04:00) America/Nassau"
  },
  {
    value: "America/New_York",
    text: "(GMT -04:00) America/New_York"
  },
  {
    value: "America/Nipigon",
    text: "(GMT -04:00) America/Nipigon"
  },
  {
    value: "America/Pangnirtung",
    text: "(GMT -04:00) America/Pangnirtung"
  },
  {
    value: "America/Port-au-Prince",
    text: "(GMT -04:00) America/Port-au-Prince"
  },
  {
    value: "America/Port_of_Spain",
    text: "(GMT -04:00) America/Port_of_Spain"
  },
  {
    value: "America/Porto_Velho",
    text: "(GMT -04:00) America/Porto_Velho"
  },
  {
    value: "America/Puerto_Rico",
    text: "(GMT -04:00) America/Puerto_Rico"
  },
  {
    value: "America/Santiago",
    text: "(GMT -04:00) America/Santiago"
  },
  {
    value: "America/Santo_Domingo",
    text: "(GMT -04:00) America/Santo_Domingo"
  },
  {
    value: "America/St_Barthelemy",
    text: "(GMT -04:00) America/St_Barthelemy"
  },
  {
    value: "America/St_Kitts",
    text: "(GMT -04:00) America/St_Kitts"
  },
  {
    value: "America/St_Lucia",
    text: "(GMT -04:00) America/St_Lucia"
  },
  {
    value: "America/St_Thomas",
    text: "(GMT -04:00) America/St_Thomas"
  },
  {
    value: "America/St_Vincent",
    text: "(GMT -04:00) America/St_Vincent"
  },
  {
    value: "America/Thunder_Bay",
    text: "(GMT -04:00) America/Thunder_Bay"
  },
  {
    value: "America/Toronto",
    text: "(GMT -04:00) America/Toronto"
  },
  {
    value: "America/Tortola",
    text: "(GMT -04:00) America/Tortola"
  },
  {
    value: "America/Virgin",
    text: "(GMT -04:00) America/Virgin"
  },
  {
    value: "Antarctica/Palmer",
    text: "(GMT -04:00) Antarctica/Palmer"
  },
  {
    value: "Brazil/West",
    text: "(GMT -04:00) Brazil/West"
  },
  {
    value: "Canada/Eastern",
    text: "(GMT -04:00) Canada/Eastern"
  },
  {
    value: "Chile/Continental",
    text: "(GMT -04:00) Chile/Continental"
  },
  {
    value: "Cuba",
    text: "(GMT -04:00) Cuba"
  },
  {
    value: "EST5EDT",
    text: "(GMT -04:00) EST5EDT"
  },
  {
    value: "Etc/GMT+4",
    text: "(GMT -04:00) Etc/GMT+4"
  },
  {
    value: "US/East-Indiana",
    text: "(GMT -04:00) US/East-Indiana"
  },
  {
    value: "US/Eastern",
    text: "(GMT -04:00) US/Eastern"
  },
  {
    value: "US/Michigan",
    text: "(GMT -04:00) US/Michigan"
  },
  {
    value: "America/Araguaina",
    text: "(GMT -03:00) America/Araguaina"
  },
  {
    value: "America/Argentina/Buenos_Aires",
    text: "(GMT -03:00) America/Argentina/Buenos_Aires"
  },
  {
    value: "America/Argentina/Catamarca",
    text: "(GMT -03:00) America/Argentina/Catamarca"
  },
  {
    value: "America/Argentina/ComodRivadavia",
    text: "(GMT -03:00) America/Argentina/ComodRivadavia"
  },
  {
    value: "America/Argentina/Cordoba",
    text: "(GMT -03:00) America/Argentina/Cordoba"
  },
  {
    value: "America/Argentina/Jujuy",
    text: "(GMT -03:00) America/Argentina/Jujuy"
  },
  {
    value: "America/Argentina/La_Rioja",
    text: "(GMT -03:00) America/Argentina/La_Rioja"
  },
  {
    value: "America/Argentina/Mendoza",
    text: "(GMT -03:00) America/Argentina/Mendoza"
  },
  {
    value: "America/Argentina/Rio_Gallegos",
    text: "(GMT -03:00) America/Argentina/Rio_Gallegos"
  },
  {
    value: "America/Argentina/Salta",
    text: "(GMT -03:00) America/Argentina/Salta"
  },
  {
    value: "America/Argentina/San_Juan",
    text: "(GMT -03:00) America/Argentina/San_Juan"
  },
  {
    value: "America/Argentina/San_Luis",
    text: "(GMT -03:00) America/Argentina/San_Luis"
  },
  {
    value: "America/Argentina/Tucuman",
    text: "(GMT -03:00) America/Argentina/Tucuman"
  },
  {
    value: "America/Argentina/Ushuaia",
    text: "(GMT -03:00) America/Argentina/Ushuaia"
  },
  {
    value: "America/Bahia",
    text: "(GMT -03:00) America/Bahia"
  },
  {
    value: "America/Belem",
    text: "(GMT -03:00) America/Belem"
  },
  {
    value: "America/Buenos_Aires",
    text: "(GMT -03:00) America/Buenos_Aires"
  },
  {
    value: "America/Catamarca",
    text: "(GMT -03:00) America/Catamarca"
  },
  {
    value: "America/Cayenne",
    text: "(GMT -03:00) America/Cayenne"
  },
  {
    value: "America/Cordoba",
    text: "(GMT -03:00) America/Cordoba"
  },
  {
    value: "America/Fortaleza",
    text: "(GMT -03:00) America/Fortaleza"
  },
  {
    value: "America/Glace_Bay",
    text: "(GMT -03:00) America/Glace_Bay"
  },
  {
    value: "America/Goose_Bay",
    text: "(GMT -03:00) America/Goose_Bay"
  },
  {
    value: "America/Halifax",
    text: "(GMT -03:00) America/Halifax"
  },
  {
    value: "America/Jujuy",
    text: "(GMT -03:00) America/Jujuy"
  },
  {
    value: "America/Maceio",
    text: "(GMT -03:00) America/Maceio"
  },
  {
    value: "America/Mendoza",
    text: "(GMT -03:00) America/Mendoza"
  },
  {
    value: "America/Moncton",
    text: "(GMT -03:00) America/Moncton"
  },
  {
    value: "America/Montevideo",
    text: "(GMT -03:00) America/Montevideo"
  },
  {
    value: "America/Paramaribo",
    text: "(GMT -03:00) America/Paramaribo"
  },
  {
    value: "America/Recife",
    text: "(GMT -03:00) America/Recife"
  },
  {
    value: "America/Rosario",
    text: "(GMT -03:00) America/Rosario"
  },
  {
    value: "America/Santarem",
    text: "(GMT -03:00) America/Santarem"
  },
  {
    value: "America/Sao_Paulo",
    text: "(GMT -03:00) America/Sao_Paulo"
  },
  {
    value: "America/Thule",
    text: "(GMT -03:00) America/Thule"
  },
  {
    value: "Antarctica/Rothera",
    text: "(GMT -03:00) Antarctica/Rothera"
  },
  {
    value: "Atlantic/Bermuda",
    text: "(GMT -03:00) Atlantic/Bermuda"
  },
  {
    value: "Atlantic/Stanley",
    text: "(GMT -03:00) Atlantic/Stanley"
  },
  {
    value: "Brazil/East",
    text: "(GMT -03:00) Brazil/East"
  },
  {
    value: "Canada/Atlantic",
    text: "(GMT -03:00) Canada/Atlantic"
  },
  {
    value: "Etc/GMT+3",
    text: "(GMT -03:00) Etc/GMT+3"
  },
  {
    value: "America/St_Johns",
    text: "(GMT -02:30) America/St_Johns"
  },
  {
    value: "Canada/Newfoundland",
    text: "(GMT -02:30) Canada/Newfoundland"
  },
  {
    value: "America/Godthab",
    text: "(GMT -02:00) America/Godthab"
  },
  {
    value: "America/Miquelon",
    text: "(GMT -02:00) America/Miquelon"
  },
  {
    value: "America/Noronha",
    text: "(GMT -02:00) America/Noronha"
  },
  {
    value: "Atlantic/South_Georgia",
    text: "(GMT -02:00) Atlantic/South_Georgia"
  },
  {
    value: "Brazil/DeNoronha",
    text: "(GMT -02:00) Brazil/DeNoronha"
  },
  {
    value: "Etc/GMT+2",
    text: "(GMT -02:00) Etc/GMT+2"
  },
  {
    value: "Atlantic/Cape_Verde",
    text: "(GMT -01:00) Atlantic/Cape_Verde"
  },
  {
    value: "Etc/GMT+1",
    text: "(GMT -01:00) Etc/GMT+1"
  },
  {
    value: "Africa/Abidjan",
    text: "(GMT +00:00) Africa/Abidjan"
  },
  {
    value: "Africa/Accra",
    text: "(GMT +00:00) Africa/Accra"
  },
  {
    value: "Africa/Bamako",
    text: "(GMT +00:00) Africa/Bamako"
  },
  {
    value: "Africa/Banjul",
    text: "(GMT +00:00) Africa/Banjul"
  },
  {
    value: "Africa/Bissau",
    text: "(GMT +00:00) Africa/Bissau"
  },
  {
    value: "Africa/Conakry",
    text: "(GMT +00:00) Africa/Conakry"
  },
  {
    value: "Africa/Dakar",
    text: "(GMT +00:00) Africa/Dakar"
  },
  {
    value: "Africa/Freetown",
    text: "(GMT +00:00) Africa/Freetown"
  },
  {
    value: "Africa/Lome",
    text: "(GMT +00:00) Africa/Lome"
  },
  {
    value: "Africa/Monrovia",
    text: "(GMT +00:00) Africa/Monrovia"
  },
  {
    value: "Africa/Nouakchott",
    text: "(GMT +00:00) Africa/Nouakchott"
  },
  {
    value: "Africa/Ouagadougou",
    text: "(GMT +00:00) Africa/Ouagadougou"
  },
  {
    value: "Africa/Sao_Tome",
    text: "(GMT +00:00) Africa/Sao_Tome"
  },
  {
    value: "Africa/Timbuktu",
    text: "(GMT +00:00) Africa/Timbuktu"
  },
  {
    value: "America/Danmarkshavn",
    text: "(GMT +00:00) America/Danmarkshavn"
  },
  {
    value: "America/Scoresbysund",
    text: "(GMT +00:00) America/Scoresbysund"
  },
  {
    value: "Atlantic/Azores",
    text: "(GMT +00:00) Atlantic/Azores"
  },
  {
    value: "Atlantic/Reykjavik",
    text: "(GMT +00:00) Atlantic/Reykjavik"
  },
  {
    value: "Atlantic/St_Helena",
    text: "(GMT +00:00) Atlantic/St_Helena"
  },
  {
    value: "Etc/GMT",
    text: "(GMT +00:00) Etc/GMT"
  },
  {
    value: "Etc/GMT+0",
    text: "(GMT +00:00) Etc/GMT+0"
  },
  {
    value: "Etc/GMT-0",
    text: "(GMT +00:00) Etc/GMT-0"
  },
  {
    value: "Etc/GMT0",
    text: "(GMT +00:00) Etc/GMT0"
  },
  {
    value: "Etc/Greenwich",
    text: "(GMT +00:00) Etc/Greenwich"
  },
  {
    value: "Etc/UCT",
    text: "(GMT +00:00) Etc/UCT"
  },
  {
    value: "Etc/UTC",
    text: "(GMT +00:00) Etc/UTC"
  },
  {
    value: "Etc/Universal",
    text: "(GMT +00:00) Etc/Universal"
  },
  {
    value: "Etc/Zulu",
    text: "(GMT +00:00) Etc/Zulu"
  },
  {
    value: "GMT",
    text: "(GMT +00:00) GMT"
  },
  {
    value: "GMT+0",
    text: "(GMT +00:00) GMT+0"
  },
  {
    value: "GMT-0",
    text: "(GMT +00:00) GMT-0"
  },
  {
    value: "GMT0",
    text: "(GMT +00:00) GMT0"
  },
  {
    value: "Greenwich",
    text: "(GMT +00:00) Greenwich"
  },
  {
    value: "Iceland",
    text: "(GMT +00:00) Iceland"
  },
  {
    value: "UCT",
    text: "(GMT +00:00) UCT"
  },
  {
    value: "UTC",
    text: "(GMT +00:00) UTC"
  },
  {
    value: "Universal",
    text: "(GMT +00:00) Universal"
  },
  {
    value: "Zulu",
    text: "(GMT +00:00) Zulu"
  },
  {
    value: "Africa/Algiers",
    text: "(GMT +01:00) Africa/Algiers"
  },
  {
    value: "Africa/Bangui",
    text: "(GMT +01:00) Africa/Bangui"
  },
  {
    value: "Africa/Brazzaville",
    text: "(GMT +01:00) Africa/Brazzaville"
  },
  {
    value: "Africa/Casablanca",
    text: "(GMT +01:00) Africa/Casablanca"
  },
  {
    value: "Africa/Douala",
    text: "(GMT +01:00) Africa/Douala"
  },
  {
    value: "Africa/El_Aaiun",
    text: "(GMT +01:00) Africa/El_Aaiun"
  },
  {
    value: "Africa/Kinshasa",
    text: "(GMT +01:00) Africa/Kinshasa"
  },
  {
    value: "Africa/Lagos",
    text: "(GMT +01:00) Africa/Lagos"
  },
  {
    value: "Africa/Libreville",
    text: "(GMT +01:00) Africa/Libreville"
  },
  {
    value: "Africa/Luanda",
    text: "(GMT +01:00) Africa/Luanda"
  },
  {
    value: "Africa/Malabo",
    text: "(GMT +01:00) Africa/Malabo"
  },
  {
    value: "Africa/Ndjamena",
    text: "(GMT +01:00) Africa/Ndjamena"
  },
  {
    value: "Africa/Niamey",
    text: "(GMT +01:00) Africa/Niamey"
  },
  {
    value: "Africa/Porto-Novo",
    text: "(GMT +01:00) Africa/Porto-Novo"
  },
  {
    value: "Africa/Tunis",
    text: "(GMT +01:00) Africa/Tunis"
  },
  {
    value: "Africa/Windhoek",
    text: "(GMT +01:00) Africa/Windhoek"
  },
  {
    value: "Atlantic/Canary",
    text: "(GMT +01:00) Atlantic/Canary"
  },
  {
    value: "Atlantic/Faeroe",
    text: "(GMT +01:00) Atlantic/Faeroe"
  },
  {
    value: "Atlantic/Faroe",
    text: "(GMT +01:00) Atlantic/Faroe"
  },
  {
    value: "Atlantic/Madeira",
    text: "(GMT +01:00) Atlantic/Madeira"
  },
  {
    value: "Eire",
    text: "(GMT +01:00) Eire"
  },
  {
    value: "Etc/GMT-1",
    text: "(GMT +01:00) Etc/GMT-1"
  },
  {
    value: "Europe/Belfast",
    text: "(GMT +01:00) Europe/Belfast"
  },
  {
    value: "Europe/Dublin",
    text: "(GMT +01:00) Europe/Dublin"
  },
  {
    value: "Europe/Guernsey",
    text: "(GMT +01:00) Europe/Guernsey"
  },
  {
    value: "Europe/Isle_of_Man",
    text: "(GMT +01:00) Europe/Isle_of_Man"
  },
  {
    value: "Europe/Jersey",
    text: "(GMT +01:00) Europe/Jersey"
  },
  {
    value: "Europe/Lisbon",
    text: "(GMT +01:00) Europe/Lisbon"
  },
  {
    value: "Europe/London",
    text: "(GMT +01:00) Europe/London"
  },
  {
    value: "GB",
    text: "(GMT +01:00) GB"
  },
  {
    value: "GB-Eire",
    text: "(GMT +01:00) GB-Eire"
  },
  {
    value: "Portugal",
    text: "(GMT +01:00) Portugal"
  },
  {
    value: "WET",
    text: "(GMT +01:00) WET"
  },
  {
    value: "Africa/Blantyre",
    text: "(GMT +02:00) Africa/Blantyre"
  },
  {
    value: "Africa/Bujumbura",
    text: "(GMT +02:00) Africa/Bujumbura"
  },
  {
    value: "Africa/Ceuta",
    text: "(GMT +02:00) Africa/Ceuta"
  },
  {
    value: "Africa/Gaborone",
    text: "(GMT +02:00) Africa/Gaborone"
  },
  {
    value: "Africa/Harare",
    text: "(GMT +02:00) Africa/Harare"
  },
  {
    value: "Africa/Johannesburg",
    text: "(GMT +02:00) Africa/Johannesburg"
  },
  {
    value: "Africa/Kigali",
    text: "(GMT +02:00) Africa/Kigali"
  },
  {
    value: "Africa/Lubumbashi",
    text: "(GMT +02:00) Africa/Lubumbashi"
  },
  {
    value: "Africa/Lusaka",
    text: "(GMT +02:00) Africa/Lusaka"
  },
  {
    value: "Africa/Maputo",
    text: "(GMT +02:00) Africa/Maputo"
  },
  {
    value: "Africa/Maseru",
    text: "(GMT +02:00) Africa/Maseru"
  },
  {
    value: "Africa/Mbabane",
    text: "(GMT +02:00) Africa/Mbabane"
  },
  {
    value: "Africa/Tripoli",
    text: "(GMT +02:00) Africa/Tripoli"
  },
  {
    value: "Antarctica/Troll",
    text: "(GMT +02:00) Antarctica/Troll"
  },
  {
    value: "Arctic/Longyearbyen",
    text: "(GMT +02:00) Arctic/Longyearbyen"
  },
  {
    value: "Atlantic/Jan_Mayen",
    text: "(GMT +02:00) Atlantic/Jan_Mayen"
  },
  {
    value: "CET",
    text: "(GMT +02:00) CET"
  },
  {
    value: "Etc/GMT-2",
    text: "(GMT +02:00) Etc/GMT-2"
  },
  {
    value: "Europe/Amsterdam",
    text: "(GMT +02:00) Europe/Amsterdam"
  },
  {
    value: "Europe/Andorra",
    text: "(GMT +02:00) Europe/Andorra"
  },
  {
    value: "Europe/Belgrade",
    text: "(GMT +02:00) Europe/Belgrade"
  },
  {
    value: "Europe/Berlin",
    text: "(GMT +02:00) Europe/Berlin"
  },
  {
    value: "Europe/Bratislava",
    text: "(GMT +02:00) Europe/Bratislava"
  },
  {
    value: "Europe/Brussels",
    text: "(GMT +02:00) Europe/Brussels"
  },
  {
    value: "Europe/Budapest",
    text: "(GMT +02:00) Europe/Budapest"
  },
  {
    value: "Europe/Busingen",
    text: "(GMT +02:00) Europe/Busingen"
  },
  {
    value: "Europe/Copenhagen",
    text: "(GMT +02:00) Europe/Copenhagen"
  },
  {
    value: "Europe/Gibraltar",
    text: "(GMT +02:00) Europe/Gibraltar"
  },
  {
    value: "Europe/Kaliningrad",
    text: "(GMT +02:00) Europe/Kaliningrad"
  },
  {
    value: "Europe/Ljubljana",
    text: "(GMT +02:00) Europe/Ljubljana"
  },
  {
    value: "Europe/Luxembourg",
    text: "(GMT +02:00) Europe/Luxembourg"
  },
  {
    value: "Europe/Madrid",
    text: "(GMT +02:00) Europe/Madrid"
  },
  {
    value: "Europe/Malta",
    text: "(GMT +02:00) Europe/Malta"
  },
  {
    value: "Europe/Monaco",
    text: "(GMT +02:00) Europe/Monaco"
  },
  {
    value: "Europe/Oslo",
    text: "(GMT +02:00) Europe/Oslo"
  },
  {
    value: "Europe/Paris",
    text: "(GMT +02:00) Europe/Paris"
  },
  {
    value: "Europe/Podgorica",
    text: "(GMT +02:00) Europe/Podgorica"
  },
  {
    value: "Europe/Prague",
    text: "(GMT +02:00) Europe/Prague"
  },
  {
    value: "Europe/Rome",
    text: "(GMT +02:00) Europe/Rome"
  },
  {
    value: "Europe/San_Marino",
    text: "(GMT +02:00) Europe/San_Marino"
  },
  {
    value: "Europe/Sarajevo",
    text: "(GMT +02:00) Europe/Sarajevo"
  },
  {
    value: "Europe/Skopje",
    text: "(GMT +02:00) Europe/Skopje"
  },
  {
    value: "Europe/Stockholm",
    text: "(GMT +02:00) Europe/Stockholm"
  },
  {
    value: "Europe/Tirane",
    text: "(GMT +02:00) Europe/Tirane"
  },
  {
    value: "Europe/Vaduz",
    text: "(GMT +02:00) Europe/Vaduz"
  },
  {
    value: "Europe/Vatican",
    text: "(GMT +02:00) Europe/Vatican"
  },
  {
    value: "Europe/Vienna",
    text: "(GMT +02:00) Europe/Vienna"
  },
  {
    value: "Europe/Warsaw",
    text: "(GMT +02:00) Europe/Warsaw"
  },
  {
    value: "Europe/Zagreb",
    text: "(GMT +02:00) Europe/Zagreb"
  },
  {
    value: "Europe/Zurich",
    text: "(GMT +02:00) Europe/Zurich"
  },
  {
    value: "Libya",
    text: "(GMT +02:00) Libya"
  },
  {
    value: "MET",
    text: "(GMT +02:00) MET"
  },
  {
    value: "Poland",
    text: "(GMT +02:00) Poland"
  },
  {
    value: "Africa/Addis_Ababa",
    text: "(GMT +03:00) Africa/Addis_Ababa"
  },
  {
    value: "Africa/Asmara",
    text: "(GMT +03:00) Africa/Asmara"
  },
  {
    value: "Africa/Asmera",
    text: "(GMT +03:00) Africa/Asmera"
  },
  {
    value: "Africa/Cairo",
    text: "(GMT +03:00) Africa/Cairo"
  },
  {
    value: "Africa/Dar_es_Salaam",
    text: "(GMT +03:00) Africa/Dar_es_Salaam"
  },
  {
    value: "Africa/Djibouti",
    text: "(GMT +03:00) Africa/Djibouti"
  },
  {
    value: "Africa/Juba",
    text: "(GMT +03:00) Africa/Juba"
  },
  {
    value: "Africa/Kampala",
    text: "(GMT +03:00) Africa/Kampala"
  },
  {
    value: "Africa/Khartoum",
    text: "(GMT +03:00) Africa/Khartoum"
  },
  {
    value: "Africa/Mogadishu",
    text: "(GMT +03:00) Africa/Mogadishu"
  },
  {
    value: "Africa/Nairobi",
    text: "(GMT +03:00) Africa/Nairobi"
  },
  {
    value: "Antarctica/Syowa",
    text: "(GMT +03:00) Antarctica/Syowa"
  },
  {
    value: "Asia/Aden",
    text: "(GMT +03:00) Asia/Aden"
  },
  {
    value: "Asia/Amman",
    text: "(GMT +03:00) Asia/Amman"
  },
  {
    value: "Asia/Baghdad",
    text: "(GMT +03:00) Asia/Baghdad"
  },
  {
    value: "Asia/Bahrain",
    text: "(GMT +03:00) Asia/Bahrain"
  },
  {
    value: "Asia/Beirut",
    text: "(GMT +03:00) Asia/Beirut"
  },
  {
    value: "Asia/Damascus",
    text: "(GMT +03:00) Asia/Damascus"
  },
  {
    value: "Asia/Gaza",
    text: "(GMT +03:00) Asia/Gaza"
  },
  {
    value: "Asia/Hebron",
    text: "(GMT +03:00) Asia/Hebron"
  },
  {
    value: "Asia/Istanbul",
    text: "(GMT +03:00) Asia/Istanbul"
  },
  {
    value: "Asia/Jerusalem",
    text: "(GMT +03:00) Asia/Jerusalem"
  },
  {
    value: "Asia/Kuwait",
    text: "(GMT +03:00) Asia/Kuwait"
  },
  {
    value: "Asia/Nicosia",
    text: "(GMT +03:00) Asia/Nicosia"
  },
  {
    value: "Asia/Qatar",
    text: "(GMT +03:00) Asia/Qatar"
  },
  {
    value: "Asia/Riyadh",
    text: "(GMT +03:00) Asia/Riyadh"
  },
  {
    value: "Asia/Tel_Aviv",
    text: "(GMT +03:00) Asia/Tel_Aviv"
  },
  {
    value: "EET",
    text: "(GMT +03:00) EET"
  },
  {
    value: "Egypt",
    text: "(GMT +03:00) Egypt"
  },
  {
    value: "Etc/GMT-3",
    text: "(GMT +03:00) Etc/GMT-3"
  },
  {
    value: "Europe/Athens",
    text: "(GMT +03:00) Europe/Athens"
  },
  {
    value: "Europe/Bucharest",
    text: "(GMT +03:00) Europe/Bucharest"
  },
  {
    value: "Europe/Chisinau",
    text: "(GMT +03:00) Europe/Chisinau"
  },
  {
    value: "Europe/Helsinki",
    text: "(GMT +03:00) Europe/Helsinki"
  },
  {
    value: "Europe/Istanbul",
    text: "(GMT +03:00) Europe/Istanbul"
  },
  {
    value: "Europe/Kiev",
    text: "(GMT +03:00) Europe/Kiev"
  },
  {
    value: "Europe/Mariehamn",
    text: "(GMT +03:00) Europe/Mariehamn"
  },
  {
    value: "Europe/Minsk",
    text: "(GMT +03:00) Europe/Minsk"
  },
  {
    value: "Europe/Moscow",
    text: "(GMT +03:00) Europe/Moscow"
  },
  {
    value: "Europe/Nicosia",
    text: "(GMT +03:00) Europe/Nicosia"
  },
  {
    value: "Europe/Riga",
    text: "(GMT +03:00) Europe/Riga"
  },
  {
    value: "Europe/Simferopol",
    text: "(GMT +03:00) Europe/Simferopol"
  },
  {
    value: "Europe/Sofia",
    text: "(GMT +03:00) Europe/Sofia"
  },
  {
    value: "Europe/Tallinn",
    text: "(GMT +03:00) Europe/Tallinn"
  },
  {
    value: "Europe/Tiraspol",
    text: "(GMT +03:00) Europe/Tiraspol"
  },
  {
    value: "Europe/Uzhgorod",
    text: "(GMT +03:00) Europe/Uzhgorod"
  },
  {
    value: "Europe/Vilnius",
    text: "(GMT +03:00) Europe/Vilnius"
  },
  {
    value: "Europe/Volgograd",
    text: "(GMT +03:00) Europe/Volgograd"
  },
  {
    value: "Europe/Zaporozhye",
    text: "(GMT +03:00) Europe/Zaporozhye"
  },
  {
    value: "Indian/Antananarivo",
    text: "(GMT +03:00) Indian/Antananarivo"
  },
  {
    value: "Indian/Comoro",
    text: "(GMT +03:00) Indian/Comoro"
  },
  {
    value: "Indian/Mayotte",
    text: "(GMT +03:00) Indian/Mayotte"
  },
  {
    value: "Israel",
    text: "(GMT +03:00) Israel"
  },
  {
    value: "Turkey",
    text: "(GMT +03:00) Turkey"
  },
  {
    value: "W-SU",
    text: "(GMT +03:00) W-SU"
  },
  {
    value: "Asia/Dubai",
    text: "(GMT +04:00) Asia/Dubai"
  },
  {
    value: "Asia/Muscat",
    text: "(GMT +04:00) Asia/Muscat"
  },
  {
    value: "Asia/Tbilisi",
    text: "(GMT +04:00) Asia/Tbilisi"
  },
  {
    value: "Asia/Yerevan",
    text: "(GMT +04:00) Asia/Yerevan"
  },
  {
    value: "Etc/GMT-4",
    text: "(GMT +04:00) Etc/GMT-4"
  },
  {
    value: "Europe/Samara",
    text: "(GMT +04:00) Europe/Samara"
  },
  {
    value: "Indian/Mahe",
    text: "(GMT +04:00) Indian/Mahe"
  },
  {
    value: "Indian/Mauritius",
    text: "(GMT +04:00) Indian/Mauritius"
  },
  {
    value: "Indian/Reunion",
    text: "(GMT +04:00) Indian/Reunion"
  },
  {
    value: "Asia/Kabul",
    text: "(GMT +04:30) Asia/Kabul"
  },
  {
    value: "Asia/Tehran",
    text: "(GMT +04:30) Asia/Tehran"
  },
  {
    value: "Iran",
    text: "(GMT +04:30) Iran"
  },
  {
    value: "Antarctica/Mawson",
    text: "(GMT +05:00) Antarctica/Mawson"
  },
  {
    value: "Asia/Aqtau",
    text: "(GMT +05:00) Asia/Aqtau"
  },
  {
    value: "Asia/Aqtobe",
    text: "(GMT +05:00) Asia/Aqtobe"
  },
  {
    value: "Asia/Ashgabat",
    text: "(GMT +05:00) Asia/Ashgabat"
  },
  {
    value: "Asia/Ashkhabad",
    text: "(GMT +05:00) Asia/Ashkhabad"
  },
  {
    value: "Asia/Baku",
    text: "(GMT +05:00) Asia/Baku"
  },
  {
    value: "Asia/Dushanbe",
    text: "(GMT +05:00) Asia/Dushanbe"
  },
  {
    value: "Asia/Karachi",
    text: "(GMT +05:00) Asia/Karachi"
  },
  {
    value: "Asia/Oral",
    text: "(GMT +05:00) Asia/Oral"
  },
  {
    value: "Asia/Samarkand",
    text: "(GMT +05:00) Asia/Samarkand"
  },
  {
    value: "Asia/Tashkent",
    text: "(GMT +05:00) Asia/Tashkent"
  },
  {
    value: "Asia/Yekaterinburg",
    text: "(GMT +05:00) Asia/Yekaterinburg"
  },
  {
    value: "Etc/GMT-5",
    text: "(GMT +05:00) Etc/GMT-5"
  },
  {
    value: "Indian/Kerguelen",
    text: "(GMT +05:00) Indian/Kerguelen"
  },
  {
    value: "Indian/Maldives",
    text: "(GMT +05:00) Indian/Maldives"
  },
  {
    value: "Asia/Calcutta",
    text: "(GMT +05:30) Asia/Calcutta"
  },
  {
    value: "Asia/Colombo",
    text: "(GMT +05:30) Asia/Colombo"
  },
  {
    value: "Asia/Kolkata",
    text: "(GMT +05:30) Asia/Kolkata"
  },
  {
    value: "Asia/Kathmandu",
    text: "(GMT +05:45) Asia/Kathmandu"
  },
  {
    value: "Asia/Katmandu",
    text: "(GMT +05:45) Asia/Katmandu"
  },
  {
    value: "Antarctica/Vostok",
    text: "(GMT +06:00) Antarctica/Vostok"
  },
  {
    value: "Asia/Almaty",
    text: "(GMT +06:00) Asia/Almaty"
  },
  {
    value: "Asia/Bishkek",
    text: "(GMT +06:00) Asia/Bishkek"
  },
  {
    value: "Asia/Dacca",
    text: "(GMT +06:00) Asia/Dacca"
  },
  {
    value: "Asia/Dhaka",
    text: "(GMT +06:00) Asia/Dhaka"
  },
  {
    value: "Asia/Kashgar",
    text: "(GMT +06:00) Asia/Kashgar"
  },
  {
    value: "Asia/Novosibirsk",
    text: "(GMT +06:00) Asia/Novosibirsk"
  },
  {
    value: "Asia/Omsk",
    text: "(GMT +06:00) Asia/Omsk"
  },
  {
    value: "Asia/Qyzylorda",
    text: "(GMT +06:00) Asia/Qyzylorda"
  },
  {
    value: "Asia/Thimbu",
    text: "(GMT +06:00) Asia/Thimbu"
  },
  {
    value: "Asia/Thimphu",
    text: "(GMT +06:00) Asia/Thimphu"
  },
  {
    value: "Asia/Urumqi",
    text: "(GMT +06:00) Asia/Urumqi"
  },
  {
    value: "Etc/GMT-6",
    text: "(GMT +06:00) Etc/GMT-6"
  },
  {
    value: "Indian/Chagos",
    text: "(GMT +06:00) Indian/Chagos"
  },
  {
    value: "Asia/Rangoon",
    text: "(GMT +06:30) Asia/Rangoon"
  },
  {
    value: "Indian/Cocos",
    text: "(GMT +06:30) Indian/Cocos"
  },
  {
    value: "Antarctica/Davis",
    text: "(GMT +07:00) Antarctica/Davis"
  },
  {
    value: "Asia/Bangkok",
    text: "(GMT +07:00) Asia/Bangkok"
  },
  {
    value: "Asia/Ho_Chi_Minh",
    text: "(GMT +07:00) Asia/Ho_Chi_Minh"
  },
  {
    value: "Asia/Hovd",
    text: "(GMT +07:00) Asia/Hovd"
  },
  {
    value: "Asia/Jakarta",
    text: "(GMT +07:00) Asia/Jakarta"
  },
  {
    value: "Asia/Krasnoyarsk",
    text: "(GMT +07:00) Asia/Krasnoyarsk"
  },
  {
    value: "Asia/Novokuznetsk",
    text: "(GMT +07:00) Asia/Novokuznetsk"
  },
  {
    value: "Asia/Phnom_Penh",
    text: "(GMT +07:00) Asia/Phnom_Penh"
  },
  {
    value: "Asia/Pontianak",
    text: "(GMT +07:00) Asia/Pontianak"
  },
  {
    value: "Asia/Saigon",
    text: "(GMT +07:00) Asia/Saigon"
  },
  {
    value: "Asia/Vientiane",
    text: "(GMT +07:00) Asia/Vientiane"
  },
  {
    value: "Etc/GMT-7",
    text: "(GMT +07:00) Etc/GMT-7"
  },
  {
    value: "Indian/Christmas",
    text: "(GMT +07:00) Indian/Christmas"
  },
  {
    value: "Antarctica/Casey",
    text: "(GMT +08:00) Antarctica/Casey"
  },
  {
    value: "Asia/Brunei",
    text: "(GMT +08:00) Asia/Brunei"
  },
  {
    value: "Asia/Chita",
    text: "(GMT +08:00) Asia/Chita"
  },
  {
    value: "Asia/Choibalsan",
    text: "(GMT +08:00) Asia/Choibalsan"
  },
  {
    value: "Asia/Chongqing",
    text: "(GMT +08:00) Asia/Chongqing"
  },
  {
    value: "Asia/Chungking",
    text: "(GMT +08:00) Asia/Chungking"
  },
  {
    value: "Asia/Harbin",
    text: "(GMT +08:00) Asia/Harbin"
  },
  {
    value: "Asia/Hong_Kong",
    text: "(GMT +08:00) Asia/Hong_Kong"
  },
  {
    value: "Asia/Irkutsk",
    text: "(GMT +08:00) Asia/Irkutsk"
  },
  {
    value: "Asia/Kuala_Lumpur",
    text: "(GMT +08:00) Asia/Kuala_Lumpur"
  },
  {
    value: "Asia/Kuching",
    text: "(GMT +08:00) Asia/Kuching"
  },
  {
    value: "Asia/Macao",
    text: "(GMT +08:00) Asia/Macao"
  },
  {
    value: "Asia/Macau",
    text: "(GMT +08:00) Asia/Macau"
  },
  {
    value: "Asia/Makassar",
    text: "(GMT +08:00) Asia/Makassar"
  },
  {
    value: "Asia/Manila",
    text: "(GMT +08:00) Asia/Manila"
  },
  {
    value: "Asia/Shanghai",
    text: "(GMT +08:00) Asia/Shanghai"
  },
  {
    value: "Asia/Singapore",
    text: "(GMT +08:00) Asia/Singapore"
  },
  {
    value: "Asia/Taipei",
    text: "(GMT +08:00) Asia/Taipei"
  },
  {
    value: "Asia/Ujung_Pandang",
    text: "(GMT +08:00) Asia/Ujung_Pandang"
  },
  {
    value: "Asia/Ulaanbaatar",
    text: "(GMT +08:00) Asia/Ulaanbaatar"
  },
  {
    value: "Asia/Ulan_Bator",
    text: "(GMT +08:00) Asia/Ulan_Bator"
  },
  {
    value: "Australia/Perth",
    text: "(GMT +08:00) Australia/Perth"
  },
  {
    value: "Australia/West",
    text: "(GMT +08:00) Australia/West"
  },
  {
    value: "Etc/GMT-8",
    text: "(GMT +08:00) Etc/GMT-8"
  },
  {
    value: "Hongkong",
    text: "(GMT +08:00) Hongkong"
  },
  {
    value: "PRC",
    text: "(GMT +08:00) PRC"
  },
  {
    value: "ROC",
    text: "(GMT +08:00) ROC"
  },
  {
    value: "Singapore",
    text: "(GMT +08:00) Singapore"
  },
  {
    value: "Australia/Eucla",
    text: "(GMT +08:45) Australia/Eucla"
  },
  {
    value: "Asia/Dili",
    text: "(GMT +09:00) Asia/Dili"
  },
  {
    value: "Asia/Jayapura",
    text: "(GMT +09:00) Asia/Jayapura"
  },
  {
    value: "Asia/Khandyga",
    text: "(GMT +09:00) Asia/Khandyga"
  },
  {
    value: "Asia/Pyongyang",
    text: "(GMT +09:00) Asia/Pyongyang"
  },
  {
    value: "Asia/Seoul",
    text: "(GMT +09:00) Asia/Seoul"
  },
  {
    value: "Asia/Tokyo",
    text: "(GMT +09:00) Asia/Tokyo"
  },
  {
    value: "Asia/Yakutsk",
    text: "(GMT +09:00) Asia/Yakutsk"
  },
  {
    value: "Etc/GMT-9",
    text: "(GMT +09:00) Etc/GMT-9"
  },
  {
    value: "Japan",
    text: "(GMT +09:00) Japan"
  },
  {
    value: "Pacific/Palau",
    text: "(GMT +09:00) Pacific/Palau"
  },
  {
    value: "ROK",
    text: "(GMT +09:00) ROK"
  },
  {
    value: "Australia/Adelaide",
    text: "(GMT +09:30) Australia/Adelaide"
  },
  {
    value: "Australia/Broken_Hill",
    text: "(GMT +09:30) Australia/Broken_Hill"
  },
  {
    value: "Australia/Darwin",
    text: "(GMT +09:30) Australia/Darwin"
  },
  {
    value: "Australia/North",
    text: "(GMT +09:30) Australia/North"
  },
  {
    value: "Australia/South",
    text: "(GMT +09:30) Australia/South"
  },
  {
    value: "Australia/Yancowinna",
    text: "(GMT +09:30) Australia/Yancowinna"
  },
  {
    value: "Antarctica/DumontDUrville",
    text: "(GMT +10:00) Antarctica/DumontDUrville"
  },
  {
    value: "Asia/Magadan",
    text: "(GMT +10:00) Asia/Magadan"
  },
  {
    value: "Asia/Sakhalin",
    text: "(GMT +10:00) Asia/Sakhalin"
  },
  {
    value: "Asia/Ust-Nera",
    text: "(GMT +10:00) Asia/Ust-Nera"
  },
  {
    value: "Asia/Vladivostok",
    text: "(GMT +10:00) Asia/Vladivostok"
  },
  {
    value: "Australia/ACT",
    text: "(GMT +10:00) Australia/ACT"
  },
  {
    value: "Australia/Brisbane",
    text: "(GMT +10:00) Australia/Brisbane"
  },
  {
    value: "Australia/Canberra",
    text: "(GMT +10:00) Australia/Canberra"
  },
  {
    value: "Australia/Currie",
    text: "(GMT +10:00) Australia/Currie"
  },
  {
    value: "Australia/Hobart",
    text: "(GMT +10:00) Australia/Hobart"
  },
  {
    value: "Australia/Lindeman",
    text: "(GMT +10:00) Australia/Lindeman"
  },
  {
    value: "Australia/Melbourne",
    text: "(GMT +10:00) Australia/Melbourne"
  },
  {
    value: "Australia/NSW",
    text: "(GMT +10:00) Australia/NSW"
  },
  {
    value: "Australia/Queensland",
    text: "(GMT +10:00) Australia/Queensland"
  },
  {
    value: "Australia/Sydney",
    text: "(GMT +10:00) Australia/Sydney"
  },
  {
    value: "Australia/Tasmania",
    text: "(GMT +10:00) Australia/Tasmania"
  },
  {
    value: "Australia/Victoria",
    text: "(GMT +10:00) Australia/Victoria"
  },
  {
    value: "Etc/GMT-10",
    text: "(GMT +10:00) Etc/GMT-10"
  },
  {
    value: "Pacific/Chuuk",
    text: "(GMT +10:00) Pacific/Chuuk"
  },
  {
    value: "Pacific/Guam",
    text: "(GMT +10:00) Pacific/Guam"
  },
  {
    value: "Pacific/Port_Moresby",
    text: "(GMT +10:00) Pacific/Port_Moresby"
  },
  {
    value: "Pacific/Saipan",
    text: "(GMT +10:00) Pacific/Saipan"
  },
  {
    value: "Pacific/Truk",
    text: "(GMT +10:00) Pacific/Truk"
  },
  {
    value: "Pacific/Yap",
    text: "(GMT +10:00) Pacific/Yap"
  },
  {
    value: "Australia/LHI",
    text: "(GMT +10:30) Australia/LHI"
  },
  {
    value: "Australia/Lord_Howe",
    text: "(GMT +10:30) Australia/Lord_Howe"
  },
  {
    value: "Antarctica/Macquarie",
    text: "(GMT +11:00) Antarctica/Macquarie"
  },
  {
    value: "Asia/Srednekolymsk",
    text: "(GMT +11:00) Asia/Srednekolymsk"
  },
  {
    value: "Etc/GMT-11",
    text: "(GMT +11:00) Etc/GMT-11"
  },
  {
    value: "Pacific/Bougainville",
    text: "(GMT +11:00) Pacific/Bougainville"
  },
  {
    value: "Pacific/Efate",
    text: "(GMT +11:00) Pacific/Efate"
  },
  {
    value: "Pacific/Guadalcanal",
    text: "(GMT +11:00) Pacific/Guadalcanal"
  },
  {
    value: "Pacific/Kosrae",
    text: "(GMT +11:00) Pacific/Kosrae"
  },
  {
    value: "Pacific/Noumea",
    text: "(GMT +11:00) Pacific/Noumea"
  },
  {
    value: "Pacific/Pohnpei",
    text: "(GMT +11:00) Pacific/Pohnpei"
  },
  {
    value: "Pacific/Ponape",
    text: "(GMT +11:00) Pacific/Ponape"
  },
  {
    value: "Pacific/Norfolk",
    text: "(GMT +11:30) Pacific/Norfolk"
  },
  {
    value: "Antarctica/McMurdo",
    text: "(GMT +12:00) Antarctica/McMurdo"
  },
  {
    value: "Antarctica/South_Pole",
    text: "(GMT +12:00) Antarctica/South_Pole"
  },
  {
    value: "Asia/Anadyr",
    text: "(GMT +12:00) Asia/Anadyr"
  },
  {
    value: "Asia/Kamchatka",
    text: "(GMT +12:00) Asia/Kamchatka"
  },
  {
    value: "Etc/GMT-12",
    text: "(GMT +12:00) Etc/GMT-12"
  },
  {
    value: "Kwajalein",
    text: "(GMT +12:00) Kwajalein"
  },
  {
    value: "NZ",
    text: "(GMT +12:00) NZ"
  },
  {
    value: "Pacific/Auckland",
    text: "(GMT +12:00) Pacific/Auckland"
  },
  {
    value: "Pacific/Fiji",
    text: "(GMT +12:00) Pacific/Fiji"
  },
  {
    value: "Pacific/Funafuti",
    text: "(GMT +12:00) Pacific/Funafuti"
  },
  {
    value: "Pacific/Kwajalein",
    text: "(GMT +12:00) Pacific/Kwajalein"
  },
  {
    value: "Pacific/Majuro",
    text: "(GMT +12:00) Pacific/Majuro"
  },
  {
    value: "Pacific/Nauru",
    text: "(GMT +12:00) Pacific/Nauru"
  },
  {
    value: "Pacific/Tarawa",
    text: "(GMT +12:00) Pacific/Tarawa"
  },
  {
    value: "Pacific/Wake",
    text: "(GMT +12:00) Pacific/Wake"
  },
  {
    value: "Pacific/Wallis",
    text: "(GMT +12:00) Pacific/Wallis"
  },
  {
    value: "NZ-CHAT",
    text: "(GMT +12:45) NZ-CHAT"
  },
  {
    value: "Pacific/Chatham",
    text: "(GMT +12:45) Pacific/Chatham"
  },
  {
    value: "Etc/GMT-13",
    text: "(GMT +13:00) Etc/GMT-13"
  },
  {
    value: "Pacific/Apia",
    text: "(GMT +13:00) Pacific/Apia"
  },
  {
    value: "Pacific/Enderbury",
    text: "(GMT +13:00) Pacific/Enderbury"
  },
  {
    value: "Pacific/Fakaofo",
    text: "(GMT +13:00) Pacific/Fakaofo"
  },
  {
    value: "Pacific/Tongatapu",
    text: "(GMT +13:00) Pacific/Tongatapu"
  },
  {
    value: "Etc/GMT-14",
    text: "(GMT +14:00) Etc/GMT-14"
  },
  {
    value: "Pacific/Kiritimati",
    text: "(GMT +14:00) Pacific/Kiritimati"
  }
];

export const countries = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" }
];

export const currencies = {
  USD: {
    symbol: "$",
    name: "US Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "USD",
    name_plural: "US dollars"
  },
  EUR: {
    symbol: "€",
    name: "Euro",
    symbol_native: "€",
    decimal_digits: 2,
    rounding: 0,
    code: "EUR",
    name_plural: "euros"
  }
};

import { format } from "date-fns";
import Vue from "vue";

Vue.filter("shortDate", function(value: Date) {
  if (!value) return "";
  if (!(value instanceof Date))
    throw new Error(`Invalid use of shortDate filter: ${value} is not a date`);
  return format(value, "yyyy-MM-dd");
});

Vue.filter("monthDate", function(value: Date) {
  if (!value) return "";
  if (!(value instanceof Date))
    throw new Error(`Invalid use of monthDate filter: ${value} is not a date`);
  return format(value, "yyyy-MM");
});

Vue.filter("timestamp", function(value: Date) {
  if (!value) return "";
  if (!(value instanceof Date))
    throw new Error(`Invalid use of timestamp filter: ${value} is not a date`);
  return format(value, "yyyy-MM-dd / HH:mm");
});

Vue.filter("timestampToDate", function(value: number) {
  if (!value) return null;
  if (!(typeof value === "number"))
    throw new Error(
      `Invalid use of timestampToDate filter: ${value} is not a timestamp`
    );
  return new Date(value * 1000);
});

Vue.filter("number", function(value: number) {
  if (!value && value !== 0) return "";
  else if (Number.isNaN(+value) || typeof +value !== "number") return value;
  return new Intl.NumberFormat("en-US").format(+value);
});

Vue.filter("currency", function(value: number, currency: string) {
  if (value === null || value === undefined) return "";
  else if (Number.isNaN(+value) || typeof +value !== "number") return value;
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency || "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(+value);
});

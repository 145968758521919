import gql from "graphql-tag";
import { OrganizationUserFragment } from "./fragments";

export const GET_USERS = gql`
  query getUsers($name: String, $role: String, $cursor: String) {
    organizationContext {
      users(name: $name, role: $role, first: 10, after: $cursor) {
        edges {
          node {
            ...OrganizationUserFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }

  ${OrganizationUserFragment}
`;

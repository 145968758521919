






import Vue from "vue";

export default Vue.extend({
  props: {
    name: {
      type: String,
      required: true
    },
    url: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: "normal",
      validator: function(value) {
        return ["xl", "big", "normal", "small"].indexOf(value) !== -1;
      }
    }
  },
  methods: {
    stringToColour: function(inputStr: string): string {
      const baseRed = 128;
      const baseGreen = 128;
      const baseBlue = 128;
      //lazy seeded random hack to get values from 0 - 256
      //for seed just take bitwise XOR of first two chars
      let seed = inputStr.charCodeAt(0) ^ inputStr.charCodeAt(1);
      const rand1 = Math.abs(Math.sin(seed++) * 10000) % 256;
      const rand2 = Math.abs(Math.sin(seed++) * 10000) % 256;
      const rand3 = Math.abs(Math.sin(seed++) * 10000) % 256;
      //build colour
      const red = Math.round((rand1 + baseRed) / 2);
      const green = Math.round((rand2 + baseGreen) / 2);
      const blue = Math.round((rand3 + baseBlue) / 2);
      return `rgba(${red}, ${green}, ${blue}, 1)`;
    }
  },
  computed: {
    initials(): string {
      if (this.url) {
        return "";
      }
      let initials = "..";
      if (this.name) {
        const splitName = this.name.split(" ");
        if (splitName.length > 1) {
          initials =
            splitName[0].substring(0, 1).toUpperCase() +
            splitName[1].substring(0, 1).toUpperCase();
        } else if (this.name.length >= 2) {
          initials = this.name.substring(0, 2).toUpperCase();
        }
      }
      return initials;
    },
    logoStyles(): Partial<CSSStyleDeclaration> {
      return this.url
        ? {
            backgroundImage: `url(${this.url})`,
            backgroundSize: "cover"
          }
        : {
            backgroundColor: this.stringToColour(this.initials)
          };
    }
  }
});



















import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "Avatar",
  props: {
    src: {
      type: String
    },
    size: {
      type: String as PropType<"xs" | "s" | "md">,
      default: "md"
    },
    alt: String,
    big: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isSrcInvalid: false
    };
  },
  computed: {
    avatarSize(): string {
      switch (this.size) {
        case "xs":
          return "20px";
        case "s":
          return "26px";
        default:
        case "md":
          return "48px";
      }
    }
  },
  methods: {
    onImgError() {
      this.isSrcInvalid = true;
    }
  }
});

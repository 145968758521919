import gql from "graphql-tag";
import { OrganizationFragment, PlanFragment } from "./fragments";

export const GET_CURRENT_ORGANIZATION = gql`
  query getCurrentOrganization {
    organizationContext {
      organization {
        id
        ...OrganizationFragment
      }
    }
  }
  ${OrganizationFragment}
`;

export const GET_ORGANIZATION_LIST = gql`
  query getOrganizationList {
    organizations {
      organizations {
        id
        ...OrganizationFragment
      }
    }
  }
  ${OrganizationFragment}
`;

export const GET_PLANS = gql`
  query getPlans {
    organizationContext {
      plans(filter: { visible: true }) {
        id

        users
        ...PlanFragment
      }
    }
  }
  ${PlanFragment}
`;

export const GET_INVOICES = gql`
  query getInvoices {
    organizationContext {
      invoices {
        id
        month
        year
        issueDate
        dueDate
        paidDate
        number
        status
        amount
        currency
        url
        pdf
        plan {
          name
        }
        workspace {
          company {
            name
            logoUrl
          }
        }
      }
    }
  }
`;

















































































































import Vue from "vue";
import CollapseLink from "@/components/collapse/CollapseLink.vue";
import BillingIcon from "@/components/icons/BillingIcon.vue";
import UsageIcon from "@/components/icons/UsageIcon.vue";
import NavLink from "@/components/navbar/NavLink.vue";
import NavLinkExternal from "@/components/navbar/NavLinkExternal.vue";
import { WorkspaceSummary } from "@/models/workspace";
import { RouteName } from "@/router";

export default Vue.extend({
  name: "NavLinkList",
  components: {
    CollapseLink,
    NavLink,
    BillingIcon,
    UsageIcon,
    NavLinkExternal
  },
  props: {
    selectedWs: {
      type: String
    }
  },
  data: () => ({
    RouteName
  }),

  computed: {
    wsRoutingParams(): { [key: string]: string } {
      return { id: this.selectedWs };
    },
    primaryNav(): (RouteName | (RouteName | RouteName[])[])[] {
      return [
        RouteName.DASHBOARD,
        RouteName.INFORMATIONS,

        [
          RouteName.BILLING,
          [RouteName.ACCOUNT, RouteName.PAYMENT_OPTIONS, RouteName.INVOICES]
        ],
        RouteName.USERS
      ];
    },
    workspaceNav(): (RouteName | (RouteName | RouteName[])[])[] {
      return [
        RouteName.WORKSPACE_ACCOUNT,
        RouteName.WORKSPACE_PLAN,
        RouteName.WORKSPACE_USAGE,
        RouteName.WORKSPACE_USERS
      ];
    },

    isPrimaryNav(): boolean {
      return this.selectedWs === null;
    },
    orgId() {
      return this.$route.params.orgId;
    },
    workspaces(): WorkspaceSummary[] {
      return this.$storeTyped.getters.getEditableWorkspaceSummaries;
    },
    showChangeable() {
      return this.$storeTyped.state?.workspace?.currentWorkspace?.subscription
        ?.plan?.changeable;
    }
  },
  methods: {
    gotoExternalWorkspace() {
      const currentWs = this.$storeTyped.state.workspace.currentWorkspace?.id;
      const baseUrl = process.env.VUE_APP_WEBAPP;
      const url = "http://app.stent.io/";
      if (baseUrl && baseUrl != "undefined") {
        window.open(`${baseUrl}${currentWs}`, "_blank");
      } else {
        window.open(`${url}${currentWs}`, "_blank");
      }
    },
    isVisible(name: string): boolean {
      return this.$route.path.includes(name);
    }
  }
});

import gql from "graphql-tag";

export const INVITE_ORGANIZATION_USER = gql`
  mutation inviteOrganizationUser($input: InviteUserInput!) {
    organizationContext {
      inviteOrganizationUser(input: $input) {
        organizationUser {
          email
          firstName
          id
          invitationDate
          lastName
          roles {
            name
            value
          }
          pictureUrl
        }
      }
    }
  }
`;

export const UPDATE_ORGANIZATION_USER_ROLES = gql`
  mutation updateOrganizationUserRoles($id: String!, $input: [String]!) {
    organizationContext {
      updateOrganizationUserRoles(id: $id, input: $input) {
        roles {
          name
          description
          value
        }
      }
    }
  }
`;

export const REMOVE_ORGANIZATION_USER = gql`
  mutation removeOrganizationUser($id: String!) {
    organizationContext {
      removeOrganizationUser(id: $id)
    }
  }
`;

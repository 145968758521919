








































































import Vue from "vue";
import { ActionTypes } from "@/store/action-types";
import WorkspaceLogo from "@/components/layout/WorkspaceLogo.vue";
import { WorkspaceSummary } from "@/models/workspace";
import { OrganizationSummary } from "@/models/organization";
import { RouteName } from "@/router";
import { BIconSearch, BIconShuffle } from "bootstrap-vue";

export default Vue.extend({
  name: "WorkspaceNavbar",
  components: { WorkspaceLogo, BIconSearch, BIconShuffle },
  props: {
    selectedWs: {
      type: String
    }
  },
  watch: {
    selectedWs(val: string) {
      if (val) {
        this.collapseWorkspaceSearch();
      }
    }
  },
  created() {
    this.$storeTyped.dispatch(ActionTypes.FETCH_WORKSPACE_SUMMARIES);
  },
  data() {
    return {
      isExpanded: false,
      workspaceFilter: ""
    };
  },
  mounted() {
    this.getSidebarLinkStatus();
  },
  computed: {
    favoriteWorkspaces(): WorkspaceSummary[] {
      return this.$storeTyped.getters.getFavoriteWorkspaceSummaries;
    },
    workspaces(): WorkspaceSummary[] {
      return this.$storeTyped.state.workspace.workspaceSummaries;
    },
    filteredWorkspaces(): WorkspaceSummary[] {
      return this.workspaces.filter(ws =>
        ws.company.name
          .trim()
          .toLowerCase()
          .includes(this.workspaceFilter.trim().toLowerCase())
      );
    },
    organizations(): OrganizationSummary[] | null {
      return this.$storeTyped.state?.organization?.organizationSummaries;
    },
    canSwitchOrgs(): boolean {
      const summaries = this.$storeTyped.state?.organization
        ?.organizationSummaries;
      return summaries !== null && summaries.length > 1;
    }
  },
  methods: {
    getSidebarLinkStatus() {
      this.$root.$on("toogleSidebar", (data: any) => {
        if (data == true) {
          this.$root.$emit("sidebar", data);
          this.isExpanded = true;
        } else {
          this.$root.$emit("sidebar", data);
          this.isExpanded = false;
        }
      });
    },
    switchOrg() {
      this.$router.push({
        name: RouteName.SWITCH_ORG
      });
      this.$storeTyped.dispatch(ActionTypes.RESET_STORE);
    },
    expandWorkspaceSearch() {
      this.isExpanded = true;
    },
    collapseWorkspaceSearch() {
      this.isExpanded = false;
      this.$root.$emit("sidebar", this.isExpanded);
    },
    selectWorkspace(id: string) {
      this.$emit("select-ws", id);
      this.collapseWorkspaceSearch();
      this.$router.push({
        name: RouteName.WORKSPACE_ACCOUNT,
        params: {
          orgId: this.$route.params.orgId,
          id
        }
      });
    },
    gotoExternalWorkspace(ws: string) {
      const currentWs = ws;
      const baseUrl = process.env.VUE_APP_WEBAPP;
      const url = "http://app.stent.io/";

      if (baseUrl && baseUrl != "undefined") {
        console.log("baseUrl + currentWs", baseUrl, currentWs);
        window.open(`${baseUrl}${currentWs}`, "_blank");
      } else {
        console.log("baseUrl + currentWs", baseUrl, currentWs);
        window.open(`${url}${currentWs}`, "_blank");
      }
    }
  }
});
















import Vue from "vue";

export default Vue.extend({
  name: "CollapseLink",
  props: {
    name: String,
    icon: String,
    id: String,
    visible: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isFeatherIcon(): boolean {
      return Boolean(this.icon) && this.icon.startsWith("fe-");
    }
  }
});

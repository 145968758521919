import gql from "graphql-tag";
import { DashboardWorkspaceFragment } from "./fragments";

export const GET_DASHBOARD_DATA = gql`
  query getDashboardData($currentMonth: Int, $currentYear: Int) {
    organizationContext {
      informations: organization {
        id
        name
        logoUrl
        contact {
          lastName
          firstName
        }
        addressLine1
        addressLine2
        city
        country {
          code
          name
        }
        zip
        state
      }
      billing: invoices(
        filter: { createdMonth: $currentMonth, createdYear: $currentYear }
      ) {
        year
        month
        amount
        id
        credits
        currency
      }

      users {
        edges {
          node {
            firstName
            lastName
            id
            pictureUrl
          }
        }
        totalCount
      }

      favoriteWorkspaces: workspaces(favorites: true, first: 3) {
        edges {
          node {
            ...DashboardWorkspaceFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }

      ws: workspaces(first: 10, status: ACTIVE) {
        edges {
          node {
            ...DashboardWorkspaceFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${DashboardWorkspaceFragment}
`;

export const GET_DASHBOARD_WORKSPACES = gql`
  query getDashboardWorkspaces(
    $cursor: String
    $name: String
    $status: Status
  ) {
    organizationContext {
      workspaces(first: 10, after: $cursor, name: $name, status: $status) {
        edges {
          node {
            ...DashboardWorkspaceFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${DashboardWorkspaceFragment}
`;

export const GET_DASHBOARD_FAVOURITE_WORKSPACES = gql`
  query getDashboardFavouriteWorkspaces($cursor: String) {
    organizationContext {
      favoriteWorkspaces: workspaces(
        favorites: true
        first: 3
        after: $cursor
      ) {
        edges {
          node {
            ...DashboardWorkspaceFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${DashboardWorkspaceFragment}
`;

import { DirectiveOptions } from "vue";

export default {
  inserted(el, binding) {
    const eventName = binding.value || "change";
    el.addEventListener(eventName, () => {
      (document.activeElement as HTMLElement).blur();
    });
  }
} as DirectiveOptions;

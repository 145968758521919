















import Vue from "vue";
import Navbar from "@/components/navbar/Navbar.vue";
import Loader from "@/components/layout/Loader.vue";
import Banner from "@/components/layout/Banner.vue";

export default Vue.extend({
  name: "Layout",
  components: { Navbar, Loader, Banner },
  computed: {
    hasBanner(): boolean {
      return !!this.$storeTyped.state.bannerText;
    },
    isAuthenticated() {
      return this.$storeTyped.getters.isAuthenticated;
    }
  }
});

import Vue, { DirectiveOptions } from "vue";
import Loader from "@/components/layout/Loader.vue";

const LoaderClass = Vue.extend(Loader);

export default {
  update: function(el, { value: isLoading }) {
    if (isLoading) {
      const loaderInstance = new LoaderClass({
        propsData: { isLoading }
      });
      loaderInstance.$mount();
      el.appendChild(loaderInstance.$el);
    }
  }
} as DirectiveOptions;

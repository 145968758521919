









































import Vue from "vue";
import { BvTableFieldArray, BIconTrash } from "bootstrap-vue";

export default Vue.extend({
  props: {
    text: {
      type: String
    },
    icon: {
      type: String
    },
    actionButton: {
      type: Boolean
    }
  }
});

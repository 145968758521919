import Vue from "vue";
import router from "./router";
import store from "./store/index";
import App from "./App.vue";

// Form validation
import Vuelidate from "vuelidate";
// Global directives
import "@/directives/directives";
// Global filters
import "./filters";
// Bootstrap
import { BootstrapVue } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { LinkPlugin, PopoverPlugin } from "bootstrap-vue";
// Sentry
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";

import { createProvider } from "./vue-apollo";
import "./styles/theme.scss";
import BubblePlugin from "./plugins/bubble.plugin";

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(LinkPlugin);
Vue.use(Vuelidate);
Vue.use(BubblePlugin);
Vue.use(PopoverPlugin);

Sentry.init({
  dsn: process.env.VUE_APP_SENTRY,
  integrations: [
    new VueIntegration({
      Vue,
      attachProps: true,
      logErrors: process.env.NODE_ENV === "development"
    })
  ]
});

Object.defineProperty(Vue.prototype, "$storeTyped", {
  get: function(this: Vue) {
    return this.$store;
  }
});

// TODO: Check if this is not bad practice
window.vm = null;

new Vue({
  router,
  store: store,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount("#app");

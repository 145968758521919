import gql from "graphql-tag";

export const SETUP_INDENT = gql`
  mutation createPaymentMethodIntent {
    organizationContext {
      createPaymentMethodIntent {
        clientSecret
        id
        status
      }
    }
  }
`;

export const CANCEL_SETUP_INDENT = gql`
  mutation cancelPaymentMethodIntent($id: String!) {
    organizationContext {
      cancelPaymentMethodIntent(id: $id) {
        clientSecret
        id
        status
      }
    }
  }
`;

export const DETACH_ORGANIZATION_PAYMENT_METHOD = gql`
  mutation detachOrganizationPaymentMethod($paymentMethodId: String!) {
    organizationContext {
      detachOrganizationPaymentMethod(paymentMethodId: $paymentMethodId)
    }
  }
`;

export const UPDATE_ORGANIZATION_DEFAULT_PAYMENT_METHOD = gql`
  mutation updateOrganizationDefaultPaymentMethod($id: String!) {
    organizationContext {
      updateOrganizationDefaultPaymentMethod(id: $id) {
        defaultPaymentMethod
      }
    }
  }
`;

<template>
  <svg
    version="1.1"
    id="Calque_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 14 18.1"
    style="enable-background:new 0 0 14 18.1;"
    xml:space="preserve"
    class="img-icon"
  >
    <g>
      <path
        class="st0"
        d="M13.3,0.5c-0.4-0.2-0.8-0.1-1.2,0.2l-1,0.9L9.8,0.3c-0.4-0.4-1-0.4-1.4,0L7,1.5L5.6,0.3c-0.4-0.4-1-0.4-1.4,0
		L2.8,1.5l-1-0.9C1.3,0.3,0.7,0.3,0.3,0.7C0.1,0.9,0,1.2,0,1.5v15.2c0,0.6,0.5,1.1,1.1,1.1c0,0,0,0,0,0c0.3,0,0.5-0.1,0.7-0.3l1-0.9
		l1.4,1.2c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3L7,16.6l1.4,1.2c0.4,0.4,1,0.4,1.4,0l1.4-1.2l1,0.9c0.2,0.2,0.5,0.3,0.8,0.3
		c0.3,0,0.5-0.1,0.7-0.4c0.2-0.2,0.3-0.5,0.3-0.7V1.5C14,1,13.7,0.7,13.3,0.5z M12.6,16.1l-0.7-0.6c-0.4-0.4-1-0.4-1.4,0l-1.4,1.2
		l-1.4-1.2c-0.4-0.4-1-0.4-1.4,0l-1.4,1.2l-1.4-1.2c-0.2-0.2-0.5-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3l-0.7,0.6L1.3,2l0.7,0.6
		c0.4,0.4,1,0.4,1.4,0l1.4-1.2l1.4,1.2c0.4,0.4,1,0.4,1.4,0l1.4-1.2l1.4,1.2c0.4,0.4,1,0.4,1.4,0L12.6,2L12.6,16.1z"
      />
      <path
        class="st0"
        d="M10.9,8.4H3.1c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h7.8c0.4,0,0.7-0.3,0.7-0.7S11.3,8.4,10.9,8.4z"
      />
      <path
        class="st0"
        d="M3.1,7.3h4.1c0.4,0,0.7-0.3,0.7-0.7S7.5,5.9,7.2,5.9H3.1c-0.4,0-0.7,0.3-0.7,0.7S2.7,7.3,3.1,7.3z"
      />
      <path
        class="st0"
        d="M10.9,10.9H3.1c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h7.8c0.4,0,0.7-0.3,0.7-0.7S11.3,10.9,10.9,10.9z"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

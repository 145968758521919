


























































































import Vue from "vue";

import WorkspaceLogo from "@/components/layout/WorkspaceLogo.vue";
import Avatar from "@/components/avatar/Avatar.vue";
import { ActionTypes } from "@/store/action-types";
import { mapState } from "vuex";
import { RouteName } from "@/router";
import { WorkspaceSummary } from "@/models/workspace";
import { BIconThreeDotsVertical, BIconGrid } from "bootstrap-vue";

export default Vue.extend({
  name: "NavbarHeader",
  components: { WorkspaceLogo, Avatar, BIconThreeDotsVertical, BIconGrid },
  props: {
    selectedWs: {
      type: String
    }
  },
  data() {
    return {
      dashboardRoute: RouteName.DASHBOARD,
      editProfileRoute: RouteName.EDIT_PROFILE,
      isExpanded: true,
      showSidebar: false
    };
  },
  computed: {
    ...mapState({
      currentOrg: store => store.organization.organization,
      currentUser: state => state.loggedUser
    }),
    workspaces(): WorkspaceSummary[] {
      return this.$storeTyped.state.workspace.workspaceSummaries;
    },
    currentWorkspace(): WorkspaceSummary | null {
      return this.workspaces?.find(w => w.id === this.selectedWs) || null;
    }
  },
  created() {
    this.$storeTyped.dispatch(ActionTypes.FETCH_ORGANIZATION);
  },
  updated() {
    this.getSidebarLinkStatus();
  },
  methods: {
    getSidebarLinkStatus() {
      this.$root.$on("sidebar", (data: any) => {
        if (data == true) {
          this.showSidebar = true;
        } else {
          this.showSidebar = false;
        }
      });
    },

    activateSidebarLink() {
      const toogleSidebar = (this.showSidebar = !this.showSidebar);
      this.$root.$emit("toogleSidebar", toogleSidebar);
    },
    logout() {
      this.$storeTyped.dispatch(ActionTypes.LOGOUT_USER);
      this.removeScript();
    },
    removeEadvocacyStorage() {
      this.isExpanded = true;
      localStorage.removeItem("ea");
    },
    removeScript() {
      const el = document.getElementById("onboardScript");
      if (el) {
        el.remove();
      }
    }
  }
});

import gql from "graphql-tag";

export const FETCH_BILLING = gql`
  query getBilling {
    organizationContext {
      billing {
        name
        addressLine1
        addressLine2
        zip
        city
        state
        country {
          code
          name
        }
        currency
        vat
        recipients {
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Any: any;
  /** The built-in `Decimal` scalar type. */
  Decimal: any;
  /**
   * The `Long` scalar type represents non-fractional signed whole 64-bit numeric
   * values. Long can represent values between -(2^63) and 2^63 - 1.
   */
  Long: any;
  /** The multiplier path scalar represents a valid GraphQL multiplier path string. */
  MultiplierPath: any;
  Url: any;
  examples__JSON: any;
};






export type Account = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
};

export enum AccountChannels {
  Linkedin = 'LINKEDIN'
}

export type AccountUnion = LinkedInAccount;

export type AchDebitPaymentMethod = Node & PaymentMethod & {
  default: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type Action = {
  category: ActionCategory;
  context: ActionContextUnion;
  id: Scalars['String'];
  name: Scalars['String'];
  type: ActionType;
};

export enum ActionCategory {
  Stent = 'STENT',
  Pipedrive = 'PIPEDRIVE'
}

export type ActionContextUnion = StentCreateContactActionContext | PipedriveCreateContactActionContext;

export enum ActionType {
  Webhook = 'WEBHOOK',
  Workflow = 'WORKFLOW'
}

export enum ActivityStatus {
  Connected = 'CONNECTED',
  Discarded = 'DISCARDED',
  Blocked = 'BLOCKED',
  Rejected = 'REJECTED',
  Approved = 'APPROVED',
  Canceled = 'CANCELED',
  Pending = 'PENDING',
  Processed = 'PROCESSED',
  Pulled = 'PULLED',
  Queued = 'QUEUED',
  Scheduled = 'SCHEDULED',
  Suggested = 'SUGGESTED',
  Removed = 'REMOVED'
}

export type ActivityUnion = SuggestedContentActivity | ScheduledContentActivity | PendingContentActivity | SharedContentActivity | RejectedContentActivity | CampaignCohortActivity;

export type AddMemberPayload = {
  members: Array<WorkspaceMember>;
};


export type Api = {
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
};

export type App = Node & {
  authentication?: Maybe<Authentication>;
  category: Scalars['String'];
  context: Scalars['Boolean'];
  description: Scalars['String'];
  enabled: Scalars['Boolean'];
  icon: Scalars['Url'];
  id: Scalars['ID'];
  image: Scalars['Url'];
  name: Scalars['String'];
};

export type AppConnection = {
  edges: Array<AppEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Long']>;
};

export type AppEdge = {
  cursor: Scalars['String'];
  node: App;
};

export type ApprovePendingContactPayload = {
  activity: CampaignCohortActivityUnion;
  success: Scalars['Boolean'];
};

export type ApproveScheduledContactPayload = {
  activity: CampaignCohortActivityUnion;
  success: Scalars['Boolean'];
};

export type ApproveScheduledContentPayload = {
  activity: ActivityUnion;
  success: Scalars['Boolean'];
};

export type ArchiveMediaPayload = {
  media: MediaUnion;
  status: Scalars['String'];
};

export type ArticleMedia = Node & Media & {
  contentUrl: Scalars['String'];
  createdAt: Scalars['Long'];
  id: Scalars['ID'];
  posts: Array<PosttUnion>;
  reach: AudienceReach;
  reactions: Reaction;
  shortLink?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  summary: Scalars['String'];
  thumbnailUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type AudienceReach = {
  comments: Scalars['Int'];
  likes: Scalars['Int'];
  shares: Scalars['Int'];
  views: Scalars['Int'];
};

export type Audit = {
  name: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
};

export type AuditUnion = LinkedInPersonalAudit | LinkedInNetworkAudit;

export type Authentication = {
  clientId?: Maybe<Scalars['String']>;
  redirectUri?: Maybe<Scalars['Url']>;
  type: Scalars['String'];
  urls?: Maybe<Urls>;
};

export type Billing = {
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Country;
  currency: Scalars['String'];
  name: Scalars['String'];
  recipients?: Maybe<Array<Maybe<ContactInfo>>>;
  state?: Maybe<Scalars['String']>;
  vat?: Maybe<Scalars['String']>;
  zip: Scalars['String'];
};

export type BillingInput = {
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: CountryInput;
  currency: Scalars['String'];
  name: Scalars['String'];
  recipients?: Maybe<Array<Maybe<ContactInfoInput>>>;
  state?: Maybe<Scalars['String']>;
  vat?: Maybe<Scalars['String']>;
  zip: Scalars['String'];
};

export type BillingPayload = {
  billing: Billing;
};

export type Blocking = {
  api: Scalars['Boolean'];
  invite: Scalars['Boolean'];
  message: Scalars['Boolean'];
};

export type CallToActionLinkClickSignal = Node & Signal & {
  body: Scalars['String'];
  campaign?: Maybe<SignalCampaign>;
  id: Scalars['ID'];
  owner: SignalOwner;
  pillar: SignalPillar;
  timestamp: Scalars['Long'];
  type: Scalars['String'];
  url: Scalars['String'];
};

export type Campaign = Node & {
  approval: CampaignApproval;
  contacts: ContactConnection;
  description?: Maybe<Scalars['String']>;
  digest?: Maybe<CampaignDigestUnion>;
  funnel: Array<FunnelLane>;
  health: CampaignHealth;
  history: Array<CampaignDigestUnion>;
  id: Scalars['ID'];
  lane: ContactConnection;
  name: Scalars['String'];
  onHold: Scalars['Boolean'];
  parameters: CampaignParameters;
  program: Scalars['String'];
  schedule: CampaignSchedule;
  segmentById: ContactSegment;
  segments: Array<ContactSegment>;
  target: CampaignTargets;
  type: CampaignTypes;
  workspace: Workspace;
};


export type CampaignContactsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where: CampaignContactsFilterInput;
};


export type CampaignHistoryArgs = {
  cumulative?: Maybe<Scalars['Boolean']>;
  days?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Long']>;
};


export type CampaignLaneArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  key: ContactSegmentKeys;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<CampaignLaneFilterInput>;
};


export type CampaignSegmentByIdArgs = {
  id: Scalars['String'];
};

export type CampaignActivityFilterInput = {
  activityStatus?: Maybe<ActivityStatus>;
  name?: Maybe<Scalars['String']>;
};

export type CampaignApproval = {
  method?: Maybe<CampaignApprovalMethods>;
  type: CampaignApprovalTypes;
};

export enum CampaignApprovalMethods {
  Forget = 'FORGET',
  Sliding = 'SLIDING'
}

export enum CampaignApprovalTypes {
  Optin = 'OPTIN',
  Optout = 'OPTOUT'
}

export type CampaignCohortActivity = Node & {
  activity: Array<CampaignCohortActivityUnion>;
  campaign: Campaign;
  closeAt: Scalars['Long'];
  id: Scalars['ID'];
  startAt: Scalars['Long'];
  timestamp: Scalars['Long'];
  workspace?: Maybe<Workspace>;
};

export type CampaignCohortActivityUnion = PendingContactActivity | ScheduledContactActivity | CanceledContactActivity | ProcessedContactActivity | ConnectedContactActivity | SuggestedContactActivity | RejectedContactActivity | DiscardedContactActivity;

export type CampaignConnection = {
  edges: Array<CampaignEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Long']>;
};

export type CampaignContactsFilterInput = {
  excludes?: Maybe<Array<ContactSegmentKeys>>;
  includes: Array<ContactSegmentKeys>;
  name?: Maybe<Scalars['String']>;
};

export type CampaignDigestUnion = InviteCampaignDigest | NurtureCampaignDigest;

export type CampaignEdge = {
  cursor: Scalars['String'];
  node: Campaign;
};

export type CampaignHealth = {
  errors?: Maybe<Array<Scalars['String']>>;
  status: Scalars['String'];
  warnings?: Maybe<Array<Scalars['String']>>;
};

export type CampaignLaneFilterInput = {
  name: Scalars['String'];
};

export type CampaignMessageInput = {
  body: Scalars['String'];
  rules?: Maybe<CampaignMessageRulesInput>;
  variations?: Maybe<Array<CampaignMessageVariationInput>>;
};

export type CampaignMessageRulesInput = {
  stopIfIncomings?: Maybe<Scalars['Boolean']>;
  stopIfOutgoings?: Maybe<Scalars['Boolean']>;
};

export type CampaignMessageVariationInput = {
  body: Scalars['String'];
  lang: Scalars['String'];
};

export type CampaignNotificationsInput = {
  email?: Maybe<Scalars['Boolean']>;
  mobile?: Maybe<Scalars['Boolean']>;
};

export type CampaignParameters = {
  cohort: CampaignParametersCohort;
};

export type CampaignParametersCohort = {
  group: Scalars['Boolean'];
  size: Scalars['Int'];
};

export type CampaignSchedule = {
  slots: Array<CampaignScheduleSlot>;
  timezone: Timezone;
};

export type CampaignScheduleInput = {
  endDate?: Maybe<Scalars['String']>;
  slots?: Maybe<Array<CampaignScheduleSlotInput>>;
  slotSize?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

export type CampaignScheduleSlot = {
  cron: Scalars['String'];
  duration: Scalars['String'];
};

export type CampaignScheduleSlotInput = {
  cron: Scalars['String'];
  duration: Scalars['String'];
};

export type CampaignSummaryActivity = {
  count: Scalars['Long'];
  status: ActivityStatus;
};

export enum CampaignTargets {
  Lead = 'LEAD',
  Candidate = 'CANDIDATE',
  Other = 'OTHER'
}

export enum CampaignTypes {
  Invite = 'INVITE',
  Nurture = 'NURTURE'
}

export type CanceledContactActivity = Node & {
  contact: Profile;
  errorCode?: Maybe<Scalars['String']>;
  httpStatus?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
  timestamp: Scalars['Long'];
};

export enum CardPaymentBrand {
  Visa = 'VISA',
  Mastercard = 'MASTERCARD'
}

export type CardPaymentMethod = Node & PaymentMethod & {
  brand: CardPaymentBrand;
  default: Scalars['Boolean'];
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  id: Scalars['ID'];
  last4: Scalars['Int'];
};

export type ChangeFinderStatusInput = {
  finderId?: Maybe<Scalars['ID']>;
};

export type ChangeFinderStatusPayload = {
  finder?: Maybe<Finder>;
  success: Scalars['Boolean'];
};

export type ChangeInviteCodePayload = {
  workspace: Workspace;
};

export type ChangeMemberStatusInput = {
  id?: Maybe<Scalars['ID']>;
  status: MemberStatus;
};

export type ChangeMemberStatusPayload = {
  member: WorkspaceMember;
};

export type ChangePlanOrganizationWorkspacePayload = {
  subscription: Subscription;
};

export type Company = {
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CompanyInput = {
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CompanyMatchesFilterInput = {
  filter?: Maybe<Scalars['String']>;
};

export type CompanySegment = Node & Segment & {
  category?: Maybe<SegmentCategories>;
  companies: ICompanyConnection;
  engine?: Maybe<Scalars['String']>;
  entity: SegmentEntities;
  id: Scalars['ID'];
  name: Scalars['String'];
  ownerId?: Maybe<Scalars['String']>;
  scope: SegmentScopes;
  status: SegmentStatus;
  visible: Scalars['Boolean'];
};


export type CompanySegmentCompaniesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<CompanyMatchesFilterInput>;
};

export type CompanyUnion = LinkedInCompany;

export enum Confidence {
  Uncertain = 'UNCERTAIN',
  Ambiguous = 'AMBIGUOUS',
  Probable = 'PROBABLE',
  Similar = 'SIMILAR',
  Exact = 'EXACT',
  Perfect = 'PERFECT',
  Unrelevant = 'UNRELEVANT',
  Pertinent = 'PERTINENT',
  Low = 'LOW',
  Medium = 'MEDIUM',
  Best = 'BEST'
}

export type ConnectAppInput = {
  accessToken?: Maybe<Scalars['String']>;
  apiKey?: Maybe<Scalars['String']>;
  expiresIn?: Maybe<Scalars['Long']>;
  password?: Maybe<Scalars['String']>;
  redirectUri?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

export type ConnectAppPayload = {
  app: App;
};

export type ConnectedContactActivity = Node & {
  connectedAt?: Maybe<Scalars['Long']>;
  contact: Profile;
  id: Scalars['ID'];
  invitedAt?: Maybe<Scalars['Long']>;
  message?: Maybe<Scalars['String']>;
  timestamp: Scalars['Long'];
};

export type ContactConnection = {
  edges: Array<ContactEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Long']>;
};

export type ContactDetectedLanguage = {
  confidence: Scalars['Int'];
  language: Scalars['String'];
};

export type ContactEdge = {
  cursor: Scalars['String'];
  node: Profile;
};

export type ContactInfo = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: Maybe<PhoneNumber>;
};

export type ContactInfoInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: Maybe<PhoneNumberInput>;
};

export type ContactJobPosition = {
  company: CompanyUnion;
  current: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  from?: Maybe<ContactJobPositionTimePeriod>;
  location?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  to?: Maybe<ContactJobPositionTimePeriod>;
};

export type ContactJobPositionTimePeriod = {
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

export type ContactSegment = Node & Segment & {
  category?: Maybe<SegmentCategories>;
  contacts: ContactConnection;
  count: Scalars['Int'];
  engine?: Maybe<Scalars['String']>;
  entity: SegmentEntities;
  filters?: Maybe<ContactSegmentFilters>;
  id: Scalars['ID'];
  name: Scalars['String'];
  ownerId?: Maybe<Scalars['String']>;
  scope: SegmentScopes;
  status: SegmentStatus;
  visible: Scalars['Boolean'];
};


export type ContactSegmentContactsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<ContactSegmentFilterInput>;
};


export type ContactSegmentCountArgs = {
  where?: Maybe<ContactSegmentFilterInput>;
};

export type ContactSegmentFilterInput = {
  name: Scalars['String'];
};

export type ContactSegmentFilters = {
  excludes?: Maybe<Array<ContactSegmentKeys>>;
  includes?: Maybe<Array<ContactSegmentKeys>>;
  name?: Maybe<Scalars['String']>;
};

export enum ContactSegmentKeys {
  Invited = 'INVITED',
  Connected = 'CONNECTED',
  Welcomed = 'WELCOMED',
  Nurtured = 'NURTURED',
  Declined = 'DECLINED',
  Replied = 'REPLIED',
  Clicked = 'CLICKED',
  Leads = 'LEADS'
}

export type ContactSignalsFiltersInput = {
  types?: Maybe<Array<SignalTypes>>;
};

export type ContentPublishingJob = {
  body?: Maybe<Scalars['String']>;
  publishedAt: Scalars['Long'];
  publishedBy: AccountUnion;
};

export type ContentPublishingJobUnion = LinkedInScheduledContentPublishingJob | LinkedInSharedContentPublishingJob | LinkedInRejectedContentPublishingJob | LinkedInFailedContentPublishingJob | LinkedInCorporateContentPublishingJob | LinkedInCorporateScheduledContentPublishingJob | LinkedInCorporateSharedContentPublishingJob | LinkedInCorporateRejectedContentPublishingJob | LinkedInCorporateFailedContentPublishingJob;

export type ContentPublishingMilestone = {
  publishedAt: Scalars['Long'];
  publishedBy: Delegate;
};

export type ContentPublishingNotificationInput = {
  body: Scalars['String'];
  title: Scalars['String'];
};

export type ContentSchedule = {
  body?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  from: Scalars['Long'];
  media: MediaUnion;
  notification: ContentScheduleNotification;
  schedule: Array<ContentPublishingJobUnion>;
  to: Scalars['Long'];
};

export type ContentScheduleConnection = {
  edges: Array<ContentScheduleEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Long']>;
};

export type ContentScheduleEdge = {
  cursor: Scalars['String'];
  node: ContentScheduleUnion;
};

export type ContentScheduleFilterInput = {
  from?: Maybe<Scalars['Long']>;
  to?: Maybe<Scalars['Long']>;
};

export type ContentScheduleNotification = {
  body: Scalars['String'];
  title: Scalars['String'];
};

export type ContentScheduleUnion = LinkedInShareContentSchedule | LinkedInPageContentSchedule | NewsfeedRecommendationContentSchedule;

export type Country = {
  code: Scalars['String'];
  name: Scalars['String'];
};

export type CountryInput = {
  code: Scalars['String'];
  name: Scalars['String'];
};

export type CreateArticleMediaInput = {
  summary: Scalars['String'];
  thumbnailUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  url: Scalars['String'];
};

export type CreateArticleMediaPayload = {
  media: ArticleMedia;
};

export type CreateCampaignQueueInput = {
  campaignId?: Maybe<Scalars['ID']>;
};

export type CreateCompanyFinderFromExcelInput = {
  accountId?: Maybe<Scalars['ID']>;
  fileUrl: Scalars['String'];
  mapping: Array<Maybe<ExcelFinderFieldsMappingInput>>;
  memberId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  schedule?: Maybe<FinderScheduleInput>;
};

export type CreateCompanyFinderFromExcelPayload = {
  finder: Finder;
};

export type CreateContactFinderFromProvisionerInput = {
  accountId?: Maybe<Scalars['ID']>;
  memberId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  provisioner?: Maybe<Scalars['Any']>;
  schedule?: Maybe<FinderScheduleInput>;
};

export type CreateContactFinderFromProvisionerPayload = {
  finder: Finder;
};

export type CreateDocumentMediaInput = {
  fileName: Scalars['String'];
  summary: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type CreateDocumentMediaPayload = {
  media: DocumentMedia;
};

export type CreateFinderQueueInput = {
  finderId?: Maybe<Scalars['ID']>;
};

export type CreateImageMediaInput = {
  fileName: Scalars['String'];
  summary: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type CreateImageMediaPayload = {
  media: ImageMedia;
};

export type CreateInviteCampaignFromFinderInput = {
  accountId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  finderId?: Maybe<Scalars['ID']>;
  invite: CampaignMessageInput;
  memberId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  notifications?: Maybe<CampaignNotificationsInput>;
  program: Scalars['String'];
  schedule?: Maybe<CampaignScheduleInput>;
  target?: Maybe<CampaignTargets>;
  welcome: CampaignMessageInput;
};

export type CreateInviteCampaignFromFinderPayload = {
  campaign: Campaign;
};

export type CreateLinkedInSalesNavigatorAccountsListInput = {
  accountId?: Maybe<Scalars['ID']>;
  companyIds: Array<Scalars['String']>;
  defer: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  overwrite: Scalars['Boolean'];
};

export type CreateLinkedInSalesNavigatorAccountsListPayload = {
  jobId?: Maybe<Scalars['String']>;
  listId?: Maybe<Scalars['ID']>;
};

export type CreateLinkedInSalesNavigatorLeadsListInput = {
  accountId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type CreateLinkedInSalesNavigatorLeadsListPayload = {
  listId?: Maybe<Scalars['ID']>;
};

export type CreateNurtureCampaignFromSegmentInput = {
  accountId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  notifications?: Maybe<CampaignNotificationsInput>;
  nurture: CampaignMessageInput;
  program: Scalars['String'];
  schedule?: Maybe<CampaignScheduleInput>;
  segmentId?: Maybe<Scalars['ID']>;
  target?: Maybe<CampaignTargets>;
};

export type CreateNurtureCampaignFromSegmentPayload = {
  campaign: Campaign;
};

export type CreateSegmentEnterTriggerInput = {
  payload?: Maybe<Scalars['Any']>;
  segmentId?: Maybe<Scalars['ID']>;
  workflowId?: Maybe<Scalars['ID']>;
};

export type CreateSegmentEnterTriggerPayload = {
  triggerId?: Maybe<Scalars['ID']>;
};

export type CreateSegmentFromCampaignContactsInput = {
  filters: CampaignContactsFilterInput;
  /** Segment name */
  name: Scalars['String'];
};

export type CreateSegmentFromCampaignContactsPayload = {
  buildJobId: Scalars['String'];
  segment: SegmentUnion;
  success: Scalars['Boolean'];
};

export type CreateSegmentQueueInput = {
  segmentId?: Maybe<Scalars['ID']>;
};

export type CreateVideoMediaInput = {
  fileName: Scalars['String'];
  summary: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type CreateVideoMediaPayload = {
  media: VideoMedia;
};

export type CreditTypeCounter = {
  count: Scalars['Int'];
  type: Scalars['String'];
};

export type CreditsUsage = {
  /** Return current credit usage */
  current?: Maybe<CurrentCreditsUsage>;
  /** Return periods containing history */
  history: Array<Maybe<UsageHistory>>;
  /** Get past credit usage */
  past: PastCreditsUsage;
};


export type CreditsUsagePastArgs = {
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type CurrentCreditsUsage = {
  cumulated: Array<Maybe<Plot>>;
  daily: Array<Maybe<Plot>>;
  forecast: Array<Maybe<CreditTypeCounter>>;
  periodEnd: Scalars['Long'];
  periodStart: Scalars['Long'];
  trend: Array<Maybe<Plot>>;
  used: Array<Maybe<CreditTypeCounter>>;
};

export enum DataType {
  String = 'STRING',
  Date = 'DATE',
  Bool = 'BOOL',
  Int = 'INT'
}


export type DefaultPaymentMethodPayload = {
  defaultPaymentMethod: Scalars['String'];
};

export type Delegate = Node & {
  account: AccountUnion;
  id: Scalars['ID'];
  member: WorkspaceMember;
};

export type DeleteLinkedInSalesNavigatorListInput = {
  accountId?: Maybe<Scalars['ID']>;
  listId?: Maybe<Scalars['ID']>;
  memberId?: Maybe<Scalars['ID']>;
};

export type DeleteLinkedInSalesNavigatorListPayload = {
  success: Scalars['Boolean'];
};

export type DeleteSegmentPayload = {
  success: Scalars['Boolean'];
};

export type DiscardPendingInvitePayload = {
  activity: CampaignCohortActivityUnion;
  success: Scalars['Boolean'];
};

export type DiscardScheduledContentPayload = {
  activity: ActivityUnion;
  success: Scalars['Boolean'];
};

export type DiscardedContactActivity = Node & {
  contact: Profile;
  discardedAt?: Maybe<Scalars['Long']>;
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  timestamp: Scalars['Long'];
};

export type DisconnectAppPayload = {
  app: App;
};

export type DocumentMedia = Node & Media & {
  contentUrl: Scalars['String'];
  createdAt: Scalars['Long'];
  fileName: Scalars['String'];
  id: Scalars['ID'];
  posts: Array<PosttUnion>;
  reach: AudienceReach;
  reactions: Reaction;
  summary: Scalars['String'];
  thumbnailUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DuplicateSegmentInput = {
  patch: SegmentPatchInput;
  /** Template segment ID */
  segmentId?: Maybe<Scalars['ID']>;
};

export type DuplicateSegmentPayload = {
  segment?: Maybe<SegmentUnion>;
  success: Scalars['Boolean'];
};

export type EmptyOrganizationInput = {
  addressLine1: Scalars['String'];
  city: Scalars['String'];
  contact: ContactInfoInput;
  country: CountryInput;
  name: Scalars['String'];
  zip: Scalars['String'];
};

export type EnqueueCampaignActivityPayload = {
  activity: CampaignCohortActivityUnion;
  success: Scalars['Boolean'];
};

export type Event = {
  category: EventCategory;
  context: EventContextUnion;
  id: Scalars['String'];
  name: Scalars['String'];
  type: EventType;
};

export enum EventCategory {
  Stent = 'STENT'
}

export type EventContextUnion = StentSegmentEnterEventContext;

export enum EventType {
  Segment = 'SEGMENT',
  Signal = 'SIGNAL',
  Webhook = 'WEBHOOK'
}

export type ExcelFinderFieldsMappingInput = {
  destination: Scalars['String'];
  source: Scalars['String'];
};

export type ExcelFinderProvisioner = FinderProvisioner & {
  fileUrl: Scalars['String'];
  hasHeaders?: Maybe<Scalars['Boolean']>;
  mapping: Array<ExcelFinderProvisionerMapping>;
  persona?: Maybe<FinderProvisionerPersona>;
  size?: Maybe<Scalars['Long']>;
  sync?: Maybe<FinderProvisionerSync>;
  type: Scalars['String'];
};

export type ExcelFinderProvisionerMapping = {
  destination: Scalars['String'];
  source: ExcelFinderProvisionerMappingSource;
};

export type ExcelFinderProvisionerMappingSource = {
  column: Scalars['String'];
  index: Scalars['Int'];
};

export type ExportCampaignQueueToSpreadsheetInput = {
  campaignId?: Maybe<Scalars['ID']>;
  campaignName?: Maybe<Scalars['String']>;
  campaignProgram?: Maybe<Scalars['String']>;
  fileName: Scalars['String'];
};

export type ExportCampaignQueueToSpreadsheetPayload = {
  jobId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ExportFinderMatchesToSpreadsheetInput = {
  fileName: Scalars['String'];
  finderId?: Maybe<Scalars['ID']>;
};

export type ExportFinderMatchesToSpreadsheetPayload = {
  jobId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ExportFunnelLanePayload = {
  jobId: Scalars['String'];
  success: Scalars['Boolean'];
};

export type ExportSegmentPayload = {
  jobId: Scalars['String'];
  success: Scalars['Boolean'];
};

export type FetchFinderMatchesInput = {
  finderId?: Maybe<Scalars['ID']>;
};

export type FetchFinderMatchesPayload = {
  batchId?: Maybe<Scalars['String']>;
  finder?: Maybe<Finder>;
  success: Scalars['Boolean'];
};

export type Finder = Node & {
  additionalMembers?: Maybe<Array<WorkspaceMember>>;
  emailLookup: Scalars['Boolean'];
  entity: FinderEntities;
  fetched: Scalars['Long'];
  id: Scalars['ID'];
  matches: FinderMatchesConnection;
  name: Scalars['String'];
  output: FinderOutputUnion;
  owner: WorkspaceMember;
  processed: Scalars['Long'];
  progress: Scalars['Float'];
  provisioner: FinderProvisionerUnion;
  schedule: FinderSchedule;
  state: FinderStates;
  status: FinderStatus;
  synced: Scalars['Long'];
  target: FinderTargets;
  unsynced: Scalars['Long'];
  version?: Maybe<Scalars['Int']>;
};


export type FinderMatchesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<FindersMatchesSortInput>;
  where?: Maybe<FindersMatchesFilterInput>;
};

export type FinderConnection = {
  edges: Array<FinderEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Long']>;
};

export type FinderEdge = {
  cursor: Scalars['String'];
  node: Finder;
};

export enum FinderEntities {
  Contact = 'CONTACT',
  Company = 'COMPANY'
}

export enum FinderFields {
  Name = 'NAME',
  Owner = 'OWNER',
  State = 'STATE'
}

export type FinderLookup = Node & {
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  identifier?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  metadata?: Maybe<Array<FinderProvisionerLookupMetadata>>;
  pictureUrl?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type FinderMatch = Node & {
  city?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  identifier?: Maybe<Scalars['String']>;
  industry?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type FinderMatchSync = {
  status: FinderProvisionerMatchStatus;
  timestamp?: Maybe<Scalars['Long']>;
};

export type FinderMatches = {
  lookup: FinderLookup;
  match?: Maybe<FinderMatch>;
  relevancy?: Maybe<FinderMatching>;
  sync?: Maybe<FinderMatchSync>;
};

export type FinderMatchesConnection = {
  edges: Array<FinderMatchesEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Long']>;
};

export type FinderMatchesEdge = {
  cursor: Scalars['String'];
  node: FinderMatches;
};

export enum FinderMatchesFields {
  Probability = 'PROBABILITY',
  Lookup = 'LOOKUP',
  Match = 'MATCH',
  Status = 'STATUS'
}

export type FinderMatching = {
  confidence: Confidence;
  probability: Scalars['Float'];
};

export type FinderOutputUnion = ContactSegment | CompanySegment;

export type FinderProvisioner = {
  persona?: Maybe<FinderProvisionerPersona>;
  size?: Maybe<Scalars['Long']>;
  sync?: Maybe<FinderProvisionerSync>;
  type: Scalars['String'];
};

export type FinderProvisionerLookupMetadata = {
  dataType: DataType;
  displayName: Scalars['String'];
  fieldName: Scalars['String'];
};

export enum FinderProvisionerMatchStatus {
  Fetching = 'FETCHING',
  Notfound = 'NOTFOUND',
  Pending = 'PENDING',
  Syncing = 'SYNCING',
  Synchronized = 'SYNCHRONIZED',
  Failed = 'FAILED'
}

export type FinderProvisionerMetadata = {
  dataType: DataType;
  displayName: Scalars['String'];
  fieldName: Scalars['String'];
};

export type FinderProvisionerPersona = {
  fileUrl?: Maybe<Scalars['String']>;
  profileUrl?: Maybe<Scalars['String']>;
  source?: Maybe<FinderProvisionerPersonaSources>;
  text?: Maybe<Scalars['String']>;
};

export enum FinderProvisionerPersonaSources {
  Text = 'TEXT',
  File = 'FILE',
  Profile = 'PROFILE'
}

export enum FinderProvisionerStates {
  Processing = 'PROCESSING',
  Error = 'ERROR',
  Failed = 'FAILED',
  Succeeded = 'SUCCEEDED',
  Suspended = 'SUSPENDED'
}

export enum FinderProvisionerStatus {
  Fetch = 'FETCH',
  Lookup = 'LOOKUP',
  Sync = 'SYNC',
  Done = 'DONE'
}

export type FinderProvisionerSync = {
  jobId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  state?: Maybe<FinderProvisionerStates>;
  status?: Maybe<FinderProvisionerStatus>;
  timestamp?: Maybe<Scalars['Long']>;
};

export type FinderProvisionerUnion = LinkedSalesListFinderProvisioner | LinkedSalesSearchFinderProvisioner | GoogleNewsFinderProvisioner | GitHubFinderProvisioner | LinkedInNetworkFinderProvisioner | LinkedInSuggestedFinderProvisioner | LinkedInRecruiterSearchFinderProvisioner | ExcelFinderProvisioner | LinkedInRecruiterInboxFinderProvisioner;

export enum FinderProvisioners {
  Excel = 'EXCEL',
  Github = 'GITHUB',
  GoogleNews = 'GOOGLE_NEWS',
  LinkedinNetworkConnections = 'LINKEDIN_NETWORK_CONNECTIONS',
  LinkedinSalesNavigatorAccountList = 'LINKEDIN_SALES_NAVIGATOR_ACCOUNT_LIST',
  LinkedinRecruiterSavedSearch = 'LINKEDIN_RECRUITER_SAVED_SEARCH',
  LinkedinSalesNavigatorSavedSearch = 'LINKEDIN_SALES_NAVIGATOR_SAVED_SEARCH',
  LinkedinSuggestedContacts = 'LINKEDIN_SUGGESTED_CONTACTS',
  LinkedinRecruiterInbox = 'LINKEDIN_RECRUITER_INBOX'
}

export type FinderSchedule = {
  slots: Array<FinderScheduleSlot>;
  slotSize: Scalars['Int'];
  timezone: Scalars['String'];
};

export type FinderScheduleInput = {
  slots?: Maybe<Array<FinderScheduleSlotInput>>;
  slotSize?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['String']>;
};

export type FinderScheduleSlot = {
  cron: Scalars['String'];
  duration: Scalars['String'];
};

export type FinderScheduleSlotInput = {
  cron: Scalars['String'];
  duration: Scalars['String'];
};

export enum FinderStates {
  Stopped = 'STOPPED',
  Error = 'ERROR',
  Fetching = 'FETCHING',
  Syncing = 'SYNCING',
  Processed = 'PROCESSED',
  Suspended = 'SUSPENDED',
  Idle = 'IDLE'
}

export enum FinderStatus {
  Active = 'ACTIVE',
  Stop = 'STOP',
  Archive = 'ARCHIVE'
}

export enum FinderTargets {
  Lead = 'LEAD',
  Candidate = 'CANDIDATE',
  Other = 'OTHER'
}

export type FindersFilterInput = {
  entity?: Maybe<FinderEntities>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  provisioner?: Maybe<FinderProvisioners>;
  state?: Maybe<FinderStates>;
  status?: Maybe<FinderStatus>;
};

export type FindersMatchesFilterInput = {
  lookupKeywords?: Maybe<Scalars['String']>;
  matchKeywords?: Maybe<Scalars['String']>;
  probabilityMax?: Maybe<Scalars['Int']>;
  probabilityMin?: Maybe<Scalars['Int']>;
  status?: Maybe<FinderProvisionerMatchStatus>;
};

export type FindersMatchesSortInput = {
  field?: Maybe<FinderMatchesFields>;
  order?: Maybe<SortOrder>;
};

export type FindersSortInput = {
  field?: Maybe<FinderFields>;
  order?: Maybe<SortOrder>;
};

export type FunnelLane = {
  count: Scalars['Int'];
  lane: ContactSegmentKeys;
};

export enum Genders {
  Male = 'MALE',
  Female = 'FEMALE'
}

export type GitHubFinderProvisioner = FinderProvisioner & {
  persona?: Maybe<FinderProvisionerPersona>;
  query?: Maybe<Scalars['Any']>;
  size?: Maybe<Scalars['Long']>;
  sync?: Maybe<FinderProvisionerSync>;
  type: Scalars['String'];
};

export type GoogleNewsFinderProvisioner = FinderProvisioner & {
  persona?: Maybe<FinderProvisionerPersona>;
  query?: Maybe<Scalars['Any']>;
  size?: Maybe<Scalars['Long']>;
  sync?: Maybe<FinderProvisionerSync>;
  type: Scalars['String'];
};

export type IActivityConnection = {
  edges: Array<IActivityEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Long']>;
};

export type IActivityEdge = {
  cursor: Scalars['String'];
  node: ActivityUnion;
};

export type ICampaignCohortActivityConnection = {
  edges: Array<ICampaignCohortActivityEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Long']>;
};

export type ICampaignCohortActivityEdge = {
  cursor: Scalars['String'];
  node: CampaignCohortActivityUnion;
};

export type ICompanyConnection = {
  edges: Array<ICompanyEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Long']>;
};

export type ICompanyEdge = {
  cursor: Scalars['String'];
  node: CompanyUnion;
};

export type IWorkspaceMemberConnection = {
  edges: Array<IWorkspaceMemberEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Long']>;
};

export type IWorkspaceMemberEdge = {
  cursor: Scalars['String'];
  node: MemberUnion;
};

export enum IdentityChannel {
  Linkedin = 'LINKEDIN'
}

export type ImageMedia = Node & Media & {
  contentUrl: Scalars['String'];
  createdAt: Scalars['Long'];
  fileName: Scalars['String'];
  id: Scalars['ID'];
  posts: Array<PosttUnion>;
  reach: AudienceReach;
  reactions: Reaction;
  summary: Scalars['String'];
  thumbnailUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type InitPersonalWorkspace = {
  success: Scalars['Boolean'];
};

export type InviteCampaignDigest = {
  acceptance: Scalars['Float'];
  connections: Scalars['Int'];
  invites: Scalars['Int'];
  timestamp: Scalars['Long'];
};

export type InviteOrganizationUserPayload = {
  organizationUser: OrganizationUser;
};

export type InviteOrganizationWorkspaceUserPayload = {
  organizationWorkspaceUser: OrganizationWorkspaceUser;
};

export type InviteSuggestedContactPayload = {
  activity: CampaignCohortActivityUnion;
  success: Scalars['Boolean'];
};

export type InviteUserInput = {
  email: Scalars['String'];
  roles: Array<Maybe<Scalars['String']>>;
};

export type Invoice = {
  amount: Scalars['Int'];
  credits?: Maybe<Scalars['Long']>;
  currency: Scalars['String'];
  dueDate: Scalars['Long'];
  id: Scalars['String'];
  issueDate: Scalars['Long'];
  month: Scalars['Int'];
  number: Scalars['String'];
  paidDate: Scalars['Long'];
  pdf?: Maybe<Scalars['String']>;
  plan: Plan;
  status: InvoiceStatus;
  url?: Maybe<Scalars['String']>;
  /** Retrieve the workspace associated to the invoice */
  workspace?: Maybe<OrganizationWorkspace>;
  year: Scalars['Int'];
};

export enum InvoiceStatus {
  Draft = 'DRAFT',
  Open = 'OPEN',
  Paid = 'PAID',
  Void = 'VOID',
  Uncollectible = 'UNCOLLECTIBLE'
}

export type InvoicesFilterInput = {
  createdMonth?: Maybe<Scalars['Int']>;
  createdYear?: Maybe<Scalars['Int']>;
};

export type Item = {
  href?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  items: Array<Item>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type LinkedInAccount = Node & Account & {
  active: Scalars['Boolean'];
  adminCorporatePages: Array<LinkedInCorporatePage>;
  blocking: Blocking;
  firstName?: Maybe<Scalars['String']>;
  firstSsi?: Maybe<Ssi>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  salesNavigatorAccountLists: Array<SalesNavigatorList>;
  salesNavigatorLeadLists: Array<SalesNavigatorList>;
  ssi?: Maybe<Ssi>;
};


export type LinkedInAccountSalesNavigatorAccountListsArgs = {
  where?: Maybe<SalesNavigatorListsFilterInput>;
};


export type LinkedInAccountSalesNavigatorLeadListsArgs = {
  where?: Maybe<SalesNavigatorListsFilterInput>;
};

export type LinkedInCompany = Node & {
  employeeCountRange?: Maybe<LinkedInEmployeeCountRange>;
  id: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  universalName: Scalars['String'];
};

export type LinkedInCorporateContentPublishingJob = Node & ContentPublishingJob & {
  body?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  origin: LinkedInCorporatePublishingJobOrigin;
  publishedAt: Scalars['Long'];
  publishedBy: AccountUnion;
};

export type LinkedInCorporateFailedContentPublishingJob = Node & ContentPublishingJob & {
  body?: Maybe<Scalars['String']>;
  error: Scalars['String'];
  id: Scalars['ID'];
  origin: LinkedInCorporatePublishingJobOrigin;
  publishedAt: Scalars['Long'];
  publishedBy: AccountUnion;
};

export type LinkedInCorporatePage = {
  id?: Maybe<Scalars['ID']>;
  logoUrl: Scalars['String'];
  name: Scalars['String'];
  urn: Scalars['String'];
};

export type LinkedInCorporatePublishingJobOrigin = {
  actor: Scalars['String'];
  name: Scalars['String'];
};

export type LinkedInCorporateRejectedContentPublishingJob = Node & ContentPublishingJob & {
  body?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  origin: LinkedInCorporatePublishingJobOrigin;
  publishedAt: Scalars['Long'];
  publishedBy: AccountUnion;
};

export type LinkedInCorporateScheduledContentPublishingJob = Node & ContentPublishingJob & {
  body?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  origin: LinkedInCorporatePublishingJobOrigin;
  publishedAt: Scalars['Long'];
  publishedBy: AccountUnion;
};

export type LinkedInCorporateSharedContentPublishingJob = Node & ContentPublishingJob & {
  body?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  origin: LinkedInCorporatePublishingJobOrigin;
  permaLink: Scalars['String'];
  publishedAt: Scalars['Long'];
  publishedBy: AccountUnion;
};

export type LinkedInEmployeeCountRange = {
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
};

export type LinkedInFailedContentPublishingJob = Node & ContentPublishingJob & {
  body?: Maybe<Scalars['String']>;
  error: Scalars['String'];
  id: Scalars['ID'];
  publishedAt: Scalars['Long'];
  publishedBy: AccountUnion;
};

export type LinkedInFinderMatchSignal = Node & Signal & {
  body: Scalars['String'];
  campaign?: Maybe<SignalCampaign>;
  finderId?: Maybe<Scalars['String']>;
  finderName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  owner: SignalOwner;
  pillar: SignalPillar;
  timestamp: Scalars['Long'];
  type: Scalars['String'];
};

export type LinkedInNetworkAudit = Audit & {
  name: Scalars['String'];
  plots: Array<Maybe<Plot>>;
  summary?: Maybe<Scalars['String']>;
};

export type LinkedInNetworkConnectionSignal = Node & Signal & {
  body: Scalars['String'];
  campaign?: Maybe<SignalCampaign>;
  connectedAt: Scalars['Long'];
  id: Scalars['ID'];
  owner: SignalOwner;
  pillar: SignalPillar;
  timestamp: Scalars['Long'];
  type: Scalars['String'];
};

export type LinkedInNetworkDistanceSignal = Node & Signal & {
  body: Scalars['String'];
  campaign?: Maybe<SignalCampaign>;
  distance: Scalars['Int'];
  id: Scalars['ID'];
  owner: SignalOwner;
  pillar: SignalPillar;
  timestamp: Scalars['Long'];
  type: Scalars['String'];
};

export type LinkedInNetworkFinderProvisioner = FinderProvisioner & {
  persona?: Maybe<FinderProvisionerPersona>;
  size?: Maybe<Scalars['Long']>;
  sync?: Maybe<FinderProvisionerSync>;
  type: Scalars['String'];
};

export type LinkedInNetworkInviteSignal = Node & Signal & {
  body: Scalars['String'];
  campaign?: Maybe<SignalCampaign>;
  id: Scalars['ID'];
  owner: SignalOwner;
  pillar: SignalPillar;
  timestamp: Scalars['Long'];
  type: Scalars['String'];
};

export type LinkedInNetworkMessageBlockedSignal = Node & Signal & {
  body: Scalars['String'];
  campaign?: Maybe<SignalCampaign>;
  id: Scalars['ID'];
  owner: SignalOwner;
  pillar: SignalPillar;
  reason: Scalars['String'];
  timestamp: Scalars['Long'];
  type: Scalars['String'];
};

export enum LinkedInNetworkMessageSendingTypes {
  Welcome = 'WELCOME',
  Nurture = 'NURTURE',
  Handwritten = 'HANDWRITTEN'
}

export type LinkedInNetworkMessageSentSignal = Node & Signal & {
  body: Scalars['String'];
  campaign?: Maybe<SignalCampaign>;
  id: Scalars['ID'];
  owner: SignalOwner;
  pillar: SignalPillar;
  sendingType: LinkedInNetworkMessageSendingTypes;
  timestamp: Scalars['Long'];
  type: Scalars['String'];
};

export type LinkedInNetworkReplySignal = Node & Signal & {
  agreement: MessageSentimentAgreement;
  body: Scalars['String'];
  campaign?: Maybe<SignalCampaign>;
  confidence?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  irony: MessageSentimentIrony;
  language?: Maybe<Scalars['String']>;
  owner: SignalOwner;
  pillar: SignalPillar;
  score: MessageSentimentScores;
  sentiment?: Maybe<Scalars['Float']>;
  subjectivity: MessageSentimentSubjectivity;
  timestamp: Scalars['Long'];
  type: Scalars['String'];
};

export type LinkedInPageContentPublishingMilestoneInput = {
  corporatePage: LinkedInPageOriginInput;
  publishedAt: Scalars['Long'];
  publishedBy?: Maybe<Scalars['ID']>;
};

export type LinkedInPageContentSchedule = Node & ContentSchedule & {
  body?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  doNotResend: Scalars['Boolean'];
  from: Scalars['Long'];
  id: Scalars['ID'];
  media: MediaUnion;
  notification: ContentScheduleNotification;
  schedule: Array<ContentPublishingJobUnion>;
  to: Scalars['Long'];
};

export type LinkedInPageOriginInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type LinkedInPageShareInput = {
  doNotResend?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['ID']>;
  message: Scalars['String'];
  notification: ContentPublishingNotificationInput;
  timeline: Array<Maybe<LinkedInPageContentPublishingMilestoneInput>>;
};

export type LinkedInPageSharePatchInput = {
  doNotResend?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type LinkedInPersonaStreamPost = Node & {
  comments: LinkedInPersonaStreamPostComments;
  id: Scalars['ID'];
  likes: LinkedInPersonaStreamPostLikes;
  postedAt: Scalars['Long'];
  postedBy: LinkedInAccount;
  shares: Scalars['Int'];
  text?: Maybe<Scalars['String']>;
  views: Scalars['Int'];
  visibility: LinkedInStreamShareVisibility;
};

export type LinkedInPersonaStreamPostComments = {
  count: Scalars['Int'];
  signals: LinkedInStreamPostCommentSignalConnection;
};


export type LinkedInPersonaStreamPostCommentsSignalsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type LinkedInPersonaStreamPostLikes = {
  count: Scalars['Int'];
  signals: LinkedInStreamPostLikeSignalConnection;
};


export type LinkedInPersonaStreamPostLikesSignalsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type LinkedInPersonalAudit = Audit & {
  level: Scalars['String'];
  moreInfo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  tip?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type LinkedInProfile = {
  connections?: Maybe<Scalars['Int']>;
  followers?: Maybe<Scalars['Int']>;
  vanityId?: Maybe<Scalars['String']>;
};

export type LinkedInProfileViewSignal = Node & Signal & {
  body: Scalars['String'];
  campaign?: Maybe<SignalCampaign>;
  id: Scalars['ID'];
  owner: SignalOwner;
  pillar: SignalPillar;
  timestamp: Scalars['Long'];
  type: Scalars['String'];
};

export type LinkedInRecruiterInboxFinderProvisioner = FinderProvisioner & {
  persona?: Maybe<FinderProvisionerPersona>;
  size?: Maybe<Scalars['Long']>;
  sync?: Maybe<FinderProvisionerSync>;
  type: Scalars['String'];
};

export type LinkedInRecruiterSearchFinderProvisioner = FinderProvisioner & {
  contractId: Scalars['String'];
  metadata: Array<FinderProvisionerMetadata>;
  persona?: Maybe<FinderProvisionerPersona>;
  projectId: Scalars['String'];
  searchId: Scalars['String'];
  size?: Maybe<Scalars['Long']>;
  sync?: Maybe<FinderProvisionerSync>;
  type: Scalars['String'];
};

export type LinkedInRejectedContentPublishingJob = Node & ContentPublishingJob & {
  body?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  publishedAt: Scalars['Long'];
  publishedBy: AccountUnion;
};

export type LinkedInScheduledContentPublishingJob = Node & ContentPublishingJob & {
  body?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  publishedAt: Scalars['Long'];
  publishedBy: AccountUnion;
};

export type LinkedInShareContentSchedule = Node & ContentSchedule & {
  body?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  doNotResend: Scalars['Boolean'];
  from: Scalars['Long'];
  id: Scalars['ID'];
  media: MediaUnion;
  notification: ContentScheduleNotification;
  schedule: Array<ContentPublishingJobUnion>;
  to: Scalars['Long'];
  visibility: LinkedInStreamShareVisibility;
};

export type LinkedInSharedContentPublishingJob = Node & ContentPublishingJob & {
  body?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  permaLink: Scalars['String'];
  publishedAt: Scalars['Long'];
  publishedBy: AccountUnion;
};

export type LinkedInStreamContentPublishingMilestoneInput = {
  publishedAt: Scalars['Long'];
  publishedBy?: Maybe<Scalars['ID']>;
};

export type LinkedInStreamPostCommentSignal = Node & Signal & {
  agreement: MessageSentimentAgreement;
  body: Scalars['String'];
  campaign?: Maybe<SignalCampaign>;
  confidence?: Maybe<Scalars['Float']>;
  contact: Profile;
  id: Scalars['ID'];
  irony: MessageSentimentIrony;
  language?: Maybe<Scalars['String']>;
  owner: SignalOwner;
  pillar: SignalPillar;
  score: MessageSentimentScores;
  sentiment?: Maybe<Scalars['Float']>;
  share: Share;
  subjectivity: MessageSentimentSubjectivity;
  timestamp: Scalars['Long'];
  type: Scalars['String'];
};

export type LinkedInStreamPostCommentSignalConnection = {
  edges: Array<LinkedInStreamPostCommentSignalEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Long']>;
};

export type LinkedInStreamPostCommentSignalEdge = {
  cursor: Scalars['String'];
  node: LinkedInStreamPostCommentSignal;
};

export type LinkedInStreamPostLikeSignal = Node & Signal & {
  body: Scalars['String'];
  campaign?: Maybe<SignalCampaign>;
  contact: Profile;
  id: Scalars['ID'];
  owner: SignalOwner;
  pillar: SignalPillar;
  share: Share;
  timestamp: Scalars['Long'];
  type: Scalars['String'];
};

export type LinkedInStreamPostLikeSignalConnection = {
  edges: Array<LinkedInStreamPostLikeSignalEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Long']>;
};

export type LinkedInStreamPostLikeSignalEdge = {
  cursor: Scalars['String'];
  node: LinkedInStreamPostLikeSignal;
};

export type LinkedInStreamShareInput = {
  doNotResend?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['ID']>;
  message: Scalars['String'];
  notification: ContentPublishingNotificationInput;
  timeline: Array<Maybe<LinkedInStreamContentPublishingMilestoneInput>>;
  visibility: LinkedInStreamShareVisibility;
};

export type LinkedInStreamSharePatchInput = {
  doNotResend?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  visibility?: Maybe<LinkedInStreamShareVisibility>;
};

export enum LinkedInStreamShareVisibility {
  Public = 'PUBLIC',
  Connections = 'CONNECTIONS'
}

export type LinkedInSuggestedFinderProvisioner = FinderProvisioner & {
  persona?: Maybe<FinderProvisionerPersona>;
  size?: Maybe<Scalars['Long']>;
  sourceType: Scalars['String'];
  sync?: Maybe<FinderProvisionerSync>;
  type: Scalars['String'];
};

export type LinkedSalesListFinderProvisioner = FinderProvisioner & {
  listId: Scalars['String'];
  metadata: Array<FinderProvisionerMetadata>;
  persona?: Maybe<FinderProvisionerPersona>;
  size?: Maybe<Scalars['Long']>;
  sync?: Maybe<FinderProvisionerSync>;
  type: Scalars['String'];
};

export type LinkedSalesSearchFinderProvisioner = FinderProvisioner & {
  filterOnCompany: Scalars['Boolean'];
  metadata: Array<FinderProvisionerMetadata>;
  persona?: Maybe<FinderProvisionerPersona>;
  query?: Maybe<Scalars['Any']>;
  size?: Maybe<Scalars['Long']>;
  sync?: Maybe<FinderProvisionerSync>;
  type: Scalars['String'];
};

export type Locale = {
  timezone: Scalars['String'];
};

export type LocaleInput = {
  timezone: Scalars['String'];
};


export type MarkContactAsLeadInput = {
  campaignId?: Maybe<Scalars['String']>;
  contactId: Scalars['String'];
  origin?: Maybe<Scalars['String']>;
  workspaceId: Scalars['String'];
};

export type MarkContactAsLeadPayload = {
  success: Scalars['Boolean'];
};

export type Media = {
  contentUrl: Scalars['String'];
  createdAt: Scalars['Long'];
  summary: Scalars['String'];
  thumbnailUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type MediaConnection = {
  edges: Array<MediaEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Long']>;
};

export type MediaEdge = {
  cursor: Scalars['String'];
  node: MediaUnion;
};

export type MediaFilterInput = {
  archive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<MediaType>;
};

export type MediaQueryController = {
  posts: Array<PosttUnion>;
  reach: AudienceReach;
  reactions: Reaction;
};

export type MediaSortInput = {
  field?: Maybe<MediaSortableFields>;
  order?: Maybe<SortOrder>;
};

export enum MediaSortableFields {
  Title = 'TITLE',
  Publishedat = 'PUBLISHEDAT'
}

export enum MediaType {
  Article = 'ARTICLE',
  Document = 'DOCUMENT',
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export type MediaUnion = ArticleMedia | DocumentMedia | ImageMedia | VideoMedia;

export type MemberInput = {
  id?: Maybe<Scalars['ID']>;
  type: MemberTypes;
};

export type MemberReaction = {
  member: WorkspaceMember;
  mood: ReactionMoods;
};

export type MemberReactionConnection = {
  edges: Array<MemberReactionEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Long']>;
};

export type MemberReactionEdge = {
  cursor: Scalars['String'];
  node: MemberReaction;
};

export enum MemberStatus {
  Active = 'ACTIVE',
  Disabled = 'DISABLED',
  Archive = 'ARCHIVE'
}

export enum MemberTypes {
  Member = 'MEMBER',
  Group = 'GROUP'
}

export type MemberUnion = WorkspaceMember | WorkspaceGroup;

export type MembersFilterInput = {
  channel?: Maybe<AccountChannels>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<MemberStatus>;
  type?: Maybe<MemberTypes>;
};

export enum MessageSentimentAgreement {
  Agreement = 'AGREEMENT',
  Disagreement = 'DISAGREEMENT'
}

export enum MessageSentimentIrony {
  NonIronic = 'NON_IRONIC',
  Ironic = 'IRONIC'
}

export enum MessageSentimentScores {
  StrongPositive = 'STRONG_POSITIVE',
  Positive = 'POSITIVE',
  Neutral = 'NEUTRAL',
  Negative = 'NEGATIVE',
  StrongNegative = 'STRONG_NEGATIVE',
  WithoutPolarity = 'WITHOUT_POLARITY'
}

export enum MessageSentimentSubjectivity {
  Objective = 'OBJECTIVE',
  Subjective = 'SUBJECTIVE'
}

export type MessageVariation = {
  body: Scalars['String'];
  lang: Scalars['String'];
};

export type MoveContactToBlackListInput = {
  campaignId?: Maybe<Scalars['String']>;
  contactId: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type MoveContactToBlackListPayload = {
  success: Scalars['Boolean'];
};


export type Mutation = {
  organization: OrganizationManagementMutationController;
  organizationContext: OrganizationMutationContext;
  userContext: UserMutationContext;
  workspaceContext: WorkspaceMutationContext;
};

export type Navigation = {
  items: Array<Item>;
};

export type NewOrganizationInput = {
  addressLine1?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  contact: ContactInfoInput;
  country: CountryInput;
  locale?: Maybe<LocaleInput>;
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  priceLookup?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type NewsfeedRecommendationAudienceInput = {
  id?: Maybe<Scalars['ID']>;
  type: MemberTypes;
};

export type NewsfeedRecommendationContentSchedule = Node & ContentSchedule & {
  allowReactions: Scalars['Boolean'];
  allowSharing: Scalars['Boolean'];
  audience: Array<MemberUnion>;
  body?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  from: Scalars['Long'];
  id: Scalars['ID'];
  media: MediaUnion;
  notification: ContentScheduleNotification;
  schedule: Array<ContentPublishingJobUnion>;
  to: Scalars['Long'];
};

export type NewsfeedRecommendationInput = {
  allowReactions?: Maybe<Scalars['Boolean']>;
  allowSharing?: Maybe<Scalars['Boolean']>;
  audience?: Maybe<Array<Maybe<NewsfeedRecommendationAudienceInput>>>;
  date: Scalars['String'];
  mediaId?: Maybe<Scalars['ID']>;
  message: Scalars['String'];
  notification: ContentPublishingNotificationInput;
};

export type NewsfeedRecommendationPatchInput = {
  allowReactions?: Maybe<Scalars['Boolean']>;
  allowSharing?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type Node = {
  id: Scalars['ID'];
};

export type Notifications = {
  id: Scalars['ID'];
  timestamp: Scalars['Long'];
  pictureUrl?: Maybe<Scalars['Url']>;
  title: Scalars['String'];
  summary: Scalars['String'];
  read: Scalars['Boolean'];
  url: Scalars['Url'];
};

export type NurtureCampaignDigest = {
  clicks: Scalars['Int'];
  messages: Scalars['Int'];
  replies: NurtureCampaignDigestReplies;
  timestamp: Scalars['Long'];
};

export type NurtureCampaignDigestReplies = {
  count: Scalars['Int'];
  sentiment: Scalars['Float'];
};

export type Organization = Node & {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contact: ContactInfo;
  country: Country;
  id: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  status: OrganizationStatus;
  zip?: Maybe<Scalars['String']>;
};

export type OrganizationController = {
  /** Check organization name is available */
  isOrganizationNameAvailable: Scalars['Boolean'];
  /** Get the list of organizations */
  organizations: Array<Maybe<Organization>>;
};


export type OrganizationControllerIsOrganizationNameAvailableArgs = {
  name: Scalars['String'];
};

export type OrganizationInput = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contact: ContactInfoInput;
  country: CountryInput;
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type OrganizationManagementMutationController = {
  /** Create a new organization */
  createEmptyOrganization: OrganizationPayload;
  /** Create a new organization */
  createOrganization: OrganizationPayload;
};


export type OrganizationManagementMutationControllerCreateEmptyOrganizationArgs = {
  input: EmptyOrganizationInput;
};


export type OrganizationManagementMutationControllerCreateOrganizationArgs = {
  input: NewOrganizationInput;
};

export type OrganizationMessage = {
  message: Scalars['String'];
  severity: OrganizationMessageSeverity;
  url: Scalars['String'];
};

export enum OrganizationMessageSeverity {
  Low = 'LOW',
  High = 'HIGH',
  Critical = 'CRITICAL'
}

export type OrganizationMutationContext = {
  /** Activate a stopped workspace */
  activateOrganizationWorkspace: OrganizationWorkspacePayload;
  /** Cancel current subscrition */
  cancelCurrentSubscriptions: Scalars['Boolean'];
  /** Cancel a payment setup intent */
  cancelPaymentMethodIntent: PaymentMethodIntentPayload;
  /** Change the specified workspace status */
  changeOrganizationWorkspaceStatus: OrganizationWorkspaceStatusPayload;
  /** Change the plan for the workspace */
  changePlanOrganizationWorkspace: ChangePlanOrganizationWorkspacePayload;
  /** Create a new organization */
  createOrganization: OrganizationPayload;
  /** Create a new workspace in a tenant */
  createOrganizationWorkspace: OrganizationWorkspacePayload;
  /** Create a payment method intent */
  createPaymentMethodIntent: PaymentMethodIntentPayload;
  /** Remove payement method from customer */
  detachOrganizationPaymentMethod: Scalars['Boolean'];
  /** Invite user in organization */
  inviteOrganizationUser: InviteOrganizationUserPayload;
  /** Invite a user in a workspace */
  inviteOrganizationWorkspaceUser: InviteOrganizationWorkspaceUserPayload;
  /** Mark workspace organization as favorite */
  markOrganizationWorkspaceAsFavorite: Scalars['Boolean'];
  /** Resend an invite to a organization user */
  reIniviteOrganizationUser?: Maybe<InviteOrganizationUserPayload>;
  /** Resend an invite to an organization workspace user */
  reInviteOrganizationWorkspaceUser?: Maybe<InviteOrganizationWorkspaceUserPayload>;
  /** Remove user from an organization */
  removeOrganizationUser: Scalars['Boolean'];
  /** Remove organization workspace from user favorites */
  removeOrganizationWorkspaceFromFavorites: Scalars['Boolean'];
  /** Remove user from organization workspace */
  removeOrganizationWorkspaceUser: Scalars['Boolean'];
  /** Update existing organization */
  updateOrganization: OrganizationPayload;
  /** Update billing for the current organization */
  updateOrganizationBilling: BillingPayload;
  /** Update default payment method */
  updateOrganizationDefaultPaymentMethod: DefaultPaymentMethodPayload;
  /** Update organization user roles */
  updateOrganizationUserRoles?: Maybe<UpdateOrganizationUserRolesPayload>;
  /** Update a workspace */
  updateOrganizationWorkspace: OrganizationWorkspacePayload;
  /** Update organization workspace roles */
  updateOrganizationWorkspaceUserRoles?: Maybe<UpdateWorkspaceUserRolesPayload>;
};


export type OrganizationMutationContextActivateOrganizationWorkspaceArgs = {
  id: Scalars['String'];
  priceId: Scalars['String'];
};


export type OrganizationMutationContextCancelCurrentSubscriptionsArgs = {
  id: Scalars['String'];
};


export type OrganizationMutationContextCancelPaymentMethodIntentArgs = {
  id: Scalars['String'];
};


export type OrganizationMutationContextChangeOrganizationWorkspaceStatusArgs = {
  id: Scalars['String'];
  status: Status;
};


export type OrganizationMutationContextChangePlanOrganizationWorkspaceArgs = {
  id: Scalars['String'];
  input: SubscriptionSettingsInput;
  priceId: Scalars['String'];
};


export type OrganizationMutationContextCreateOrganizationArgs = {
  code: Scalars['String'];
  currency: Scalars['String'];
  input: OrganizationInput;
  priceLookup?: Maybe<Scalars['String']>;
  workspace?: Maybe<OrganizationWorkspaceInput>;
};


export type OrganizationMutationContextCreateOrganizationWorkspaceArgs = {
  input: OrganizationWorkspaceInput;
  priceId?: Maybe<Scalars['String']>;
};


export type OrganizationMutationContextDetachOrganizationPaymentMethodArgs = {
  paymentMethodId: Scalars['String'];
};


export type OrganizationMutationContextInviteOrganizationUserArgs = {
  input: InviteUserInput;
};


export type OrganizationMutationContextInviteOrganizationWorkspaceUserArgs = {
  id: Scalars['String'];
  input: InviteUserInput;
};


export type OrganizationMutationContextMarkOrganizationWorkspaceAsFavoriteArgs = {
  id: Scalars['String'];
};


export type OrganizationMutationContextReIniviteOrganizationUserArgs = {
  input: InviteUserInput;
};


export type OrganizationMutationContextReInviteOrganizationWorkspaceUserArgs = {
  id: Scalars['String'];
  input: InviteUserInput;
};


export type OrganizationMutationContextRemoveOrganizationUserArgs = {
  id: Scalars['String'];
};


export type OrganizationMutationContextRemoveOrganizationWorkspaceFromFavoritesArgs = {
  id: Scalars['String'];
};


export type OrganizationMutationContextRemoveOrganizationWorkspaceUserArgs = {
  id: Scalars['String'];
  userKey: Scalars['String'];
};


export type OrganizationMutationContextUpdateOrganizationArgs = {
  input: OrganizationInput;
};


export type OrganizationMutationContextUpdateOrganizationBillingArgs = {
  input: BillingInput;
};


export type OrganizationMutationContextUpdateOrganizationDefaultPaymentMethodArgs = {
  id: Scalars['String'];
};


export type OrganizationMutationContextUpdateOrganizationUserRolesArgs = {
  id: Scalars['String'];
  input: Array<Maybe<Scalars['String']>>;
};


export type OrganizationMutationContextUpdateOrganizationWorkspaceArgs = {
  id: Scalars['String'];
  input: UpdateOrganizationWorkspaceInput;
};


export type OrganizationMutationContextUpdateOrganizationWorkspaceUserRolesArgs = {
  id: Scalars['String'];
  input: Array<Maybe<Scalars['String']>>;
  userKey: Scalars['String'];
};

export type OrganizationPayload = {
  message?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  organizationWorkspace?: Maybe<OrganizationWorkspace>;
  success: Scalars['Boolean'];
};

export type OrganizationQueryContext = {
  /** Get customer billing */
  billing?: Maybe<Billing>;
  /** Retrieve customer invoices */
  invoices: Array<Maybe<Invoice>>;
  /** Return some messages to display in the customer application */
  messages: Array<Maybe<OrganizationMessage>>;
  /** Return the navigation for a user */
  navigation: Navigation;
  /** Retrieve the current organization */
  organization: Organization;
  /** Get customer all payment methods */
  paymentMethods: Array<Maybe<PaymentMethodUnion>>;
  /** Get all the plan available */
  plans: Array<Plan>;
  /** Retrieve a user by it id */
  userById: User;
  /** Get the users of the organization */
  users: OrganizationUserConnection;
  /** Get an organization workspace from it id */
  workspaceById: OrganizationWorkspace;
  /** Get all workspaces of the organization */
  workspaces: OrganizationWorkspaceConnection;
};


export type OrganizationQueryContextInvoicesArgs = {
  filter?: Maybe<InvoicesFilterInput>;
};


export type OrganizationQueryContextPlansArgs = {
  filter?: Maybe<PlansFilterInput>;
  workspaceKey?: Maybe<Scalars['String']>;
};


export type OrganizationQueryContextUserByIdArgs = {
  userId: Scalars['String'];
};


export type OrganizationQueryContextUsersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};


export type OrganizationQueryContextWorkspaceByIdArgs = {
  id: Scalars['String'];
};


export type OrganizationQueryContextWorkspacesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  favorites?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
};

export enum OrganizationStatus {
  Archive = 'ARCHIVE',
  Pending = 'PENDING',
  Active = 'ACTIVE'
}

export type OrganizationUser = Node & {
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  identities?: Maybe<Array<UserIdentity>>;
  invitationDate?: Maybe<Scalars['Long']>;
  lastName?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  preferences: UserPreferences;
  roles?: Maybe<Array<Maybe<Role>>>;
};

export type OrganizationUserConnection = {
  edges: Array<OrganizationUserEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Long']>;
};

export type OrganizationUserEdge = {
  cursor: Scalars['String'];
  node: OrganizationUser;
};

export type OrganizationWorkspace = Node & {
  /** Return available plans for the workspace */
  availablePlans: Array<Maybe<Plan>>;
  company: Company;
  contact: ContactInfo;
  id: Scalars['ID'];
  isFavorite: Scalars['Boolean'];
  locale: Locale;
  /** Return workspace organization */
  organization?: Maybe<Organization>;
  /**
   * Return available plans for the workspace
   * @deprecated User GetAvailablePlans
   */
  plans: Array<Maybe<Plan>>;
  scope: Scope;
  settings: Settings;
  status: Status;
  /** Return the subscription for the workspace */
  subscription?: Maybe<Subscription>;
  timezone: Timezone;
  usage: CreditsUsage;
  userById: User;
  users: OrganizationWorkspaceUserConnection;
  usersByName: Array<Maybe<OrganizationWorkspaceUser>>;
};


export type OrganizationWorkspaceUserByIdArgs = {
  userId: Scalars['String'];
};


export type OrganizationWorkspaceUsersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};


export type OrganizationWorkspaceUsersByNameArgs = {
  name: Scalars['String'];
};

export type OrganizationWorkspaceConnection = {
  edges: Array<OrganizationWorkspaceEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Long']>;
};

export type OrganizationWorkspaceEdge = {
  cursor: Scalars['String'];
  node: OrganizationWorkspace;
};

export type OrganizationWorkspaceInput = {
  company: CompanyInput;
  contact: ContactInfoInput;
  locale: LocaleInput;
  scope: Scope;
};

export type OrganizationWorkspacePayload = {
  organizationWorkspace: OrganizationWorkspace;
};

export type OrganizationWorkspaceStatusPayload = {
  organizationWorkspace: OrganizationWorkspace;
};

export type OrganizationWorkspaceUser = Node & {
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  identities?: Maybe<Array<UserIdentity>>;
  invitationDate?: Maybe<Scalars['Long']>;
  lastName?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  preferences: UserPreferences;
  roles?: Maybe<Array<Maybe<Role>>>;
};

export type OrganizationWorkspaceUserConnection = {
  edges: Array<OrganizationWorkspaceUserEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Long']>;
};

export type OrganizationWorkspaceUserEdge = {
  cursor: Scalars['String'];
  node: OrganizationWorkspaceUser;
};

export type PageInfo = {
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type PastCreditsUsage = {
  cumulated: Array<Maybe<Plot>>;
  daily: Array<Maybe<Plot>>;
  month: Scalars['Int'];
  periodEnd: Scalars['Long'];
  periodStart: Scalars['Long'];
  used: Array<Maybe<CreditTypeCounter>>;
  year: Scalars['Int'];
};

export type PauseAllCampaignsPayload = {
  campaigns: Array<Campaign>;
  success: Scalars['Boolean'];
};

export type PauseCampaignPayload = {
  campaign: Campaign;
  success: Scalars['Boolean'];
};

export type PaymentMethod = {
  default: Scalars['Boolean'];
};

export type PaymentMethodIntentPayload = {
  clientSecret: Scalars['String'];
  id: Scalars['String'];
  status: Scalars['String'];
};

export type PaymentMethodUnion = CardPaymentMethod | AchDebitPaymentMethod | SepaDebitPaymentMethod;

export type PendingContactActivity = Node & {
  contact: Profile;
  id: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  timestamp: Scalars['Long'];
  variations?: Maybe<Array<MessageVariation>>;
};

export type PendingContentActivity = Node & {
  allowReactions?: Maybe<Scalars['Boolean']>;
  allowSharing?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  media: MediaUnion;
  message?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  scheduledAt: Scalars['Long'];
  timestamp: Scalars['Long'];
  workspace?: Maybe<Workspace>;
};

export type PhoneNumber = {
  countryCode?: Maybe<Scalars['String']>;
  internationalNumber?: Maybe<Scalars['String']>;
  localNumber?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  regionCode?: Maybe<Scalars['String']>;
  type?: Maybe<PhoneNumberTypes>;
};

export type PhoneNumberInput = {
  countryCode?: Maybe<Scalars['String']>;
  internationalNumber?: Maybe<Scalars['String']>;
  localNumber?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  regionCode?: Maybe<Scalars['String']>;
  type?: Maybe<PhoneNumberTypes>;
};

export enum PhoneNumberTypes {
  Other = 'OTHER',
  Mobile = 'MOBILE',
  Work = 'WORK',
  Home = 'HOME',
  Pager = 'PAGER',
  Fax = 'FAX'
}

export type PipedriveCreateContactActionContext = {
  mapping?: Maybe<Array<Maybe<Scalars['Any']>>>;
};

export type Plan = Node & {
  active: Scalars['Boolean'];
  changeable: Scalars['Boolean'];
  currency: Scalars['String'];
  custom: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  lookupKey?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  priceTiers: Array<Maybe<PriceTier>>;
  seats: Scalars['Int'];
  tiersMode?: Maybe<Scalars['String']>;
  users: Scalars['Int'];
  visible: Scalars['Boolean'];
};

export type PlansFilterInput = {
  changeable?: Maybe<Scalars['Boolean']>;
  custom?: Maybe<Scalars['Boolean']>;
  visible?: Maybe<Scalars['Boolean']>;
};

export type Plot = {
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type Pool = Node & {
  checkIn?: Maybe<PoolCheckIn>;
  date?: Maybe<Scalars['Long']>;
  details?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  profile: Profile;
  ranking?: Maybe<Scalars['Float']>;
  status: PoolStatus;
};

export type PoolCheckIn = {
  campaign: Campaign;
  member: WorkspaceMember;
  timestamp: Scalars['Long'];
};

export type PoolConnection = {
  edges: Array<PoolEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Long']>;
};

export type PoolEdge = {
  cursor: Scalars['String'];
  node: Pool;
};

export enum PoolFields {
  Status = 'STATUS',
  Date = 'DATE',
  Owner = 'OWNER',
  Contact = 'CONTACT',
  Ranking = 'RANKING'
}

export enum PoolStatus {
  Approved = 'APPROVED',
  Canceled = 'CANCELED',
  Pending = 'PENDING',
  Processed = 'PROCESSED',
  Pulled = 'PULLED',
  Queued = 'QUEUED',
  Rejected = 'REJECTED',
  Scheduled = 'SCHEDULED',
  Removed = 'REMOVED'
}

export type PosttUnion = LinkedInPersonaStreamPost;

export type PriceTier = {
  amount: Scalars['Decimal'];
  limit?: Maybe<Scalars['Int']>;
  type: PriceTierType;
};

export enum PriceTierType {
  Flat = 'FLAT',
  Unit = 'UNIT'
}

export type ProcessedContactActivity = Node & {
  contact: Profile;
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  processedAt?: Maybe<Scalars['Long']>;
  timestamp: Scalars['Long'];
};

export type ProductController = {
  /** Get product plans */
  plans: Array<Maybe<Plan>>;
};


export type ProductControllerPlansArgs = {
  id: Scalars['String'];
};

export type Profile = Node & {
  age?: Maybe<Scalars['Int']>;
  company?: Maybe<CompanyUnion>;
  detectedLanguages?: Maybe<Array<ContactDetectedLanguage>>;
  email?: Maybe<Scalars['String']>;
  fetchSignals: Array<SignalUnion>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Genders>;
  headline?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  journey: Array<SignalUnion>;
  lastName?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  phone?: Maybe<PhoneNumber>;
  pictureUrl?: Maybe<Scalars['String']>;
  positions: Array<ContactJobPosition>;
  preferredLanguage?: Maybe<Scalars['String']>;
  profiles: Array<ProfileUnion>;
  signals: Array<SignalUnion>;
  summary?: Maybe<Scalars['String']>;
};


export type ProfileFetchSignalsArgs = {
  identityKey: Scalars['String'];
  profileKey: Scalars['String'];
  signalTypes?: Maybe<Array<Scalars['String']>>;
};


export type ProfileSignalsArgs = {
  where: ContactSignalsFiltersInput;
};

export type ProfileUnion = LinkedInProfile | TwitterProfile;

export type ProvisionIdentityPayload = {
  success: Scalars['Boolean'];
};

export type PublishingChannel = Node & {
  id: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  preview: Scalars['Boolean'];
};

export type PushCompaniesToLinkedInSalesNavigatorListInput = {
  accountId?: Maybe<Scalars['ID']>;
  companyIds: Array<Scalars['String']>;
  defer: Scalars['Boolean'];
  listId?: Maybe<Scalars['ID']>;
};

export type PushCompaniesToLinkedInSalesNavigatorListPayload = {
  jobId?: Maybe<Scalars['String']>;
  list: SalesNavigatorList;
  success: Scalars['Boolean'];
};

export type Query = {
  organizationContext: OrganizationQueryContext;
  organizations: OrganizationController;
  products: ProductController;
  roles: RoleController;
  userContext: UserQueryContext;
  users: UserController;
  workspaceContext: WorkspaceQueryContext;
};

export type Queue = {
  campaigns?: Maybe<Array<Scalars['String']>>;
  created: Scalars['Long'];
  tenantId: Scalars['String'];
};

export type QueuesFilterInput = {
  campaignId?: Maybe<Scalars['String']>;
  campaignName?: Maybe<Scalars['String']>;
  campaignProgram?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  queueId?: Maybe<Scalars['String']>;
  status?: Maybe<PoolStatus>;
};

export type QueuesSortInput = {
  field?: Maybe<PoolFields>;
  order?: Maybe<SortOrder>;
};

export type RankFinderCandidatesPayload = {
  finder?: Maybe<Finder>;
  success: Scalars['Boolean'];
};

export type ReactToSuggestedContentPayload = {
  activity: ActivityUnion;
  success: Scalars['Boolean'];
};

export type Reaction = {
  count: Scalars['Int'];
  moods: Array<ReactionMoods>;
  userMood?: Maybe<ReactionMoods>;
  users: MemberReactionConnection;
};


export type ReactionUsersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum ReactionMoods {
  Like = 'LIKE',
  Love = 'LOVE',
  Kudo = 'KUDO',
  Smile = 'SMILE',
  Inspire = 'INSPIRE'
}

export type RebuildSegmentInput = {
  segmentId?: Maybe<Scalars['ID']>;
};

export type RebuildSegmentPayload = {
  jobId?: Maybe<Scalars['String']>;
  segment?: Maybe<SegmentUnion>;
  success: Scalars['Boolean'];
};

export type RegisterCurrentUserDeviceTokenPayload = {
  deviceToken?: Maybe<Scalars['ID']>;
};

export type RejectPendingContactPayload = {
  activity: CampaignCohortActivityUnion;
  success: Scalars['Boolean'];
};

export type RejectScheduledContactPayload = {
  activity: CampaignCohortActivityUnion;
  success: Scalars['Boolean'];
};

export type RejectedContactActivity = Node & {
  contact: Profile;
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  rejectedAt?: Maybe<Scalars['Long']>;
  scheduledAt?: Maybe<Scalars['Long']>;
  timestamp: Scalars['Long'];
};

export type RejectedContentActivity = Node & {
  allowReactions?: Maybe<Scalars['Boolean']>;
  allowSharing?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  media: MediaUnion;
  message?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  scheduledAt: Scalars['Long'];
  timestamp: Scalars['Long'];
  workspace?: Maybe<Workspace>;
};

export type RemoveContactFromBlackListInput = {
  contactId: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type RemoveContactFromBlackListPayload = {
  success: Scalars['Boolean'];
};

export type RemoveContactFromLeadsInput = {
  contactId: Scalars['String'];
  workspaceId: Scalars['String'];
};

export type RemoveContactFromLeadsPayload = {
  success: Scalars['Boolean'];
};

export type RemoveContactFromQueueInput = {
  poolId?: Maybe<Scalars['ID']>;
};

export type RemoveContentPayload = {
  activity: ActivityUnion;
  success: Scalars['Boolean'];
};

export type RemoveSuggestedContactPayload = {
  success: Scalars['Boolean'];
};

export type RenewClientSecretPayload = {
  clientSecret: Scalars['String'];
};

export type RequeueContactInput = {
  poolId?: Maybe<Scalars['ID']>;
};

export type ResetFinderPayload = {
  finder?: Maybe<Finder>;
  success: Scalars['Boolean'];
};

export type ResumeAllCampaignsPayload = {
  campaigns: Array<Campaign>;
  success: Scalars['Boolean'];
};

export type ResumeCampaignPayload = {
  campaign: Campaign;
  success: Scalars['Boolean'];
};

export type Role = {
  description: Scalars['String'];
  name: Scalars['String'];
  scope: RoleScope;
  value: Scalars['String'];
};

export type RoleController = {
  roles: Array<Maybe<Role>>;
};


export type RoleControllerRolesArgs = {
  scope: RoleScope;
};

export enum RoleScope {
  Organization = 'ORGANIZATION',
  Workspace = 'WORKSPACE'
}

export type RunFinderInput = {
  batchSize?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['String']>;
  finderId?: Maybe<Scalars['ID']>;
};

export type RunFinderPayload = {
  batchId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SalesNavigatorList = {
  description: Scalars['String'];
  entityCount: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type SalesNavigatorListsFilterInput = {
  name?: Maybe<Scalars['String']>;
};

export type ScheduleCampaignCohortPayload = {
  activity: Array<CampaignCohortActivityUnion>;
  batchId: Scalars['String'];
  success: Scalars['Boolean'];
};

export type ScheduleSegmentInput = {
  cron: Scalars['String'];
  segmentId?: Maybe<Scalars['ID']>;
  timezone?: Maybe<Scalars['String']>;
};

export type ScheduleSegmentPayload = {
  recurringJobId: Scalars['String'];
  segment?: Maybe<SegmentUnion>;
  success: Scalars['Boolean'];
};

export type ScheduledContactActivity = Node & {
  contact: Profile;
  id: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['Long']>;
  timestamp: Scalars['Long'];
  variations?: Maybe<Array<MessageVariation>>;
};

export type ScheduledContentActivity = Node & {
  allowReactions?: Maybe<Scalars['Boolean']>;
  allowSharing?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  media: MediaUnion;
  message?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  scheduledAt: Scalars['Long'];
  timestamp: Scalars['Long'];
  workspace?: Maybe<Workspace>;
};

export type SchedulingDateRangeInput = {
  from: Scalars['String'];
  to: Scalars['String'];
};

export enum SchedulingStategies {
  Asap = 'ASAP',
  Duration = 'DURATION',
  Date = 'DATE',
  Daterange = 'DATERANGE',
  Bestmoment = 'BESTMOMENT'
}

export type SchedulingStategyInput = {
  cron?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  dateRange?: Maybe<SchedulingDateRangeInput>;
  duration?: Maybe<Scalars['String']>;
  stategy: SchedulingStategies;
};

export enum Scope {
  Personal = 'PERSONAL',
  Company = 'COMPANY'
}

export type Segment = {
  category?: Maybe<SegmentCategories>;
  entity: SegmentEntities;
  name: Scalars['String'];
  ownerId?: Maybe<Scalars['String']>;
  scope: SegmentScopes;
  status: SegmentStatus;
  visible: Scalars['Boolean'];
};

export enum SegmentCategories {
  Blacklist = 'BLACKLIST',
  Connected = 'CONNECTED',
  Declined = 'DECLINED',
  Engaged = 'ENGAGED',
  Invited = 'INVITED',
  Leads = 'LEADS',
  Nurtured = 'NURTURED'
}

export type SegmentConnection = {
  edges: Array<SegmentEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Long']>;
};

export type SegmentEdge = {
  cursor: Scalars['String'];
  node: SegmentUnion;
};

export enum SegmentEntities {
  Contact = 'CONTACT',
  Company = 'COMPANY'
}

export type SegmentPatchInput = {
  description?: Maybe<Scalars['String']>;
  identityId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export enum SegmentScopes {
  Identity = 'IDENTITY',
  Tenant = 'TENANT'
}

export type SegmentSnapshot = {
  count: Scalars['Int'];
  datetime: Scalars['String'];
  timestamp: Scalars['Long'];
};

export enum SegmentStatus {
  Active = 'ACTIVE',
  Stop = 'STOP',
  Archive = 'ARCHIVE'
}

export type SegmentUnion = ContactSegment | CompanySegment;

export type SegmentsFilterInput = {
  category?: Maybe<SegmentCategories>;
  entity?: Maybe<SegmentEntities>;
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  scope?: Maybe<SegmentScopes>;
  status?: Maybe<SegmentStatus>;
  visible?: Maybe<Scalars['Boolean']>;
};

export type SendMessageInput = {
  channel: SendMessageInputChannel;
  message: Scalars['String'];
  profileKey: Array<Scalars['String']>;
};

export enum SendMessageInputChannel {
  Linkedin = 'LINKEDIN'
}

export type SendMessagePayload = {
  message: Scalars['String'];
  profileKey: Scalars['String'];
  success: Scalars['Boolean'];
};

export type SepaDebitPaymentMethod = Node & PaymentMethod & {
  default: Scalars['Boolean'];
  email: Scalars['String'];
  id: Scalars['ID'];
  last4: Scalars['Int'];
  name: Scalars['String'];
};

export type Settings = {
  api: Api;
  inviteCode: Scalars['String'];
};

export type Share = Node & {
  channel: ShareChannels;
  contentText?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  sharedAt: Scalars['Long'];
  url: Scalars['String'];
};

export enum ShareChannels {
  Linkedin = 'LINKEDIN'
}

export type ShareContentPayload = {
  activity: ActivityUnion;
  success: Scalars['Boolean'];
};

export type SharedContentActivity = Node & {
  allowReactions?: Maybe<Scalars['Boolean']>;
  allowSharing?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  comments: Scalars['Int'];
  id: Scalars['ID'];
  likes: Scalars['Int'];
  media: MediaUnion;
  message?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  reach: AudienceReach;
  sharedAt: Scalars['Long'];
  shares: Scalars['Int'];
  timestamp: Scalars['Long'];
  views: Scalars['Int'];
  workspace?: Maybe<Workspace>;
};

export type Signal = {
  body: Scalars['String'];
  campaign?: Maybe<SignalCampaign>;
  owner: SignalOwner;
  pillar: SignalPillar;
  timestamp: Scalars['Long'];
  type: Scalars['String'];
};

export enum SignalBoundTypes {
  Profiles = 'PROFILES',
  Companies = 'COMPANIES',
  Identities = 'IDENTITIES',
  Tenants = 'TENANTS',
  Shares = 'SHARES',
  Occupations = 'OCCUPATIONS',
  Regions = 'REGIONS'
}

export type SignalCampaign = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  program?: Maybe<Scalars['String']>;
};

export type SignalOwner = {
  boundKey: Scalars['String'];
  boundType: SignalBoundTypes;
  name: Scalars['String'];
};

export enum SignalPillar {
  Behavioral = 'BEHAVIORAL',
  Relational = 'RELATIONAL',
  Demographic = 'DEMOGRAPHIC',
  Firmographic = 'FIRMOGRAPHIC'
}

export enum SignalTypes {
  LinkedinNurtureMessageBlocked = 'LINKEDIN_NURTURE_MESSAGE_BLOCKED',
  LinkedinSearchSalesMatch = 'LINKEDIN_SEARCH_SALES_MATCH',
  LinkedinNetworkInviteSent = 'LINKEDIN_NETWORK_INVITE_SENT',
  LinkedinNetworkDistance = 'LINKEDIN_NETWORK_DISTANCE',
  LinkedinNetworkConnection = 'LINKEDIN_NETWORK_CONNECTION',
  LinkedinNetworkWelcomeSent = 'LINKEDIN_NETWORK_WELCOME_SENT',
  LinkedinNetworkMessageSent = 'LINKEDIN_NETWORK_MESSAGE_SENT',
  LinkedinNurtureMessageSent = 'LINKEDIN_NURTURE_MESSAGE_SENT',
  LinkedinNetworkMessageReply = 'LINKEDIN_NETWORK_MESSAGE_REPLY',
  LinkClick = 'LINK_CLICK',
  LinkedinNetworkMessageWrite = 'LINKEDIN_NETWORK_MESSAGE_WRITE',
  LinkedinNetworkWelcomeBlocked = 'LINKEDIN_NETWORK_WELCOME_BLOCKED',
  LinkedinStreamPostComment = 'LINKEDIN_STREAM_POST_COMMENT',
  LinkedinStreamPostLike = 'LINKEDIN_STREAM_POST_LIKE',
  LinkedinNetworkProfileView = 'LINKEDIN_NETWORK_PROFILE_VIEW',
  LinkedinNetworkInviteBlocked = 'LINKEDIN_NETWORK_INVITE_BLOCKED'
}

export type SignalUnion = LinkedInNetworkInviteSignal | LinkedInNetworkConnectionSignal | LinkedInNetworkDistanceSignal | LinkedInFinderMatchSignal | LinkedInProfileViewSignal | LinkedInNetworkMessageSentSignal | LinkedInNetworkMessageBlockedSignal | LinkedInNetworkReplySignal | CallToActionLinkClickSignal | LinkedInStreamPostCommentSignal | LinkedInStreamPostLikeSignal;

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Ssi = {
  pillars: Array<Maybe<SsiPillar>>;
  score: Scalars['Float'];
  timestamp: Scalars['Long'];
};

export type SsiPillar = {
  pillar: Scalars['String'];
  score: Scalars['Float'];
};

export type StartCampaignSlotInput = {
  campaignId: Scalars['String'];
  duration?: Maybe<Scalars['String']>;
};

export type StartCampaignSlotPayload = {
  batchId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type StartFinderSlotInput = {
  duration: Scalars['String'];
  finderId?: Maybe<Scalars['ID']>;
};

export type StartFinderSlotPayload = {
  batchId?: Maybe<Scalars['String']>;
  finder?: Maybe<Finder>;
  success: Scalars['Boolean'];
};

export type StartQueuesScoringPayload = {
  jobId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type StartWorkflowInstanceInput = {
  payload?: Maybe<Scalars['Any']>;
  workflowId?: Maybe<Scalars['ID']>;
};

export type StartWorkflowInstancePayload = {
  instanceId?: Maybe<Scalars['ID']>;
};

export type StartWorkflowInstancesInput = {
  description?: Maybe<Scalars['String']>;
  instances: Array<StartWorkflowInstanceInput>;
  workflowId?: Maybe<Scalars['ID']>;
};

export type StartWorkflowInstancesPayload = {
  instances?: Maybe<Array<StartWorkflowInstancePayload>>;
  success: Scalars['Boolean'];
};

export enum Status {
  Archive = 'ARCHIVE',
  Active = 'ACTIVE',
  Stop = 'STOP'
}

export type StentCreateContactActionContext = {
  segment?: Maybe<Scalars['String']>;
};

export type StentSegmentEnterEventContext = {
  segment: Segment;
};

export type StripeCustomer = {
  id: Scalars['String'];
};

export type StripeSubscription = {
  created: Scalars['Long'];
  id: Scalars['String'];
  periodEnd: Scalars['Long'];
  periodStart: Scalars['Long'];
  priceId: Scalars['String'];
};

export type Subscription = Node & {
  id: Scalars['ID'];
  /** Get the plan for the current subscrition */
  plan: Plan;
  /** Get the subscription settings */
  settings: SubscriptionSettings;
  stripe: StripeSubscription;
  usage?: Maybe<SubscriptionUsage>;
};

export type SubscriptionSettings = {
  creditUsageLimit: Scalars['Int'];
  creditUsageNotification: Scalars['Int'];
};

export type SubscriptionSettingsInput = {
  creditUsageLimit: Scalars['Int'];
  creditUsageNotification: Scalars['Int'];
};

export type SubscriptionUsage = {
  current: UsageCounter;
  forecast: UsageCounter;
};

export type SuggestedContactActivity = Node & {
  contact: Profile;
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  timestamp: Scalars['Long'];
};

export type SuggestedContentActivity = Node & {
  allowReactions?: Maybe<Scalars['Boolean']>;
  allowSharing?: Maybe<Scalars['Boolean']>;
  category?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  media: MediaUnion;
  message?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  timestamp: Scalars['Long'];
  workspace?: Maybe<Workspace>;
};

export type SwapScheduledContactPayload = {
  activity: CampaignCohortActivityUnion;
  success: Scalars['Boolean'];
};

export type SyncFinderMatchesInput = {
  duration: Scalars['String'];
  finderId?: Maybe<Scalars['ID']>;
};

export type SyncFinderMatchesPayload = {
  batchId?: Maybe<Scalars['String']>;
  finder?: Maybe<Finder>;
  success: Scalars['Boolean'];
};

export type Timezone = {
  id: Scalars['String'];
  name: Scalars['String'];
  offset: Scalars['Int'];
};

export type Trigger = Node & {
  action: Action;
  description: Scalars['String'];
  event: Event;
  id: Scalars['ID'];
  name: Scalars['String'];
  status: TriggerStatus;
};

export type TriggerConnection = {
  edges: Array<TriggerEdge>;
  hello: Scalars['String'];
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Long']>;
};

export type TriggerEdge = {
  cursor: Scalars['String'];
  node: Trigger;
};

export enum TriggerStatus {
  Active = 'ACTIVE',
  Stop = 'STOP',
  Archive = 'ARCHIVE'
}

export type TriggersFilterInput = {
  name?: Maybe<Scalars['String']>;
  status?: Maybe<SegmentStatus>;
};

export type TwitterProfile = {
  handle: Scalars['String'];
};

export type UnRegisterCurrentUserDeviceTokenPayload = {
  deviceToken?: Maybe<Scalars['ID']>;
};

export type UnarchiveMediaPayload = {
  media: MediaUnion;
  status: Scalars['String'];
};

export type UnreactToSuggestedContentPayload = {
  activity: ActivityUnion;
  success: Scalars['Boolean'];
};

export type UpdateArticleMediaInput = {
  summary?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type UpdateArticleMediaPayload = {
  media: ArticleMedia;
};

export type UpdateDocumentMediaInput = {
  fileName: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type UpdateDocumentMediaPayload = {
  media: DocumentMedia;
};

export type UpdateImageMediaInput = {
  fileName: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type UpdateImageMediaPayload = {
  media: ImageMedia;
};

export type UpdateOrganizationUserRolesPayload = {
  roles: Array<Maybe<Role>>;
};

export type UpdateOrganizationWorkspaceInput = {
  company: CompanyInput;
  contact: ContactInfoInput;
  locale: LocaleInput;
};

export type UpdateScheduledContactLangPayload = {
  success: Scalars['Boolean'];
};

export type UpdateScheduledContactMessagePayload = {
  success: Scalars['Boolean'];
};

export type UpdateScheduledContentPayload = {
  activity: ActivityUnion;
  success: Scalars['Boolean'];
};

export type UpdateVideoMediaInput = {
  fileName: Scalars['String'];
  summary?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type UpdateVideoMediaPayload = {
  media: VideoMedia;
};

export type UpdateWorkspaceUserRolesPayload = {
  roles: Array<Maybe<Role>>;
};


export type Urls = {
  authorize: Scalars['String'];
  refreshToken: Scalars['String'];
  token: Scalars['String'];
};

export type UsageCounter = {
  amount: Scalars['Int'];
  count: Scalars['Int'];
  currency: Scalars['String'];
};

export type UsageHistory = {
  credits: Scalars['Int'];
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type User = Node & {
  /** Get customer all payment methods */
  accounts: Array<Maybe<AccountUnion>>;
  /** Return audit for the current user */
  audits: Array<Maybe<AuditUnion>>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  preferences: UserPreferences;
};


export type UserAuditsArgs = {
  lang: Scalars['String'];
};

export type UserController = {
  /** Retrieve the current organization */
  users: Array<Maybe<User>>;
};


export type UserControllerUsersArgs = {
  name: Scalars['String'];
};

export type UserIdentity = {
  channel: IdentityChannel;
  key?: Maybe<Scalars['String']>;
};

export type UserIdentityInput = {
  channel: IdentityChannel;
  key?: Maybe<Scalars['String']>;
};

export type UserInput = {
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  identities?: Maybe<Array<UserIdentityInput>>;
  lastName?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
};

export type UserMutationContext = {
  /** Add current user to workspace from an invite link */
  addCurrentUserToWorkspaceFromInvite: WorkspacePayload;
  approvePendingContact: ApprovePendingContactPayload;
  approveScheduledContact: ApproveScheduledContactPayload;
  approveScheduledContent: ApproveScheduledContentPayload;
  createSegmentFromCampaignContacts: CreateSegmentFromCampaignContactsPayload;
  /** Create user */
  createUser: UserPayload;
  deleteSegment: DeleteSegmentPayload;
  discardPendingInvite: DiscardPendingInvitePayload;
  discardScheduledContent: DiscardScheduledContentPayload;
  enqueueCampaignActivity: EnqueueCampaignActivityPayload;
  exportFunnelLaneToSpreadsheet: ExportFunnelLanePayload;
  exportSegmentToSpreadsheet: ExportSegmentPayload;
  /** Init personal workspace */
  initPersonalWorkspace: InitPersonalWorkspace;
  inviteSuggestedContact: InviteSuggestedContactPayload;
  markContactAsLead: MarkContactAsLeadPayload;
  /** Process logout from a mobile device */
  mobileLogout: Scalars['Boolean'];
  moveContactToBlackList: MoveContactToBlackListPayload;
  pauseAllCampaigns: PauseAllCampaignsPayload;
  pauseCampaign: PauseCampaignPayload;
  provisionIdentity: ProvisionIdentityPayload;
  reactToSuggestedContent: ReactToSuggestedContentPayload;
  /** register new device token for the current user */
  registerCurrentUserDeviceToken: RegisterCurrentUserDeviceTokenPayload;
  rejectPendingContact: RejectPendingContactPayload;
  rejectScheduledContact: RejectScheduledContactPayload;
  removeContactFromBlackList: RemoveContactFromBlackListPayload;
  removeContactFromLeads: RemoveContactFromLeadsPayload;
  removeContent: RemoveContentPayload;
  removeSuggestedContact: RemoveSuggestedContactPayload;
  resumeAllCampaigns: ResumeAllCampaignsPayload;
  resumeCampaign: ResumeCampaignPayload;
  scheduleCampaignCohort: ScheduleCampaignCohortPayload;
  /** Send Email Validation Code */
  sendEmailValidationCode: Scalars['Boolean'];
  sendMessage: Array<SendMessagePayload>;
  shareContent: ShareContentPayload;
  swapScheduledContact: SwapScheduledContactPayload;
  unreactToSuggestedContent: UnreactToSuggestedContentPayload;
  /** Unregister all devices for current user */
  unRegisterCurrentUserAllDevicesTokens: Array<Maybe<UnRegisterCurrentUserDeviceTokenPayload>>;
  /** Un register existing device token for the current user */
  unRegisterCurrentUserDeviceToken: UnRegisterCurrentUserDeviceTokenPayload;
  /** Update existing user */
  updateCurrentUser: UserPayload;
  /** Update current user preferences */
  updateCurrentUserPreferences: UserPreferencesPayload;
  /** Change the selected language of a campaign message. */
  updateScheduledContactLang: UpdateScheduledContactLangPayload;
  /** Change the selected language of a campaign message. */
  updateScheduledContactMessage: UpdateScheduledContactMessagePayload;
  updateScheduledContent: UpdateScheduledContentPayload;
};


export type UserMutationContextAddCurrentUserToWorkspaceFromInviteArgs = {
  inviteCode: Scalars['String'];
};


export type UserMutationContextApprovePendingContactArgs = {
  poolId: Scalars['String'];
};


export type UserMutationContextApproveScheduledContactArgs = {
  poolId: Scalars['String'];
};


export type UserMutationContextApproveScheduledContentArgs = {
  shareId: Scalars['String'];
};


export type UserMutationContextCreateSegmentFromCampaignContactsArgs = {
  id: Scalars['String'];
  input: CreateSegmentFromCampaignContactsInput;
};


export type UserMutationContextCreateUserArgs = {
  input: UserInput;
};


export type UserMutationContextDeleteSegmentArgs = {
  id: Scalars['String'];
};


export type UserMutationContextDiscardPendingInviteArgs = {
  poolId: Scalars['String'];
};


export type UserMutationContextDiscardScheduledContentArgs = {
  shareId: Scalars['String'];
};


export type UserMutationContextEnqueueCampaignActivityArgs = {
  message?: Maybe<Scalars['String']>;
  poolId: Scalars['String'];
};


export type UserMutationContextExportFunnelLaneToSpreadsheetArgs = {
  campaignId: Scalars['String'];
  laneId: Scalars['String'];
};


export type UserMutationContextExportSegmentToSpreadsheetArgs = {
  id: Scalars['String'];
};


export type UserMutationContextInviteSuggestedContactArgs = {
  body?: Maybe<Scalars['String']>;
  poolId: Scalars['String'];
};


export type UserMutationContextMarkContactAsLeadArgs = {
  input: MarkContactAsLeadInput;
};


export type UserMutationContextMoveContactToBlackListArgs = {
  input: MoveContactToBlackListInput;
};


export type UserMutationContextPauseCampaignArgs = {
  id: Scalars['String'];
};


export type UserMutationContextProvisionIdentityArgs = {
  initJobs: Scalars['Boolean'];
};


export type UserMutationContextReactToSuggestedContentArgs = {
  mood: ReactionMoods;
  recommendationId: Scalars['String'];
};


export type UserMutationContextRegisterCurrentUserDeviceTokenArgs = {
  deviceToken: Scalars['String'];
};


export type UserMutationContextRejectPendingContactArgs = {
  poolId: Scalars['String'];
};


export type UserMutationContextRejectScheduledContactArgs = {
  poolId: Scalars['String'];
};


export type UserMutationContextRemoveContactFromBlackListArgs = {
  input: RemoveContactFromBlackListInput;
};


export type UserMutationContextRemoveContactFromLeadsArgs = {
  input: RemoveContactFromLeadsInput;
};


export type UserMutationContextRemoveContentArgs = {
  recommendationId: Scalars['String'];
};


export type UserMutationContextRemoveSuggestedContactArgs = {
  poolId: Scalars['String'];
};


export type UserMutationContextResumeCampaignArgs = {
  id: Scalars['String'];
};


export type UserMutationContextScheduleCampaignCohortArgs = {
  poolIds: Array<Scalars['String']>;
};


export type UserMutationContextSendEmailValidationCodeArgs = {
  contact: ContactInfoInput;
};


export type UserMutationContextSendMessageArgs = {
  input: SendMessageInput;
};


export type UserMutationContextShareContentArgs = {
  message: Scalars['String'];
  recommendationId: Scalars['String'];
  visibility: LinkedInStreamShareVisibility;
};


export type UserMutationContextSwapScheduledContactArgs = {
  poolId: Scalars['String'];
};


export type UserMutationContextUnreactToSuggestedContentArgs = {
  recommendationId: Scalars['String'];
};


export type UserMutationContextUnRegisterCurrentUserDeviceTokenArgs = {
  deviceToken: Scalars['String'];
};


export type UserMutationContextUpdateCurrentUserArgs = {
  input: UserInput;
};


export type UserMutationContextUpdateCurrentUserPreferencesArgs = {
  input: UserPreferencesInput;
};


export type UserMutationContextUpdateScheduledContactLangArgs = {
  lang: Scalars['String'];
  poolId: Scalars['String'];
};


export type UserMutationContextUpdateScheduledContactMessageArgs = {
  message: Scalars['String'];
  poolId: Scalars['String'];
};


export type UserMutationContextUpdateScheduledContentArgs = {
  message: Scalars['String'];
  shareId: Scalars['String'];
};

export type UserPayload = {
  user?: Maybe<User>;
};

export type UserPreferences = {
  language?: Maybe<Scalars['String']>;
};

export type UserPreferencesInput = {
  language?: Maybe<Scalars['String']>;
};

export type UserPreferencesPayload = {
  user?: Maybe<User>;
};

export type UserQueryContext = {
  activityByDate: Array<ActivityUnion>;
  activityDates: Array<Scalars['String']>;
  campaignActivity: ICampaignCohortActivityConnection;
  campaignActivitySummary: Array<CampaignSummaryActivity>;
  campaignById: Campaign;
  campaigns: CampaignConnection;
  contactById: Profile;
  newstream: IActivityConnection;
  user: User;
  workspaceById: Workspace;
  workspaces: WorkspaceConnection;
};


export type UserQueryContextActivityByDateArgs = {
  date: Scalars['String'];
};


export type UserQueryContextActivityDatesArgs = {
  from: Scalars['String'];
  to: Scalars['String'];
};


export type UserQueryContextCampaignActivityArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<CampaignActivityFilterInput>;
};


export type UserQueryContextCampaignActivitySummaryArgs = {
  id: Scalars['String'];
};


export type UserQueryContextCampaignByIdArgs = {
  id: Scalars['String'];
};


export type UserQueryContextCampaignsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type UserQueryContextContactByIdArgs = {
  id: Scalars['String'];
};


export type UserQueryContextNewstreamArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  corporate?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type UserQueryContextWorkspaceByIdArgs = {
  id: Scalars['String'];
};


export type UserQueryContextWorkspacesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type VideoMedia = Node & Media & {
  contentUrl: Scalars['String'];
  createdAt: Scalars['Long'];
  fileName: Scalars['String'];
  id: Scalars['ID'];
  posts: Array<PosttUnion>;
  reach: AudienceReach;
  reactions: Reaction;
  summary: Scalars['String'];
  thumbnailUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type Workspace = Node & {
  company: Company;
  contact: ContactInfo;
  id: Scalars['ID'];
  locale: Locale;
  /** Return workspace organization */
  organization?: Maybe<Organization>;
  scope: Scope;
  settings: Settings;
  status: Status;
  /** Return the subscription for the workspace */
  subscription?: Maybe<Subscription>;
  timezone: Timezone;
};

export type WorkspaceConnection = {
  edges: Array<WorkspaceEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Long']>;
};

export type WorkspaceController = {
  /** Return workspace organization */
  organization?: Maybe<Organization>;
  /** Return the subscription for the workspace */
  subscription?: Maybe<Subscription>;
  timezone: Timezone;
};

export type WorkspaceEdge = {
  cursor: Scalars['String'];
  node: Workspace;
};

export type WorkspaceGroup = Node & {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type WorkspaceMember = Node & {
  accounts: Array<AccountUnion>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  status?: Maybe<MemberStatus>;
  timezone: Timezone;
};


export type WorkspaceMemberAccountsArgs = {
  where?: Maybe<WorkspaceMemberAccountsFilterInput>;
};

export type WorkspaceMemberAccountsFilterInput = {
  channel?: Maybe<AccountChannels>;
};

export type WorkspaceMemberIdentityInput = {
  id?: Maybe<Scalars['ID']>;
};

export type WorkspaceMutationContext = {
  addMember: AddMemberPayload;
  archiveFinder: ChangeFinderStatusPayload;
  archiveMedia: ArchiveMediaPayload;
  changeInviteCode: ChangeInviteCodePayload;
  changeMemberStatus: ChangeMemberStatusPayload;
  connectApp: ConnectAppPayload;
  createArticleMedia: CreateArticleMediaPayload;
  createCampaignQueue: Queue;
  createCompanyFinderFromExcel: CreateCompanyFinderFromExcelPayload;
  createContactFinderFromProvisioner: CreateContactFinderFromProvisionerPayload;
  createDocumentMedia: CreateDocumentMediaPayload;
  createFinderQueue: Queue;
  createImageMedia: CreateImageMediaPayload;
  createInviteCampaignFromFinder: CreateInviteCampaignFromFinderPayload;
  createLinkedInPageShareContentSchedule: ContentScheduleUnion;
  createLinkedInSalesNavigatorAccountsList: CreateLinkedInSalesNavigatorAccountsListPayload;
  createLinkedInSalesNavigatorLeadsList: CreateLinkedInSalesNavigatorLeadsListPayload;
  createLinkedInStreamShareContentSchedule: ContentScheduleUnion;
  createNewsfeedRecommendationContentSchedule: ContentScheduleUnion;
  createNurtureCampaignFromSegment: CreateNurtureCampaignFromSegmentPayload;
  createSegmentEnterTrigger: CreateSegmentEnterTriggerPayload;
  createSegmentQueue: Queue;
  createVideoMedia: CreateVideoMediaPayload;
  deleteLinkedInSalesNavigatorList: DeleteLinkedInSalesNavigatorListPayload;
  disconnectApp: DisconnectAppPayload;
  duplicateSegment: DuplicateSegmentPayload;
  exportCampaignQueueToSpreadsheet: ExportCampaignQueueToSpreadsheetPayload;
  exportFinderMatchesToSpreadsheet: ExportFinderMatchesToSpreadsheetPayload;
  fetchFinderMatches: FetchFinderMatchesPayload;
  pushCompaniesToLinkedInSalesNavigatorList: PushCompaniesToLinkedInSalesNavigatorListPayload;
  rankFinderCandidates: RankFinderCandidatesPayload;
  rebuildSegment: RebuildSegmentPayload;
  removeContactFromQueue: Pool;
  removeContentSchedule: ContentScheduleUnion;
  renewClientSecret: RenewClientSecretPayload;
  requeueContact: Pool;
  requeueContentPublishingJob: ContentPublishingJobUnion;
  resetFinder: ResetFinderPayload;
  runFinder: RunFinderPayload;
  scheduleSegment: ScheduleSegmentPayload;
  startCampaignSlot: StartCampaignSlotPayload;
  startFinder: ChangeFinderStatusPayload;
  startFinderSlot: StartFinderSlotPayload;
  startQueuesScoring: StartQueuesScoringPayload;
  startWorkflowInstances: StartWorkflowInstancesPayload;
  stopFinder: ChangeFinderStatusPayload;
  syncFinderMatches: SyncFinderMatchesPayload;
  unarchiveFinder: ChangeFinderStatusPayload;
  unarchiveMedia: UnarchiveMediaPayload;
  updateArticleMedia: UpdateArticleMediaPayload;
  updateDocumentMedia: UpdateDocumentMediaPayload;
  updateImageMedia: UpdateImageMediaPayload;
  updateLinkedInPageShareContentSchedule: ContentScheduleUnion;
  updateLinkedInStreamShareContentSchedule: ContentScheduleUnion;
  updateNewsfeedRecommendationContentSchedule: ContentScheduleUnion;
  updateVideoMedia: UpdateVideoMediaPayload;
};


export type WorkspaceMutationContextAddMemberArgs = {
  identities: Array<WorkspaceMemberIdentityInput>;
};


export type WorkspaceMutationContextArchiveFinderArgs = {
  input: ChangeFinderStatusInput;
};


export type WorkspaceMutationContextArchiveMediaArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type WorkspaceMutationContextChangeMemberStatusArgs = {
  input: ChangeMemberStatusInput;
};


export type WorkspaceMutationContextConnectAppArgs = {
  id: Scalars['String'];
  input: ConnectAppInput;
};


export type WorkspaceMutationContextCreateArticleMediaArgs = {
  input: CreateArticleMediaInput;
};


export type WorkspaceMutationContextCreateCampaignQueueArgs = {
  input: CreateCampaignQueueInput;
};


export type WorkspaceMutationContextCreateCompanyFinderFromExcelArgs = {
  input: CreateCompanyFinderFromExcelInput;
};


export type WorkspaceMutationContextCreateContactFinderFromProvisionerArgs = {
  input: CreateContactFinderFromProvisionerInput;
};


export type WorkspaceMutationContextCreateDocumentMediaArgs = {
  input: CreateDocumentMediaInput;
};


export type WorkspaceMutationContextCreateFinderQueueArgs = {
  input: CreateFinderQueueInput;
};


export type WorkspaceMutationContextCreateImageMediaArgs = {
  input: CreateImageMediaInput;
};


export type WorkspaceMutationContextCreateInviteCampaignFromFinderArgs = {
  input: CreateInviteCampaignFromFinderInput;
};


export type WorkspaceMutationContextCreateLinkedInPageShareContentScheduleArgs = {
  input: LinkedInPageShareInput;
};


export type WorkspaceMutationContextCreateLinkedInSalesNavigatorAccountsListArgs = {
  input: CreateLinkedInSalesNavigatorAccountsListInput;
};


export type WorkspaceMutationContextCreateLinkedInSalesNavigatorLeadsListArgs = {
  input: CreateLinkedInSalesNavigatorLeadsListInput;
};


export type WorkspaceMutationContextCreateLinkedInStreamShareContentScheduleArgs = {
  input: LinkedInStreamShareInput;
};


export type WorkspaceMutationContextCreateNewsfeedRecommendationContentScheduleArgs = {
  input: NewsfeedRecommendationInput;
};


export type WorkspaceMutationContextCreateNurtureCampaignFromSegmentArgs = {
  input: CreateNurtureCampaignFromSegmentInput;
};


export type WorkspaceMutationContextCreateSegmentEnterTriggerArgs = {
  input: CreateSegmentEnterTriggerInput;
};


export type WorkspaceMutationContextCreateSegmentQueueArgs = {
  input: CreateSegmentQueueInput;
};


export type WorkspaceMutationContextCreateVideoMediaArgs = {
  input: CreateVideoMediaInput;
};


export type WorkspaceMutationContextDeleteLinkedInSalesNavigatorListArgs = {
  input: DeleteLinkedInSalesNavigatorListInput;
};


export type WorkspaceMutationContextDisconnectAppArgs = {
  id: Scalars['String'];
};


export type WorkspaceMutationContextDuplicateSegmentArgs = {
  input: DuplicateSegmentInput;
};


export type WorkspaceMutationContextExportCampaignQueueToSpreadsheetArgs = {
  input: ExportCampaignQueueToSpreadsheetInput;
};


export type WorkspaceMutationContextExportFinderMatchesToSpreadsheetArgs = {
  input: ExportFinderMatchesToSpreadsheetInput;
};


export type WorkspaceMutationContextFetchFinderMatchesArgs = {
  input: FetchFinderMatchesInput;
};


export type WorkspaceMutationContextPushCompaniesToLinkedInSalesNavigatorListArgs = {
  input: PushCompaniesToLinkedInSalesNavigatorListInput;
};


export type WorkspaceMutationContextRankFinderCandidatesArgs = {
  finderId: Scalars['String'];
};


export type WorkspaceMutationContextRebuildSegmentArgs = {
  input: RebuildSegmentInput;
};


export type WorkspaceMutationContextRemoveContactFromQueueArgs = {
  input: RemoveContactFromQueueInput;
};


export type WorkspaceMutationContextRemoveContentScheduleArgs = {
  id: Scalars['String'];
};


export type WorkspaceMutationContextRenewClientSecretArgs = {
  id: Scalars['String'];
};


export type WorkspaceMutationContextRequeueContactArgs = {
  input: RequeueContactInput;
};


export type WorkspaceMutationContextRequeueContentPublishingJobArgs = {
  id: Scalars['String'];
};


export type WorkspaceMutationContextResetFinderArgs = {
  finderId: Scalars['String'];
};


export type WorkspaceMutationContextRunFinderArgs = {
  input: RunFinderInput;
};


export type WorkspaceMutationContextScheduleSegmentArgs = {
  input: ScheduleSegmentInput;
};


export type WorkspaceMutationContextStartCampaignSlotArgs = {
  input: StartCampaignSlotInput;
};


export type WorkspaceMutationContextStartFinderArgs = {
  input: ChangeFinderStatusInput;
};


export type WorkspaceMutationContextStartFinderSlotArgs = {
  input: StartFinderSlotInput;
};


export type WorkspaceMutationContextStartWorkflowInstancesArgs = {
  input: StartWorkflowInstancesInput;
};


export type WorkspaceMutationContextStopFinderArgs = {
  input: ChangeFinderStatusInput;
};


export type WorkspaceMutationContextSyncFinderMatchesArgs = {
  input: SyncFinderMatchesInput;
};


export type WorkspaceMutationContextUnarchiveFinderArgs = {
  input: ChangeFinderStatusInput;
};


export type WorkspaceMutationContextUnarchiveMediaArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type WorkspaceMutationContextUpdateArticleMediaArgs = {
  id?: Maybe<Scalars['ID']>;
  input: UpdateArticleMediaInput;
};


export type WorkspaceMutationContextUpdateDocumentMediaArgs = {
  id?: Maybe<Scalars['ID']>;
  input: UpdateDocumentMediaInput;
};


export type WorkspaceMutationContextUpdateImageMediaArgs = {
  id?: Maybe<Scalars['ID']>;
  input: UpdateImageMediaInput;
};


export type WorkspaceMutationContextUpdateLinkedInPageShareContentScheduleArgs = {
  id: Scalars['String'];
  input: LinkedInPageSharePatchInput;
};


export type WorkspaceMutationContextUpdateLinkedInStreamShareContentScheduleArgs = {
  id: Scalars['String'];
  input: LinkedInStreamSharePatchInput;
};


export type WorkspaceMutationContextUpdateNewsfeedRecommendationContentScheduleArgs = {
  id: Scalars['String'];
  input: NewsfeedRecommendationPatchInput;
};


export type WorkspaceMutationContextUpdateVideoMediaArgs = {
  id?: Maybe<Scalars['ID']>;
  input: UpdateVideoMediaInput;
};

export type WorkspacePayload = {
  workspace: Workspace;
};

export type WorkspaceQueryContext = {
  accountsByMemberId: Array<AccountUnion>;
  /** Get an app from its ID */
  appById: App;
  /** Get all apps available in the store */
  apps: AppConnection;
  campaigns: CampaignConnection;
  contentScheduleById: ContentScheduleUnion;
  contentSchedules: ContentScheduleConnection;
  finderById: Finder;
  finders: FinderConnection;
  mediaById: MediaUnion;
  medias: MediaConnection;
  members: IWorkspaceMemberConnection;
  navigation: Navigation;
  publishingChannels: Array<PublishingChannel>;
  queues: PoolConnection;
  segmentById: SegmentUnion;
  segments: SegmentConnection;
  shuffleContentScheduleTimeline: Array<ContentPublishingMilestone>;
  triggerById: Trigger;
  triggers: TriggerConnection;
  workspace: Workspace;
};


export type WorkspaceQueryContextAccountsByMemberIdArgs = {
  channel?: Maybe<AccountChannels>;
  memberId: Scalars['String'];
};


export type WorkspaceQueryContextAppByIdArgs = {
  id: Scalars['String'];
};


export type WorkspaceQueryContextAppsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type WorkspaceQueryContextCampaignsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  delegateId?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type WorkspaceQueryContextContentScheduleByIdArgs = {
  id: Scalars['String'];
};


export type WorkspaceQueryContextContentSchedulesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<ContentScheduleFilterInput>;
};


export type WorkspaceQueryContextFinderByIdArgs = {
  id: Scalars['String'];
};


export type WorkspaceQueryContextFindersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<FindersSortInput>;
  where?: Maybe<FindersFilterInput>;
};


export type WorkspaceQueryContextMediaByIdArgs = {
  id: Scalars['String'];
};


export type WorkspaceQueryContextMediasArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<MediaSortInput>;
  where?: Maybe<MediaFilterInput>;
};


export type WorkspaceQueryContextMembersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<MembersFilterInput>;
};


export type WorkspaceQueryContextPublishingChannelsArgs = {
  preview?: Maybe<Scalars['Boolean']>;
};


export type WorkspaceQueryContextQueuesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  sort?: Maybe<QueuesSortInput>;
  where?: Maybe<QueuesFilterInput>;
};


export type WorkspaceQueryContextSegmentByIdArgs = {
  id: Scalars['String'];
};


export type WorkspaceQueryContextSegmentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<SegmentsFilterInput>;
};


export type WorkspaceQueryContextShuffleContentScheduleTimelineArgs = {
  channelId: Scalars['ID'];
  members?: Maybe<Array<MemberInput>>;
  scheduling: SchedulingStategyInput;
};


export type WorkspaceQueryContextTriggerByIdArgs = {
  id: Scalars['String'];
};


export type WorkspaceQueryContextTriggersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<TriggersFilterInput>;
};


export enum Fake__Locale {
  Az = 'az',
  Cz = 'cz',
  De = 'de',
  DeAt = 'de_AT',
  DeCh = 'de_CH',
  En = 'en',
  EnAu = 'en_AU',
  EnBork = 'en_BORK',
  EnCa = 'en_CA',
  EnGb = 'en_GB',
  EnIe = 'en_IE',
  EnInd = 'en_IND',
  EnUs = 'en_US',
  EnAuOcker = 'en_au_ocker',
  Es = 'es',
  EsMx = 'es_MX',
  Fa = 'fa',
  Fr = 'fr',
  FrCa = 'fr_CA',
  Ge = 'ge',
  IdId = 'id_ID',
  It = 'it',
  Ja = 'ja',
  Ko = 'ko',
  NbNo = 'nb_NO',
  Nep = 'nep',
  Nl = 'nl',
  Pl = 'pl',
  PtBr = 'pt_BR',
  Ru = 'ru',
  Sk = 'sk',
  Sv = 'sv',
  Tr = 'tr',
  Uk = 'uk',
  Vi = 'vi',
  ZhCn = 'zh_CN',
  ZhTw = 'zh_TW'
}

export enum Fake__Types {
  ZipCode = 'zipCode',
  City = 'city',
  StreetName = 'streetName',
  /** Configure address with option `useFullAddress` */
  StreetAddress = 'streetAddress',
  SecondaryAddress = 'secondaryAddress',
  County = 'county',
  Country = 'country',
  CountryCode = 'countryCode',
  State = 'state',
  StateAbbr = 'stateAbbr',
  Latitude = 'latitude',
  Longitude = 'longitude',
  ColorName = 'colorName',
  ProductCategory = 'productCategory',
  ProductName = 'productName',
  /** Sum of money. Configure with options `minMoney`/`maxMoney` and 'decimalPlaces'. */
  Money = 'money',
  ProductMaterial = 'productMaterial',
  Product = 'product',
  CompanyName = 'companyName',
  CompanyCatchPhrase = 'companyCatchPhrase',
  CompanyBs = 'companyBS',
  DbColumn = 'dbColumn',
  DbType = 'dbType',
  DbCollation = 'dbCollation',
  DbEngine = 'dbEngine',
  /**
   * By default returns dates beetween 2000-01-01 and 2030-01-01.
   * Configure date format with options `dateFormat` `dateFrom` `dateTo`.
   */
  Date = 'date',
  /** Configure date format with option `dateFormat` */
  PastDate = 'pastDate',
  /** Configure date format with option `dateFormat` */
  FutureDate = 'futureDate',
  /** Configure date format with option `dateFormat` */
  RecentDate = 'recentDate',
  FinanceAccountName = 'financeAccountName',
  FinanceTransactionType = 'financeTransactionType',
  CurrencyCode = 'currencyCode',
  CurrencyName = 'currencyName',
  CurrencySymbol = 'currencySymbol',
  BitcoinAddress = 'bitcoinAddress',
  InternationalBankAccountNumber = 'internationalBankAccountNumber',
  BankIdentifierCode = 'bankIdentifierCode',
  HackerAbbreviation = 'hackerAbbreviation',
  HackerPhrase = 'hackerPhrase',
  /** An image url. Configure image with options: `imageCategory`, `imageWidth`, `imageHeight` and `randomizeImageUrl` */
  ImageUrl = 'imageUrl',
  /** An URL for an avatar */
  AvatarUrl = 'avatarUrl',
  /** Configure email provider with option: `emailProvider` */
  Email = 'email',
  Url = 'url',
  DomainName = 'domainName',
  Ipv4Address = 'ipv4Address',
  Ipv6Address = 'ipv6Address',
  UserAgent = 'userAgent',
  /** Configure color with option: `baseColor` */
  ColorHex = 'colorHex',
  MacAddress = 'macAddress',
  /** Configure password with option `passwordLength` */
  Password = 'password',
  /** Lorem Ipsum text. Configure size with option `loremSize` */
  Lorem = 'lorem',
  FirstName = 'firstName',
  LastName = 'lastName',
  FullName = 'fullName',
  JobTitle = 'jobTitle',
  PhoneNumber = 'phoneNumber',
  Number = 'number',
  Uuid = 'uuid',
  Word = 'word',
  Words = 'words',
  Locale = 'locale',
  Filename = 'filename',
  MimeType = 'mimeType',
  FileExtension = 'fileExtension',
  Semver = 'semver'
}

export type Fake__Color = {
  red255?: Maybe<Scalars['Int']>;
  green255?: Maybe<Scalars['Int']>;
  blue255?: Maybe<Scalars['Int']>;
};

export type Fake__ImageSize = {
  width: Scalars['Int'];
  height: Scalars['Int'];
};

export enum Fake__LoremSize {
  Word = 'word',
  Words = 'words',
  Sentence = 'sentence',
  Sentences = 'sentences',
  Paragraph = 'paragraph',
  Paragraphs = 'paragraphs'
}

export type Fake__Options = {
  /** Only for type `streetAddress` */
  useFullAddress?: Maybe<Scalars['Boolean']>;
  /** Only for type `money` */
  minMoney?: Maybe<Scalars['Float']>;
  /** Only for type `money` */
  maxMoney?: Maybe<Scalars['Float']>;
  /** Only for type `money` */
  decimalPlaces?: Maybe<Scalars['Int']>;
  /** Only for type `imageUrl` */
  imageSize?: Maybe<Fake__ImageSize>;
  /** Only for type `imageUrl`. Example value: `["nature", "water"]`. */
  imageKeywords?: Maybe<Array<Scalars['String']>>;
  /** Only for type `imageUrl` */
  randomizeImageUrl?: Maybe<Scalars['Boolean']>;
  /** Only for type `email` */
  emailProvider?: Maybe<Scalars['String']>;
  /** Only for type `password` */
  passwordLength?: Maybe<Scalars['Int']>;
  /** Only for type `lorem` */
  loremSize?: Maybe<Fake__LoremSize>;
  /** Only for types `*Date`. Example value: `YYYY MM DD`. [Full Specification](http://momentjs.com/docs/#/displaying/format/) */
  dateFormat?: Maybe<Scalars['String']>;
  /** Only for types `betweenDate`. Example value: `1986-11-02`. */
  dateFrom?: Maybe<Scalars['String']>;
  /** Only for types `betweenDate`. Example value: `2038-01-19`. */
  dateTo?: Maybe<Scalars['String']>;
  /** Only for type `colorHex`. [Details here](https://stackoverflow.com/a/43235/4989887) */
  baseColor?: Maybe<Fake__Color>;
  /** Only for type `number` */
  minNumber?: Maybe<Scalars['Float']>;
  /** Only for type `number` */
  maxNumber?: Maybe<Scalars['Float']>;
  /** Only for type `number` */
  precisionNumber?: Maybe<Scalars['Float']>;
};

import gql from "graphql-tag";

export const GET_LOGGED_USER = gql`
  query getLoggedUser {
    userContext {
      user {
        email
        firstName
        lastName
        pictureUrl
      }
    }
  }
`;

export const GET_ROLES = gql`
  query getRoles {
    organization: roles {
      roles(scope: ORGANIZATION) {
        name
        description
        scope
        value
      }
    }
    workspace: roles {
      roles(scope: WORKSPACE) {
        name
        description
        scope
        value
      }
    }
  }
`;

export const GET_BANNER_TEXT = gql`
  query getBannerText {
    organizationContext {
      messages {
        message
        severity
        url
      }
    }
  }
`;

// Snippet from https://www.apollographql.com/docs/react/data/fragments/
export const GET_POSSIBLE_TYPES = gql`
  {
    __schema {
      types {
        kind
        name
        possibleTypes {
          name
        }
      }
    }
  }
`;

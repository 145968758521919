import gql from "graphql-tag";
import { PlanFragment } from "../organizations/fragments";
import { WorkspaceFragment } from "./fragments";

export const ACTIVATE_WORKSPACE = gql`
  mutation activateWorkspace($id: String!, $priceId: String!) {
    organizationContext {
      activateOrganizationWorkspace(id: $id, priceId: $priceId) {
        organizationWorkspace {
          id
        }
      }
    }
  }
`;

export const STOP_WORKSPACE = gql`
  mutation stopWorkspace($id: String!) {
    organizationContext {
      changeOrganizationWorkspaceStatus(id: $id, status: STOP) {
        organizationWorkspace {
          id
        }
      }
    }
  }
`;

export const ARCHIVE_WORKSPACE = gql`
  mutation archiveWorkspace($id: String!) {
    organizationContext {
      changeOrganizationWorkspaceStatus(id: $id, status: ARCHIVE) {
        organizationWorkspace {
          id
        }
      }
    }
  }
`;

export const CHANGE_PLAN_ORGANIZATION_WORKSPACE = gql`
  mutation changePlanOrganizationWorkspace(
    $id: String!
    $input: SubscriptionSettingsInput!
    $priceId: String!
  ) {
    organizationContext {
      changePlanOrganizationWorkspace(
        id: $id
        input: $input
        priceId: $priceId
      ) {
        subscription {
          id
          plan {
            id
            ...PlanFragment
          }
          settings {
            creditUsageLimit
            creditUsageNotification
          }
        }
      }
    }
  }
  ${PlanFragment}
`;

export const CREATE_WORKSPACE = gql`
  mutation createWorkspace(
    $input: OrganizationWorkspaceInput!
    $priceId: String
  ) {
    organizationContext {
      createOrganizationWorkspace(input: $input, priceId: $priceId) {
        organizationWorkspace {
          ...WorkspaceFragment
        }
      }
    }
  }
  ${WorkspaceFragment}
`;

export const UPDATE_WORKSPACE = gql`
  mutation updateOrganizationWorkspace(
    $id: String!
    $input: UpdateOrganizationWorkspaceInput!
  ) {
    organizationContext {
      updateOrganizationWorkspace(id: $id, input: $input) {
        organizationWorkspace {
          ...WorkspaceFragment
        }
      }
    }
  }
  ${WorkspaceFragment}
`;

export const UPDATE_ORGANIZATION_WORKSPACE_USER_ROLES = gql`
  mutation updateOrganizationWorkspaceUserRoles(
    $id: String!
    $input: [String]!
    $userKey: String!
  ) {
    organizationContext {
      updateOrganizationWorkspaceUserRoles(
        id: $id
        input: $input
        userKey: $userKey
      ) {
        roles {
          name
          value
        }
      }
    }
  }
`;

export const REMOVE_ORGANIZATION_WORKSPACE_USER = gql`
  mutation removeOrganizationUser($id: String!, $userKey: String!) {
    organizationContext {
      removeOrganizationWorkspaceUser(id: $id, userKey: $userKey)
    }
  }
`;

export const MARK_ORGANIZATION_WORKSPACE_AS_FAVORITE = gql`
  mutation markOrganizationWorkspaceAsFavorite($id: String!) {
    organizationContext {
      markOrganizationWorkspaceAsFavorite(id: $id)
    }
  }
`;

export const REMOVE_ORGANIZATION_WORKSPACE_FROM_FAVORITE = gql`
  mutation removeOrganizationWorkspaceFromFavorites($id: String!) {
    organizationContext {
      removeOrganizationWorkspaceFromFavorites(id: $id)
    }
  }
`;

export const INVITE_ORGANIZATION_WORKSPACE_USER = gql`
  mutation inviteOrganizationWorkspaceUser(
    $id: String!
    $input: InviteUserInput!
  ) {
    organizationContext {
      inviteOrganizationWorkspaceUser(id: $id, input: $input) {
        organizationWorkspaceUser {
          email
          firstName
          id
          invitationDate
          lastName
          pictureUrl
          roles {
            name
            value
          }
        }
      }
    }
  }
`;


















import Vue from "vue";

export default Vue.extend({
  props: {
    isLoading: {
      type: Boolean,
      default: true
    },
    isMain: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: "accent"
    }
  },
  computed: {
    isVisible(): boolean {
      // Only show if loader is the main one or the main loader is not visible
      return this.isMain || !this.$storeTyped.state.isLoading;
    }
  }
});

import gql from "graphql-tag";
import {
  FilteredWorkspacesFragment,
  WorkspaceFragment,
  WorkspaceSummaryFragment
} from "./fragments";
import { WorkspaceUserFragment } from "../user/fragments";
import { PlanFragment } from "../organizations/fragments";

export const GET_WORKSPACE_SUMMARIES = gql`
  query getWorkspaceSummaries {
    organizationContext {
      workspaces(first: 1000) {
        edges {
          node {
            id
            ...WorkspaceSummaryFragment
          }
        }
      }
    }
  }
  ${WorkspaceSummaryFragment}
`;

export const GET_WORKSPACES = gql`
  query getWorkspaces(
    $name: String
    $price: String
    $status: Status
    $cursor: String
  ) {
    organizationContext {
      workspaces(
        name: $name
        price: $price
        status: $status
        first: 10
        after: $cursor
      ) {
        edges {
          node {
            id
            ...FilteredWorkspacesFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${FilteredWorkspacesFragment}
`;

export const GET_WORKSPACE = gql`
  query getWorkspace($id: String!) {
    organizationContext {
      workspaceById(id: $id) {
        id
        ...WorkspaceFragment
      }
    }
  }
  ${WorkspaceFragment}
`;

export const GET_WORKSPACE_SUBSCRIPTION = gql`
  query getWorkspaceSubscription($id: String!) {
    organizationContext {
      workspaceById(id: $id) {
        id
        subscription {
          id
          plan {
            id
            changeable
            ...PlanFragment
          }
          settings {
            creditUsageNotification
            creditUsageLimit
          }
          usage {
            current {
              amount
              count
              currency
            }
            forecast {
              amount
              count
              currency
            }
          }
        }
      }
    }
  }
  ${PlanFragment}
`;

export const GET_WORKSPACE_USAGE = gql`
  query getWorkspaceUsage($id: String!) {
    organizationContext {
      workspaceById(id: $id) {
        id
        usage {
          current {
            periodEnd
            periodStart
            cumulated {
              name
              value
            }
            daily {
              name
              value
            }
            forecast {
              count
              type
            }
            trend {
              name
              value
            }
            used {
              count
              type
            }
          }
          history {
            credits
            month
            year
          }
        }
      }
    }
  }
`;

export const GET_CURRENT_WORKSPACE_PAST_CREDIT_USAGE = gql`
  query getWorkspaceUsage($id: String!, $month: Int!, $year: Int!) {
    organizationContext {
      workspaceById(id: $id) {
        id
        usage {
          past(month: $month, year: $year) {
            cumulated {
              name
              value
            }
            daily {
              name
              value
            }
            month
            year
            used {
              count
              type
            }
          }
        }
      }
    }
  }
`;

export const GET_WORKSPACE_USERS = gql`
  query getUsers(
    $wsKey: String!
    $name: String
    $role: String
    $cursor: String
  ) {
    organizationContext {
      workspaceById(id: $wsKey) {
        id
        users(name: $name, role: $role, first: 10, after: $cursor) {
          edges {
            node {
              ...WorkspaceUserFragment
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
  ${WorkspaceUserFragment}
`;

export const GET_POTENTIAL_WORKSPACE_USERS = gql`
  query getPotentialWorkspaceUsers($wsKey: String!, $name: String!) {
    organizationContext {
      workspaceById(id: $wsKey) {
        id
        usersByName(name: $name) {
          id
          email
          firstName
          lastName
          pictureUrl
        }
      }
    }
  }
`;

export const GET_ELIGIBLE_WORKSPACE_PLANS = gql`
  query getEligibleWorkspacePlans($wsKey: String!) {
    organizationContext {
      workspaceById(id: $wsKey) {
        id
        plans {
          id
        }
      }
    }
  }
`;

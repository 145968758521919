











import { OrganizationMessageSeverity } from "@/generated/graphql";
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  computed: {
    ...mapState({
      bannerText: state => state.bannerText
    }),
    variant(): string {
      switch (this.bannerText?.severity) {
        default:
        case OrganizationMessageSeverity.Low:
          return "info";
        case OrganizationMessageSeverity.High:
          return "warning";
        case OrganizationMessageSeverity.Critical:
          return "danger";
      }
    }
  }
});

import gql from "graphql-tag";
import { OrganizationFragmentMutation } from "./fragments";

export const UPDATE_CURRENT_ORGANIZATION = gql`
  mutation updateOrganization($input: OrganizationInput!) {
    organizationContext {
      updateOrganization(input: $input) {
        organization {
          ...OrganizationFragmentMutation
        }
      }
    }
  }
  ${OrganizationFragmentMutation}
`;

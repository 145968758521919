import { ActionTypes } from "./store/action-types";
import { Mutation, Query } from "./generated/graphql";
import { MutationTypes } from "./store/mutation-types";
import { apolloClient } from "@/vue-apollo";
import store from "@/store/index";
import { omitDeep } from "@/utils";
import {
  QueryOptions,
  MutationOptions,
  OperationVariables,
  ApolloQueryResult
} from "apollo-client";
import { FetchResult } from "apollo-link";
import { isBearerExpired } from "./auth";

const cleanTypeName = (operation: unknown) => {
  return omitDeep(operation, "__typename");
};

const http = async function(cb: Function, showLoader = true) {
  if (isBearerExpired()) {
    store.dispatch(ActionTypes.REFRESH_SESSION);
  }
  showLoader && store.commit(MutationTypes.SET_LOADING, true, { root: true });
  try {
    return await cb();
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  } finally {
    showLoader &&
      store.commit(MutationTypes.SET_LOADING, false, { root: true });
  }
};

const query = async function<T = Query, TVariables = OperationVariables>(
  options: QueryOptions<TVariables>,
  showLoader = true
): Promise<ApolloQueryResult<T>> {
  const fn = async () => {
    const data = await apolloClient.query({
      fetchPolicy: "network-only",
      ...options
    });
    return await Promise.resolve(cleanTypeName(data));
  };
  return http(fn, showLoader);
};

const mutate = async function<T = Mutation, TVariables = OperationVariables>(
  options: MutationOptions<T, TVariables>,
  showLoader = true
): Promise<FetchResult<T>> {
  const fn = async () => {
    const data = await apolloClient.mutate(options);
    return await Promise.resolve(cleanTypeName(data));
  };
  return http(fn, showLoader);
};

export default {
  query,
  mutate
};

import gql from "graphql-tag";

export const WorkspaceUserFragment = gql`
  fragment WorkspaceUserFragment on OrganizationWorkspaceUser {
    id
    firstName
    lastName
    invitationDate
    pictureUrl
    email
    roles {
      value
      name
    }
  }
`;

export const OrganizationUserFragment = gql`
  fragment OrganizationUserFragment on OrganizationUser {
    id
    firstName
    invitationDate
    lastName
    pictureUrl
    email
    roles {
      value
      name
    }
  }
`;

import gql from "graphql-tag";
import { WorkspaceUserFragment } from "../user/fragments";

export const WorkspaceSummaryFragment = gql`
  fragment WorkspaceSummaryFragment on OrganizationWorkspace {
    id
    isFavorite
    status
    company {
      logoUrl
      name
    }
    subscription {
      id
    }
  }
`;

export const FilteredWorkspacesFragment = gql`
  fragment FilteredWorkspacesFragment on OrganizationWorkspace {
    id
    isFavorite
    status
    company {
      logoUrl
      name
    }
    subscription {
      id
      plan {
        id
        name
        changeable
      }
    }
    users {
      edges {
        node {
          ...WorkspaceUserFragment
        }
      }
      totalCount
    }
  }
  ${WorkspaceUserFragment}
`;

export const WorkspaceFragment = gql`
  fragment WorkspaceFragment on OrganizationWorkspace {
    id
    isFavorite
    company {
      logoUrl
      name
    }
    contact {
      firstName
      lastName
      email
    }
    locale {
      timezone
    }
    status
    subscription {
      id
      plan {
        id
        active
        changeable
      }
    }
  }
`;





















import { RouteName } from "@/router";
import { handleKeyNavigation } from "@/utils";
import Vue from "vue";
import NavbarHeader from "./NavbarHeader.vue";
import NavLinkList from "./NavLinkList.vue";
import WorkspaceNavbar from "./WorkspaceNavbar.vue";

export default Vue.extend({
  name: "Navbar",
  components: { NavbarHeader, NavLinkList, WorkspaceNavbar },
  data() {
    return {
      selectedWorkspace: null as string | null
    };
  },
  watch: {
    "$route.params.id": {
      handler(id?: string) {
        this.selectedWorkspace = id || null;
      },
      immediate: true
    }
  },
  methods: {
    goBack() {
      this.selectedWorkspace = null;
      this.$router.push({
        name: RouteName.WORKSPACES,
        params: {
          orgId: this.$route.params.orgId
        }
      });
    },
    handleNavigation(e: KeyboardEvent) {
      const onSuccess = (id: string) => {
        this.$router.push({
          name: RouteName.WORKSPACE_ACCOUNT,
          params: {
            orgId: this.$route.params.orgId,
            id
          }
        });
      };

      handleKeyNavigation(
        "#filtered-workspaces-wrapper",
        "#workspace-filter-input",
        "selected",
        e,
        onSuccess
      );
    }
  }
});

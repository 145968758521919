import gql from "graphql-tag";

export const UPDATE_ORGANIZATION_BILLING = gql`
  mutation updateOrganizationBilling($input: BillingInput!) {
    organizationContext {
      updateOrganizationBilling(input: $input) {
        billing {
          addressLine1
          addressLine2
          city
          country {
            code
            name
          }
          currency
          name
          recipients {
            email
            firstName
            lastName
            phone {
              countryCode
              internationalNumber
              localNumber
              number
            }
          }
          state
          vat
          zip
        }
      }
    }
  }
`;

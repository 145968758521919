import { DirectiveOptions } from "vue";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const defaultAvatar = require("@/assets/img/avatars/profiles/default-avatar.gif");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const defaultCompany = require("@/assets/img/avatars/profiles/default-company.svg");

const getFallbackImg = (value?: "avatar" | "company" | string) => {
  if (value === "company") {
    return defaultCompany;
  } else if (value === "avatar") {
    return defaultAvatar;
  } else {
    return defaultAvatar;
  }
};
export default {
  bind(el, binding) {
    try {
      if (!(el instanceof HTMLImageElement)) {
        const childImg = el.querySelector("img");
        if (childImg === null) {
          throw new Error(`${el} should be an image`);
        } else {
          el = childImg;
        }
      } else {
        const { arg } = binding;
        const loader = defaultAvatar;
        const fallBackImage = getFallbackImg(arg);
        const img = new Image();
        const loading = loader;
        const error = fallBackImage;
        const original = el.src;
        // if (typeof value === "string") {
        //   loading = value;
        //   error = value;
        // }
        // if (value instanceof Object) {
        //   loading = value.imageLoader || loader;
        //   error = value.fallBackImage || fallBackImage;
        // }
        img.src = original;
        el.src = loading;
        img.onload = () => {
          (el as HTMLImageElement).src = original;
        };
        img.onerror = () => {
          (el as HTMLImageElement).src = error;
        };
      }
    } catch (e) {
      console.error(e);
    }
  }
} as DirectiveOptions;

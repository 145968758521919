import gql from "graphql-tag";

export const OrganizationFragment = gql`
  fragment OrganizationFragment on Organization {
    name
    status
    contact {
      firstName
      lastName
      email
      phone {
        number
        countryCode
        localNumber
        internationalNumber
      }
    }
    addressLine1
    addressLine2
    zip
    city
    state
    country {
      code
      name
    }
    logoUrl
  }
`;

export const OrganizationFragmentMutation = gql`
  fragment OrganizationFragmentMutation on Organization {
    name
    contact {
      firstName
      lastName
      email
      phone {
        number
        countryCode
        localNumber
        internationalNumber
      }
    }
    addressLine1
    addressLine2
    zip
    city
    state
    country {
      code
      name
    }
    logoUrl
  }
`;

export const PlanFragment = gql`
  fragment PlanFragment on Plan {
    active
    currency
    name
    description
    images
    id
    visible
    changeable
    priceTiers {
      amount
      limit
      type
    }
  }
`;

<template>
  <svg
    version="1.1"
    id="Calque_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 17.5 17.5"
    style="enable-background:new 0 0 17.5 17.5;"
    xml:space="preserve"
    class="img-icon"
  >
    <g>
      <path
        class="st0"
        d="M17,16.5c-0.7,0-1.3-0.5-1.5-1.2L13.4,4.8c-0.2-1.2-1.3-2-2.5-2s-2.2,0.9-2.5,2l-1,5.2
		C7.2,9.9,6.9,9.9,6.6,9.9c-1.5,0-2.9,1-3.4,2.4c-0.3,1-0.7,2.1-1,2.9c-0.2,0.6-0.6,1-1.2,1.1V0.5C1,0.2,0.8,0,0.5,0S0,0.2,0,0.5V17
		c0,0.3,0.2,0.5,0.5,0.5H17c0.3,0,0.5-0.2,0.5-0.5C17.5,16.7,17.3,16.5,17,16.5L17,16.5z M4.9,16.5H2.7c0.2-0.3,0.4-0.6,0.5-0.9
		c0.3-0.9,0.7-2,1-2.9c0.3-1,1.3-1.7,2.4-1.7c0.2,0,0.5,0,0.7,0.1l-0.9,4.3C6.2,16,5.6,16.5,4.9,16.5z M6.9,16.5
		c0.2-0.3,0.4-0.6,0.4-1c0.2-1,0.5-2.5,0.8-4c0.4,0.3,0.6,0.7,0.8,1.1c0.3,1,0.7,2.1,1,2.9c0.1,0.3,0.3,0.6,0.5,0.9L6.9,16.5
		L6.9,16.5z M12.6,16.5c-0.8,0-1.4-0.5-1.7-1.2c-0.3-0.9-0.7-2-1-2.9c-0.3-0.8-0.8-1.5-1.5-1.9L9.5,5c0.1-0.7,0.8-1.2,1.5-1.2
		c0.7,0,1.3,0.5,1.5,1.2c0,0,1.8,9.2,2.1,10.5c0.1,0.4,0.2,0.7,0.4,1L12.6,16.5L12.6,16.5z"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

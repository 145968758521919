















import Vue from "vue";

export default Vue.extend({
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String
    },
    icon: {
      type: String
    },
    params: {
      type: Object
    }
  }
});

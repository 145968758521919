import gql from "graphql-tag";

export const UPDATE_CURRENT_USER = gql`
  mutation updateCurrentUser($input: UserInput!) {
    userContext {
      updateCurrentUser(input: $input) {
        user {
          email
          firstName
          id
          lastName
          pictureUrl
        }
      }
    }
  }
`;
